import {$_fnSchema} from "./common";
import {getType} from "@fndry-vue/fn-types";

/**
 * A wrapper element around Input Types to capture the update and emit it as a
 */
export default {
    name: "InputSchema",
    functional: true,
    inject: {
        [$_fnSchema]: {
            name: $_fnSchema,
            default: null
        }
    },
    props: {
        nameKey: String,
        schema: {
            type: Object
        },
        value: {
            required: false
        },
        state: {
            type: Boolean,
            required: false
        }
    },
    render(createElement, context) {

        const type = getType(context.props.schema.type);
        if (type === undefined) {
            console.warn(`Input type of ${context.props.schema.type} not set. Did you forget to set it?`);
            return;
        }

        let values = (context.injections[$_fnSchema]) ? context.injections[$_fnSchema].model : {};

        //create the type instance
        return createElement(type, {
            class: context.props.schema.className,
            props: {
                ...context.props.schema,
                value: context.props.value,
                values: values,
                state: context.props.state,
                nameKey: context.props.nameKey
            },
            on: context.listeners
        });
    }
};
