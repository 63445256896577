<template>
    <router-view></router-view>
</template>
<script>

import withContext from "@fndry-vue/fn-context/mixins/withContext";
import {withScreen} from "@fndry-vue/fn-screen"
import DashboardTabs from "../../components/DashboardTabs";
import {DASHBOARD} from "@/features/dashboard";
import {DASHBOARD_PAYMENT_HISTORY_TAB} from "@/features/dashboard/screens";

export default {
    name: "DashboardScreen",
    mixins: [
        withContext,
        withScreen,
    ],
    components: {
        DashboardTabs
    },
    screen() {
        return {
            title: this.$t('Dashboard'),
            data: {
                withButton: true,
                tabs : [
                    {
                        name: this.$t('Dashboard'),
                        route_name: DASHBOARD
                    },
                    // {
                    //   name: this.$t('Notifications'),
                    //   route_name: null
                    // },
                    {
                        name: this.$t('Payment History'),
                        route_name: DASHBOARD_PAYMENT_HISTORY_TAB
                    },
                    // {
                    //   name: this.$t('Blog'),
                    //   route_name: null
                    // },
                ]
            }
        }
    },
    context(){
        return false;
    }
};
</script>
