import {getType} from "@fndry-vue/fn-types";
import columns from "../utils/columns";
import {ValidationObserver} from "vee-validate";
import withSchemaStore from "../mixins/withSchemaStore";
import schemaNode from "./SchemaNode";
import {$_fnForm} from "./common";
import {uniqueId} from "lodash";

/**
 * FormSchema is a wrapper component which renders a given schema and manages the validation state.
 *
 * Based on the schema, we will build a list of functional nodes to be rendered, and connect those nodes directly to
 * their schema state stored in the schema store
 */
export default {
    name: "FormSchema",
    mixins: [
        withSchemaStore
    ],
    inject: {
        [$_fnForm]: {
            name: $_fnForm,
            default: null
        }
    },
    props: {
        /**
         * Disable the displaying of the labels above inputs
         *
         * This should only be disabled if the inputs have a placeholder
         */
        noLabel: Boolean,
        /**
         * A key to help uniquely identify the inputs in the form
         */
        nameKey: String,
    },
    computed: {
        valid: function(){
            return this.flags.valid;
        }
    },
    data() {
        return {
            observerRef: uniqueId("ob_"),
            errors: {},
            flags: {
                dirty: null,
                validated: null,
                valid: null
            }
        };
    },
    mounted() {
        this.$watch(
            () => {
                return this.$refs[this.observerRef].flags;
            },
            (val) => {
                this.flags = val;
                if (this[$_fnForm]) {
                    this[$_fnForm].onValidated(this.flags);
                }
                this.$emit("validated", this.flags);
            }
        );
    },
    methods: {
        setErrors(errors){
            this.errors = errors;
            this.$refs[this.observerRef].setErrors(errors);
        },
        validate(){
            return this.$refs[this.observerRef].validate();
        },
    },
    render(createElement) {

        const buildChildren = (children, parentKey = undefined) => {

            let _children = [];

            children.forEach((key) => {
                let schema = this.schemaState.nodes[key];

                let isInput = schema.input || false;

                let nameKey = undefined;

                /*
                 * At this point we need to see if the child is an input
                 *
                 * Knowing this, we can create a special FormGroup component to wrap our input with, which will control
                 * errors and state for the input using vee validate
                 *
                 * To reduce the size of the response and also make it possible to manipulate the input schemas, they
                 * are stored in a special inputs prop. This means that potentially that the input schema's can be
                 * changed on demand, making them full reactive.
                 */
                if (isInput) {

                    /*
                     * If it is an input, we want to rather get it's definition from the inputs object, which will keep an
                     * observable instance of the inputs in the form.
                     *
                     * This means that any reactivity will force the rerender of that input accordingly.
                     */

                    if (this.schemaState.inputs[schema.name] == null) {
                        console.warn(`Input ${schema.name} not found in schema. Did you forget to send it?`);
                        return;
                    }

                    schema = this.schemaState.inputs[schema.name];

                    nameKey = (parentKey) ? parentKey + '.' + schema.name : schema.name;
                }

                /*
                 * Here we use getType to fetch from the registered types which component we should use as the component
                 */
                const type = getType(schema.type);
                if (type === undefined) {
                    console.warn(`Type of ${schema.type} not set. Did you forget to set it?`);
                    return;
                }

                /*
                 * Here we need to check if we have children and build them accordingly
                 */
                let container_children = [];

                let _inner_children = [];
                if (schema.children) {
                    _inner_children = buildChildren(schema.children, nameKey || parentKey);
                }

                /*
                 * if the type is a row, we need to loop through the children and create col divs and set the classes as needed
                 */
                if (schema.type === "row" && _inner_children.length > 0) {
                    _inner_children.forEach((_child, _index) => {
                        const col_component = getType("column");

                        let colClass = "col";

                        if (this.schemaState.nodes[schema.children[_index]]) {
                            colClass = columns(this.schemaState.nodes[schema.children[_index]].data?.cols);
                        }

                        container_children.push(createElement(col_component, {
                            class: colClass,
                        }, [_child]));

                    });
                } else {
                    container_children = _inner_children;
                }

                /*
                 * If it is a container, we need to process it like a container with some special actions
                 */
                _children.push(createElement(schemaNode, {
                    props: {
                        schema,
                        isInput,
                        nameKey,
                        noLabel: this.noLabel,
                    }
                }, container_children));
            });

            return _children;
        };

        let children = [];
        if (this.$scopedSlots.default) {
            children = this.$scopedSlots.default({
                schema: this.schema,
                schemaStore: this.schemaStore,
                state: this.schemaState,
                inputs: this.schemaState.inputs,

                updateField: (key, value) => this.schemaStore.setValue(key, value),
                updateModel: (model) => this.schemaStore.setModel(model),
                updateInput: (schema) => this.schemaStore.setInput(schema),

                flags: this.flags,
                valid: this.valid,
                errors: this.errors
            });
        } else {
            children = buildChildren(this.schemaState.nodes[this.schemaState.root].children, this.nameKey);
        }

        return createElement(ValidationObserver, {
            class: "fn-form-schema",
            props: {
                tag: "div"
            },
            ref: this.observerRef,
        }, children);
    }
};
