export default {
    props: {
        /**
         * The set or rules to validate the input with
         */
        rules: {
            type: [String,Array,Object],
            required: false
        }
    },
}
