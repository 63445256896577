import element from "../mixins/element";
import container from "../mixins/container";

export default {
    name: 'ColumnContainer',
    mixins: [
        element,
        container
    ],
    render(createElement, context) {
        return createElement('div', {
            class: this.className
        }, this.$slots.default)
    }
}
