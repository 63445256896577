<template>
    <div>
        <div>
            <h4 class="text-center text-gray-600">{{ $t('Password Reset Request Processed') }}</h4>
        </div>
        <div>
            <p class="lead text-center text-gray-600">{{ $t("Please check your mail for a reset link. After cliking that link, and entering a new password, you can proceed to login.") }}</p>

            <div class="form-group mt-4">
                <fn-ui-button variant="primary" class="btn-block btn-rounded" type="button" @click.prevent="goToLogin">
                    {{ $t("Proceed to login") }}
                </fn-ui-button>
            </div>
            <div class="mt-4">
                <p class="text-center">{{ $t("Didn't receive the reset link?") }} <a href="#" @click.prevent="resendPin">{{ $t("Resend") }}
                    <b-spinner v-if="resendingPin" small variant="primary" class="ml-1" :label="$t('Loading')"></b-spinner>
                </a></p>
            </div>
        </div>
    </div>
</template>

<script>
import {AUTH_LOGIN} from "./index";
import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";
import {withScreen} from "@fndry-vue/fn-screen";

export default {
    name: "AuthForgotNotify",
    mixins: [
        withAutoGuard,
        withScreen
    ],
    screen() {
        return {
            title: this.$t(''),
            data: {
                noToggle: true,
                withButton: false,
                clearWave: true
            }
        }
    },
    methods: {
        resendPin() {
            this.$router.go(-1);
        },
        goToLogin() {
            this.$router.push({
                    name: AUTH_LOGIN
                });
        }
    }
};
</script>
