<template>
    <base-error>
        <div class="error-content">
            <h3 class="text-white mt-3">{{ $t("Permission Denied") }}</h3>
            <h4 class="text-secondary mt-2">{{ $t("Error code 403") }}</h4>
            <p class="text-muted mt-3">{{ $t("It's looking like you may not have access to that. Don't worry... you can talk to the system admin to assist you with the permission should you really need it.") }}
            </p>
        </div>
    </base-error>
</template>

<script>
import BaseError from "@/features/errors/screens/BaseError";

export default {
    name: "Error403",
    components: {
        BaseError
    }
};
</script>
