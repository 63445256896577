import element from "../mixins/element";
import container from "../mixins/container";

export default {
    name: "DivContainer",
    mixins: [
        element,
        container
    ],
    props: {
        cols: {
            type: Array,
            required: false
        }
    },
    render(createElement, context) {
        return createElement("div", {
            class: ""
        }, this.$slots.default);
    }
};
