import {ERROR_LAYOUT} from "../../layouts";
import screens, {ERROR_403, ERROR_404} from "./screens";

const Plugin = {
    install: function(Vue, {router, layouts}){
        //add the routes
        router.addRoutes([
            {
                path: "/403",
                component: layouts[ERROR_LAYOUT],
                children: [
                    {
                        path: "",
                        name: ERROR_403,
                        component: screens[ERROR_403],
                    }
                ]
            },
            {
                path: "*",
                component: layouts[ERROR_LAYOUT],
                children: [
                    {
                        path: "",
                        name: ERROR_404,
                        component: screens[ERROR_404],
                    }
                ]
            }
        ]);
    }
};

export default Plugin;
