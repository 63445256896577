<template>
    <div>
        <div>
            <h4 class="text-center text-gray-600">{{ $t("Forgot Password") }}</h4>
        </div>
        <div>
            <p class="text-center text-gray-600">{{ $t("Enter your email address and we will send you instructions on how to reset your password.") }}</p>
            <form @submit.prevent="onSubmit">
                <fn-form-schema ref="formSchema" :value="model" @input="onUpdate" no-inject @validated="onValidated">
                    <fn-form-group :schema="inputs.email"></fn-form-group>
                </fn-form-schema>

                <div class="form-group">
                    <fn-ui-button variant="primary" class="btn-block btn-rounded"
                        :disabled="flags.invalid || loading || submitting" :submitting="submitting" type="submit">{{
                            $t("Send Reset Email") }}
                    </fn-ui-button>
                </div>
                <div class="mt-4 text-gray-600">
                    <p class="text-center">{{ $t("Remembered your password?") }}
                        <router-link class="text-primary" :to="loginRoute">{{ $t("Log In") }}</router-link>
                    </p>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { AUTH_FORGOT_NOTIFY, AUTH_LOGIN } from "./index";
import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";
import { withScreen } from "@fndry-vue/fn-screen";
import {FnUiButton} from "@fndry-vue/fn-ui";

import {FnFormGroup, FnFormSchema} from "@fndry-vue/fn-form";

export default {
    name: "AuthForgot",
    mixins: [
        withAutoGuard,
        withScreen
    ],
    components: {
        FnFormSchema,
        FnFormGroup,
        FnUiButton
    },
    screen() {
        return {
            title: this.$t(''),
            data: {
                noToggle: true,
                withButton: false,
                clearWave: true
            }
        }
    },
    data() {
        return {
            loading: false,
            submitting: false,
            flags: {
                invalid: false,
                dirty: false
            },
            errors: [],
            inputs: {
                email: {
                    key: "email",
                    id: "email",
                    name: "email",
                    label: this.$t("Registered Email Address"),
                    placeholder: this.$t("Registered Email Address"),
                    type: "email",
                    rules: "required|email",
                    required: true,
                    autocomplete: "email"
                },
            },
            model: {
                email: "",
            }
        };
    },
    computed: {
        formUri() {
            return "/api/v2/auth/forgot";
        },
        formUriParams() {
            return this.getGuardUriParams();
        },
        loginRoute() {
            return {
                name: AUTH_LOGIN
            };
        }
    },
    methods: {
        onUpdate(value){
            this.model = {
                ...value
            };
        },
        onValidated(flags) {
            this.flags = {
                ...flags
            };
        },
        async onSubmit(){
                this.submitting = true;

                if (this.flags.valid === false) {
                    return;
                }

                let response = await this.$fnApi.call("/api/v1/mobile/forgot/password?mobile=yes", "POST", {
                    ...this.model
                });

                if (response.code == 200) {
                    this.onSuccess(response);
                } else {
                    if (response.code == 400 || response.code == 403) {
                        this.$refs.formSchema.setErrors(response.error);
                    }
                    this.$fnNotify({
                        message: this.$t("Unable to send password reset email"),
                        type: "error"
                    });
                }

                this.submitting = false;

            },
            onSuccess(response) {
                if (response.code == 200) {
                    this.$router.push({
                        name: AUTH_FORGOT_NOTIFY,
                        params: {
                            token: response.data.token,
                            email: this.inputs.email.value
                        }
                    });
                }
            }
        }
};
</script>
