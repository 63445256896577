<template>
    <div class="mt-2">
        <div class="row mb-2">
            <div class="col font-18 text-primary font-weight-bold">{{ $t('Payment Details') }}</div>
        </div>

        <div class="row row-cols-2 mb-2">
            <div class="col text-left">{{ $t('Payment Method') }}</div>
            <div class="col text-right">{{ payment.PaymentType }}</div>
        </div>

        <div class="row row-cols-2 mb-2">
            <div class="col text-left">{{ $t('Payment Amount') }}</div>
            <div class="col text-right">
                <fn-format-currency :value="payment.TotalPymtLease"></fn-format-currency>
            </div>
        </div>

        <div class="row row-cols-2 mb-2">
            <div class="col text-left">{{ $t('Payment Date') }}</div>
            <div class="col text-right">
                <fn-format-date format="MM/DD/YYYY" :value="payment.dateofpaymt" :ignore-time-zone="true"></fn-format-date>
            </div>
        </div>

        <div class="row row-cols-2 mb-3">
            <div class="col text-left">{{ $t('Payment ID') }}</div>
            <div class="col text-right">{{ payment.PaymentId }}</div>
        </div>

    </div>
</template>

<script>
import withContext from "@fndry-vue/fn-context/mixins/withContext";
import {withScreen} from "@fndry-vue/fn-screen"

export default {
    name: "PaymentDetailsScreen",
    props: {
        details: Object,
        payment: Object,
        backRoute: Object,
    },
    mixins: [
        withContext,
        withScreen,
    ],
    screen() {
        return {
            class: 'details-screen',
            data: {
                withButton: false,
                noToggle: true,
                withBack: true,
                backRoute: this.backRoute,
                details: {...this.details}
            }
        }
    },
    context(){
        return false;
    }
}
</script>
