export const getColumn = "getColumn";
export const getColumns = "getColumns";

export default {
    [getColumn]: (state) => (name) => {
        return state.columns.find(column => column.name === name);
    },
    [getColumns]: (state) => () => {
        return state.columns;
    },
};
