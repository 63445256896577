export default {
    props: {
        min: {
            type: Number
        },
        max: {
            type: Number
        }
    }
}
