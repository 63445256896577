export default {
    props: {
        /**
         * The label for the input
         */
        label: {
            type: String,
            required: false
        }
    },
}
