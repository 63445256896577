<template>
  <loader :loading="loadingHistory">
    <div class="row mb-3">
      <div class="col font-18 text-primary font-weight-bold">{{ $t('Payment Overview') }}</div>
    </div>
    <payment-history-chart :data="chartData" v-if="payments.length > 0"/>
    <div class="mt-3" v-if="payments.length > 0">
      <payment-history-item v-for="(payment, index) in payments" :key="index" :payment="payment" :lease="lease"></payment-history-item>
    </div>
    <div v-if="!loadingHistory && payments.length <= 0">
      <p>No payments have been captured against your lease</p>
    </div>
  </loader>
</template>

<script>
import PaymentHistoryItem from "../../../dashboard/components/PaymentHistoryItem";
import PaymentHistoryChart from "../../components/PaymentHistoryChart";
import moment from "moment/moment";

export default {
    name: "LeasePaymentHistoryTabScreen",
    props: {
        lease : Object,
        details: Object,
    },
    components: {
        PaymentHistoryItem,
        PaymentHistoryChart,
    },
    data() {
        return {
            loadingHistory : false,
            payments: []
        }
    },
    mounted() {
        this.getPaymentHistory();
    },
    computed: {
        lastSixMonths() {
            let currentMonth = moment().startOf('month');
            let lastSixMonths = moment().subtract(6,'month').startOf('month');

            let iterateMonth = lastSixMonths.clone();
            let months = [];
            while(currentMonth.isAfter(iterateMonth)) {
                months.push(iterateMonth.clone())
                iterateMonth.add(1, 'month');
            }
            months.push(currentMonth)
            return months;
        },
        lastSixMonthsLabel() {
          let monthNames = [];
          this.lastSixMonths.forEach((item) => {
              monthNames.push(item.format('MMM'))
          })
          return monthNames;
        },
        chartData() {
          let dataArray = [];
          try {            
            const groupedPayments = this.payments?.reduce((acc, payment) => {
              const month = moment(payment.dateofpaymt, 'YYYY-MM-DD HH:mm:ss.SSS').format('MMM-YYYY');
              if (!acc[month]) {
                acc[month] = { month: month, total: 0 };
              }
              acc[month].total += parseFloat(payment.TotalPymtLease);
              acc[month].total = parseFloat(acc[month]?.total?.toFixed(2)); // round to 2 decimal places
              return acc;
            }, {});

            dataArray = this.lastSixMonths.map((month) => {
              const monthKey = month.format('MMM-YYYY');
              return groupedPayments[monthKey] ? groupedPayments[monthKey].total : 0;
            });
          } catch (error) {
            
          }


          return  {
            labels: this.lastSixMonthsLabel,
            datasets: [
              {
                label: this.$t('Amount Paid'),
                backgroundColor: '#F2F5F7',
                borderColor: 'rgba(180, 180, 180, 0.1)',
                borderWidth: 1,
                hoverBackgroundColor: '#00A4E4',
                data: dataArray,
                borderRadius: 8,
              }
            ],
          }
        },
    },
    methods: {
        getPaymentHistory() {
            this.loadingHistory = true;
            if(this.lease?.loan_number != null) {
              this.$fnApi.call(`/api/v1/mobile/leases/${this.lease?.loan_number}/history`)
                .then((response) => {
                  if(response.status) {
                    this.payments = response.data;
                  }
                }).finally(()=>{
                    this.loadingHistory = false
              })
            }
        }
    }
}
</script>
