// MUTATIONS
import {set} from "lodash";

export const CREATE_FILTER = "CREATE_FILTER";
export const DESTROY_FILTER = "DESTROY_FILTER";
export const UPDATE_VALUES = "UPDATE_VALUES";

export const defaultState = {
    filters: {}
};

export default {

    /**
     * Create a filter for use in the UI
     *
     * @param state
     * @param {String} name
     * @param {{}} filters
     * @param {{}} values
     */
    [CREATE_FILTER](state, {name, filters, values= {}}){

        let inputs = {};
        let groups = [];

        Object.keys(filters).forEach((groupKey) => {
            let filter = filters[groupKey];
            if (filter.type === "group") {
                let types = [];
                Object.keys(filter.types).forEach((typeKey) => {
                    inputs[typeKey] = {
                        ...filter.types[typeKey],
                        group: groupKey
                    };
                    types.push(typeKey);
                    set(values, typeKey, undefined);
                });
                groups.push({
                    ...filter,
                    types
                });
            } else {
                inputs[groupKey] = {
                    ...filter,
                    group: null
                };
                set(values, groupKey, undefined);
            }
        });

        state.filters = {
            ...state.filters,
            [name]: {
                inputs,
                groups,
                values
            }
        };
    },

    /**
     * Destroy a filter by name
     *
     * @param state
     * @param name
     */
    [DESTROY_FILTER](state, {name}){
        if (state.filters[name]) {
            delete state.filters[name];
        }
    },

    /**
     * Update a filters values
     *
     * @param state
     * @param name
     * @param values
     */
    [UPDATE_VALUES](state, {name, values}){
        state.filters = {
            ...state.filters,
            [name]: {
                ...state.filters[name],
                values
            }
        };
    },

};
