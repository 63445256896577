import Vue from "vue";
import {get, set, uniq, uniqueId} from "lodash";
import {determineVisibility} from "../utils/schema";

export default class SchemaStore {

    constructor(value = undefined) {
        this.schema = {};
        this.bus = new Vue();

        let state = {
            root: uniqueId("k"),
            inputs: {},
            model: value ?? {},
            nodes: {},
            conditions: {},
            reloads: {},
            loaded: false
        };

        this.state = Vue.observable(state);

        this.onAfterLoaded();

    }

    /**
     *
     * @param schema
     * @param value
     */
    load(schema, value = undefined){

        if (value) {
            value = {...schema.values, ...value};
        }

        this.schema = schema;

        let state = {
            root: schema.key || uniqueId("k"),
            inputs: schema.inputs ? {...schema.inputs} : {},
            model: value ?? {...schema.values},
            nodes: {},
            conditions: {},
            reloads: {},
            loaded: false
        };

        processSchema(state, schema);

        state.loaded = true;

        this.state = Vue.observable(state);

        this.onAfterLoaded();
    }

    getRoot(){
        return (this.state.root) ? this.state.nodes[this.state.root] : undefined;
    }

    setModel(model){
        this.state.model = {
            ...this.state.model,
            ...model
        };
        this.onAfterModelUpdate();
    }

    getModel(){
        return this.state.model;
    }

    isLoaded() {
        return this.state.loaded;
    }

    setValue(name, value){
        let oldVal = this.getValue(name);
        set(this.state.model, name, value);
        this.onAfterInputUpdate(name, value, oldVal);
    }

    getValue(name){
        return get(this.state.model, name, undefined);
    }

    getInput(name){
        return this.state.inputs[name];
    }

    setInput(schema){
        this.state.inputs[schema.name] = {
            ...this.state.inputs[schema.name],
            ...schema
        };
    }

    reload(inputs = {}, values){
        for (let name of Object.keys(inputs)) {
            this.setInput(inputs[name]);
        }
        this.setModel(values);
    }

    onAfterLoaded() {
        this.runAllConditions();
        this.emit("loaded", this.state);
    }

    onAfterModelUpdate() {
        this.runAllConditions();
        this.emit("input", this.state.model);
    }

    onAfterInputUpdate(name, newValue, oldVal) {
        if (newValue !== oldVal) {
            this.runConditions(name);
            this.emitReload(name);
        }
        this.emit("field-updated", {name, newValue, oldVal});
        this.emit("input", this.state.model);
    }

    /**
     * Run through all the conditions on the store
     *
     * This should only be called when the full model was updated
     */
    runAllConditions() {
        let conditions = Object.keys(this.state.conditions);
        if (conditions.length > 0) {
            for (let name of conditions) {
                this.runConditions(name);
            }
        }
    }

    /**
     * Run an inputs linked conditions
     * @param {String} name The input name that received a change
     */
    runConditions(name) {
        if (this.state.conditions[name] !== undefined) {
            let conditions = this.state.conditions[name];

            //get the input value
            let value = this.getValue(name);

            let visibility = {};

            //process through all the conditions passing in the value
            for (let node of conditions) {

                //default visibility is false
                if (visibility[node.key] === undefined) {
                    visibility[node.key] = false;
                }

                //visible if at least 1 visible pass
                if (determineVisibility(node.condition, value)) {
                    visibility[node.key] = true;
                }
            }

            //now process the visibility and set each nodes' visibility
            for (let key of Object.keys(visibility)) {
                this.state.nodes[key] = {
                    ...this.state.nodes[key],
                    visible: visibility[key]
                };
            }
        }
    }

    /**
     * Run through all the conditions on the store
     *
     * This should only be called when the full model was updated
     */
    emitAllReloads() {
        let reloads = [];
        let names = Object.keys(this.state.reloads);
        if (names.length > 0) {
            for (let name of names) {
                reloads = [...reloads, ...this.state.reloads[name]];
            }
        }
        reloads = uniq(reloads);
        if (reloads.length > 0) {
            this.emit("reload", reloads);
        }
    }

    /**
     * Process the reloads for a given input name
     * @param {String} name The input name
     */
    emitReload(name)
    {
        if (this.state.reloads[name] !== undefined) {
            let reloads = this.state.reloads[name];
            if (reloads.length > 0) {
                this.emit("reload", reloads);
            }
        }
    }

    listen(listeners){
        Object.keys(listeners).forEach((eventName) => {
            this.on(eventName, listeners[eventName]);
        });
    }

    on(eventName, callable){
        this.bus.$on(eventName, callable);
    }

    emit(eventName, payload) {
        this.bus.$emit(eventName, payload);
    }

}

/**
 * Process the schema and build a key for each node for the state
 *
 * @param {{inputs: {}, model: {}, nodes: {}, conditions: {}}} state
 * @param {{}} schema The schema to be processed
 * @returns {String} The key of the schema
 */
export function processSchema(state, schema){
    let childrenKeys = [];

    let key = schema.key;

    if (schema.children && schema.children.length > 0) {
        for (const c of schema.children) {
            childrenKeys.push(processSchema(state, c));
        }
    }

    if (schema.input) {
        state.nodes[key] = state.inputs[schema.name];

        /**
         * Extract all the reloads
         */
        if (state.inputs[schema.name].reloadOnChange) {

            let sources = state.nodes[key].reloadOnChange;
            let target = schema.name;

            for (let s of sources) {
                if (state.reloads[s] === undefined) {
                    state.reloads[s] = [];
                }
                state.reloads[s].push(target);
            }
        }
    } else {
        state.nodes[key] = {...schema, children: childrenKeys.length > 0 ? childrenKeys : undefined};
    }

    /**
     * Extract all the conditions
     *
     * This will extract all the conditions and make an index of what input names are linked to condtions on
     * inputs so that we only need to run the conditions when the specified input name changes
     */
    if (state.nodes[key].conditions) {
        let conditions = extractConditions(schema.conditions);
        for (let condition of conditions) {
            if (state.conditions[condition.ref] === undefined) {
                state.conditions[condition.ref] = [];
            }
            state.conditions[condition.ref].push({
                key,
                condition: {
                    operator: condition.operator,
                    values: condition.values
                }
            });
        }
    }

    return key;
}

/**
 * Extract the conditions from a schema
 *
 * @param {Array} conditions
 * @returns {*[]}
 */
export function extractConditions(conditions = []){
    let conditions_ = [];

    for (let i = 0; i < conditions.length; i++){

        let condition = conditions[i].split(":");

        if(condition && condition.length > 0){
            let ref = condition[0];
            let operator = condition[1];
            let value = condition[2];
            let values = [];

            if(value){
                let v = value.split(",");
                if(v && v.length > 0){
                    for (let j = 0; j < v.length; j++){
                        values.push(v[j]);
                    }
                }
            }

            if(ref && operator){
                conditions_.push({
                    ref,
                    operator,
                    values
                });
            }
        }
    }
    return conditions_;
}

