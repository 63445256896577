import store_, {screenStore} from "../store";

import {SET_SCREEN, RESET_SCREEN} from "../store/mutations";
import {getScreen, getDataKey, getClass, getTitle} from "../store/getters";

/**
 * Screen Class
 */
export default class Screen {

    /**
     * Initiate the object
     *
     * @param store
     * @param Vue
     */
    constructor(store, Vue) {
        // Install the Auth Store
        store_.install(store);
        this.store = store;
        this.Vue = Vue;
    }

    setScreen(state) {
        this.store.commit(screenStore(SET_SCREEN), state);
    }

    resetScreen(state) {
        this.store.commit(screenStore(RESET_SCREEN));
    }

    /**
     * Get the entire Screen State
     *
     * @return {String|null}
     */
    getScreen() {
        return this.store.getters[screenStore(getScreen)];
    }

    /**
     * Get the Screen Title
     *
     * @return {String|null}
     */
    getScreenTitle() {
        return this.store.getters[screenStore(getTitle)];
    }

    /**
     * Get the Screen Title
     *
     * @return {String|null}
     */
    getScreenClass() {
        return this.store.getters[screenStore(getClass)];
    }

    /**
     * Get the Key with optional default value
     *
     * @return {String|null}
     */
    getDataKey(key, def) {
        return this.store.getters[screenStore(getDataKey)](key, def);
    }
}
