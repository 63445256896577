import moment from "moment";
export default {
    data() {
        return {
            loadingLeases: false,
            allLeases: [],
            currentLeasesExtraData : [],
            loanPaymentsData : [],
            paidOffLeasesExtraData : [],
        }
    },
    computed: {
        currentLeases() {
            if(this.currentLeasesExtraData.length > 0) {
                let currentLoanNumbers = this.currentLeasesExtraData.map((item) => parseInt(item.LoanNumber));
                return this.allLeases.filter((l) => {
                    if(currentLoanNumbers.includes(l.loan_number)){
                        l.extra = this.currentLeasesExtraData.filter((lease) => parseInt(lease.LoanNumber) === l.loan_number)[0];
                        return l;
                    }
                });
            } else return [];
        },
        approvedLeases() {
            if(this.allLeases.length > 0) {
                let codes = ['V','S','G','C','I','Z','Y'];
                return this.allLeases.filter((l) => codes.includes(l.state_code.toString()) && !this.leaseHasExpired(l.application_date));
            } else return [];
        },
        closedLeases() {
            if(this.paidOffLeasesExtraData.length > 0) {
                let paidOffLoanNumbers = this.paidOffLeasesExtraData.map((item) => parseInt(item.LoanNumber));
                return this.allLeases.filter((l) => {
                    
                    if(paidOffLoanNumbers.includes(l.loan_number)){
                        l.extra = this.paidOffLeasesExtraData?.filter((lease) => parseInt(lease.LoanNumber) === l.loan_number)?.[0];
                        return l;
                    }
                });                
            } else return [];
        }
    },
    methods: {
        getLeases() {
            this.loadingLeases = true;
            this.$fnApi.call('/api/v1/mobile/leases')
                .then((response) => {
                    if(response.status) {
                        this.allLeases = response.data.all;
                        this.currentLeasesExtraData = response.data.current;
                        this.loanPaymentsData = response.data.payments;
                        this.paidOffLeasesExtraData = response.data.paidoff;
                    }
                }).finally(()=>{
                this.loadingLeases = false
            })
        },
        getCurrentLeaseFromLoan(loanNumber) {
            return this.currentLeases.find((item) => {
                return item.loan_number === parseInt(loanNumber)
            })
        },
        getLeaseFromLoan(loanNumber) {
            return this.allLeases.find((item) => {
                return item.loan_number === parseInt(loanNumber)
            })
        },
        getLeaseById(id) {
            return this.allLeases.find((item) => {
                return item.lease_id === id
            })
        },
        leaseHasExpired(date)  {
            let createdAt = moment(date,'YYYY-MM-DD HH:mm:ss');
            let now = moment();

            return now.diff(createdAt, 'days') > 181
        },
    },
    mounted() {
        this.getLeases();
    }
}
