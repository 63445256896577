import store_, {contextStore} from "../store";

import {attachReaction, detachReaction, refreshContext, setContext} from "../store/actions";
import {RESET_CONTEXT} from "../store/mutations";
import {getContext} from "../store/getters";
import {sortBy} from "lodash";

export const sortItems = (items) => {
    return sortBy(items, (item) => {
            if (item.children) {
                item.children = sortItems(item.children);
            }
            return (item.order ?? 0) + item.text;
        }
    );
};

/**
 * Context Class
 */
export default class Context {

    /**
     * Initiate the object
     *
     * @param store
     * @param {Menus} fnMenu
     */
    constructor(store, Vue) {
        // Install the Auth Store
        store_.install(store);
        this.store = store;
        this.Vue = Vue;
    }

    /**
     * Attach a reaction to the context reactions
     *
     * @param {Reaction} reaction
     *
     * @return {String|void} The key for the reaction or void if reaction could not be attached
     */
    attachReaction(reaction){
        return this.store.dispatch(contextStore(attachReaction), reaction);
    }

    /**
     * Detach a reaction in the context reactions
     *
     * @param key The key for the reaction to remove
     * @return {boolean}
     */
    detachReaction(key){
        return this.store.dispatch(contextStore(detachReaction), key);
    }

    /**
     * Set the context
     *
     * @param {String} newTitle
     * @param {String} newContext
     * @param {null|Object} newSubject
     * @return {Promise<string|null>} Returns the key for the new context
     */
    async set(title, context, subject = undefined, actions = undefined, navigation = undefined) {
        //determin if the title, context or subject has not changed
        if (title === this.getContextTitle() && context === this.getContextName() && subject == this.getSubject()) {
            return null;
        }

        this.removeContextActions();
        this.removeContextNavigationItems();

        let key = await this.store.dispatch(contextStore(setContext), {
            title,
            context,
            subject
        });

        if (actions) {
            this.addContextActions(actions);
        }
        if (navigation) {
            this.addContextNavigationItems(navigation);
        }

        return key;
    }

    /**
     * Refresh the context
     *
     * This will cause the context key to change
     *
     * @return {Promise<string|null>} Returns the key for the refreshed context or null if no change
     */
    refresh(subject = undefined) {
        return this.store.dispatch(contextStore(refreshContext), subject);
    }

    /**
     * Reset the context to the default state
     */
    reset() {
        this.store.commit(contextStore(RESET_CONTEXT));
        this.removeContextActions();
        this.removeContextNavigationItems();
    }

    /**
     * Get the Context Name
     *
     * @return {String|null}
     */
    getContextKey() {
        return this.store.getters[contextStore(getContext)].key;
    }

    /**
     * Get the Context Name
     *
     * @return {String|null}
     */
    getContextTitle() {
        return this.store.getters[contextStore(getContext)].title;
    }

    /**
     * Get the Context Name
     *
     * @return {String|null}
     */
    getContextName() {
        return this.store.getters[contextStore(getContext)].name;
    }

    /**
     * Get the context subject
     *
     * @return {null|Object}
     */
    getSubject() {
        return this.store.getters[contextStore(getContext)].subject;
    }

    /**
     * Get the context actions
     *
     * @return {[]}
     */
    getContextActions() {
        return (this.Vue.prototype.$fnMenus) ? this.Vue.prototype.$fnMenus.getMenuItems("contextActions") : [];
    }

    /**
     * Get the context section actions
     *
     * @return {[]}
     */
    getContextSectionActions() {
        return (this.Vue.prototype.$fnMenus) ? this.Vue.prototype.$fnMenus.getMenuItems("contextSectionActions") : [];
    }

    /**
     * Get the context navigation items
     *
     * @return {[]}
     */
    getContextNavigation() {
        return (this.Vue.prototype.$fnMenus) ? this.Vue.prototype.$fnMenus.getMenuItems("contextNavigation") : [];
    }

    addContextAction(item, tag = "context") {
        this.addContextActions([item], tag);
    }

    addContextActions(items, tag = "context", clear = false) {
        if (items === null) return;
        if (this.Vue.prototype.$fnMenus) this.Vue.prototype.$fnMenus.addItems("contextActions", items, tag, clear);
    }

    removeContextActions(tag = "context") {
        if (this.Vue.prototype.$fnMenus) this.Vue.prototype.$fnMenus.removeItems("contextActions", tag);
    }

    clearContextActions() {
        if (this.Vue.prototype.$fnMenus) this.Vue.prototype.$fnMenus.removeItems("contextActions", null);
    }

    addContextSectionActions(items, tag = "context", clear = false) {
        if (items === null) return;
        if (this.Vue.prototype.$fnMenus) this.Vue.prototype.$fnMenus.addItems("contextSectionActions", items, tag, clear);
    }

    removeContextSectionActions(tag = "context") {
        if (this.Vue.prototype.$fnMenus) this.Vue.prototype.$fnMenus.removeItems("contextSectionActions", tag);
    }

    clearContextSectionActions() {
        if (this.Vue.prototype.$fnMenus) this.Vue.prototype.$fnMenus.removeItems("contextSectionActions", null);
    }

    addContextNavigationItem(item, tag = "context") {
        this.addContextNavigationItems([item], tag);
    }

    addContextNavigationItems(items, tag = "context", clear = false) {
        if (items === null) return;
        if (this.Vue.prototype.$fnMenus) this.Vue.prototype.$fnMenus.addItems("contextNavigation", items, tag, clear);
    }

    removeContextNavigationItems(tag = "context") {
        if (this.Vue.prototype.$fnMenus) this.Vue.prototype.$fnMenus.removeItems("contextNavigation", tag);
    }

    clearContextNavigationItems() {
        if (this.Vue.prototype.$fnMenus) this.Vue.prototype.$fnMenus.removeItems("contextNavigation", null);
    }

}
