import {get, merge} from "lodash";

let _config = {
    app: {
        name: "Foundry App",
        locale: "en",
        fallbackLocale: "en"
    }
};

/**
 * Sets the config of the application and returns a function for fetching a configuration from the configs
 *
 * @param {{}} config
 * @returns {function(*, *=): Exclude<{name: string, fallbackLocale: string, locale: string}, undefined>}
 */
export default function makeConfig(config){
    _config = merge(_config, config);

    return (key, def = null) => {
        return get(_config, key, def);
    }
}
