<template>
    <div class="d-flex flex-column h-100 justify-content-between">
        <div>
            <h4 class="text-left text-primary">{{ $t('Welcome!') }}</h4>
        </div>
        <div>
            <div class="mt-3">
                <form @submit.prevent="onSubmit">
                    <fn-form-schema ref="formSchema" :value="model" @input="onUpdate" no-inject @validated="onValidated">
                        <fn-form-group :schema="schema.first_name"></fn-form-group>
                        <fn-form-group :schema="schema.last_name"></fn-form-group>
                        <fn-form-group :schema="schema.email"></fn-form-group>
                        <fn-form-group :schema="schema.password"></fn-form-group>
                        <fn-form-group :schema="schema.password_confirmation"></fn-form-group>
                    </fn-form-schema>

                    <div class="form-group mt-4">
                        <fn-ui-button variant="primary" class="btn-block btn-rounded" :disabled="flags.invalid || submitting" :submitting="submitting" type="submit">{{$t("Register")}}</fn-ui-button>
                    </div>

                </form>

            </div>
            <div class="mt-4 pointer">
                <router-link class="text-dark font-14" :to="loginRoute">
                    {{$t("Or Login")}}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {AUTH_CHECK, AUTH_LOGIN} from "./index";
    import {LEASE_RETRIEVE} from "@/features/leases/screens"
    import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";
    import {withScreen} from "@fndry-vue/fn-screen";
    import {FnUiButton} from "@fndry-vue/fn-ui";

    import {FnFormGroup, FnFormSchema} from "@fndry-vue/fn-form";

    export default {
        name: "AuthRegister",
        mixins: [
            withAutoGuard,
            withScreen
        ],
        components: {
            FnFormSchema,
            FnFormGroup,
            FnUiButton
        },
        screen() {
            return {
                title: this.$t('Register'),
                data: {
                    noToggle: true,
                    withButton: true,
                    clearWave: true
                }
            }
        },
        data() {
            return {
                loading: false,
                showRegisterForm: true,
                submitting: false,
                flags: {
                    invalid: false,
                    dirty: false
                },
                schema: {
                    first_name: {
                        id: "id_first_name",
                        name: "first_name",
                        label: this.$t("First Name"),
                        type: "text",
                        rules: "required",
                        required: true
                    },
                    last_name: {
                        id: "id_last_name",
                        name: "last_name",
                        label: this.$t("Last Name"),
                        type: "text",
                        rules: "required",
                        required: true
                    },
                    email: {
                        id: "id_email",
                        name: "email",
                        label: this.$t("Email"),
                        type: "email",
                        rules: "email|required",
                        required: true
                    },
                    password: {
                        nameKey: "id_password",
                        id: "id_password",
                        name: "password",
                        label: this.$t("Password"),
                        type: "password",
                        required: true,
                        rules: 'required|regex:/^(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,}$/i'
                    },
                    password_confirmation: {
                        id: "id_password_confirmation",
                        name: "password_confirmation",
                        label: this.$t("Confirm Password"),
                        type: "password",
                        required: true,
                        rules: 'required|confirmed:password'
                    }
                },
                model: {
                    first_name: "",
                    last_name: "",
                    email: "",
                    password: "",
                    password_confirmation: "",
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                }
            };
        },
        mounted() {
            //ensure the user is logged out
            if (this.$fnAuth.isLoggedIn()) {
                this.loading = true;

                const uri = "/api/v2/auth/logout";

                this.$fnApi
                    .call(uri)
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        computed: {
            formUri() {
                return "/api/v2/auth/register";
            },
            loginRoute() {
                return {
                    name: AUTH_LOGIN,
                };
            },
        },
        methods: {
            onUpdate(value){
                this.model = {
                    ...value
                };
            },
            onValidated(flags) {
                this.flags = {
                    ...flags
                };
            },
            async onSubmit(){
                this.submitting = true;

                if (this.flags.valid === false) {
                    return;
                }

                let response = await this.$fnApi.call("/api/v1/mobile/register", "POST", {
                    ...this.model
                });

                if (response.status) {
                    this.onSuccess(response.data);
                } else {
                    if (response.code == 422) {
                        this.$refs.formSchema.setErrors(response.error);
                    }
                    this.$fnNotify(this.$t("Unable to register"));
                }

                this.submitting = false;

            },
            onSuccess(data){
                this.$fnNotify(this.$t("Account Registered. You are logged in."));

                let user = {
                    ...data.user,
                    mobile: {
                        ...data.mobile
                    }
                }

                //set the token to the localStorage
                sessionStorage.setItem("token", data.token);
                this.$fnAuth.setUser({user});
                this.$fnApi.$http.defaults.headers.common['Authorization'] = "Bearer " + data.token;

                if(user.mobile?.lease_details?.length > 0) {

                    this.$router.push({
                        name: AUTH_CHECK
                    });

                } else {

                    this.$router.push({
                        name: LEASE_RETRIEVE
                    });

                }

            }
        }
    };
</script>
