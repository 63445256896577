import LeasesScreen from "./LeasesScreen";

import CurrentLeasesTabScreen from "./LeasesScreen/CurrentLeasesTabScreen";
import ApprovedLeasesTabScreen from "./LeasesScreen/ApprovedLeasesTabScreen";
import PaidOffLeasesTabScreen from "./LeasesScreen/PaidOffLeasesTabScreen";

import LeaseDetailsScreen from "@/features/leases/screens/LeaseDetailsScreen";
import LeaseSummaryTabScreen from "./LeaseDetailsScreen/LeaseSummaryTabScreen";
import LeaseItemsTabScreen from "./LeaseDetailsScreen/LeaseItemsTabScreen";
import LeaseContractTermsTabScreen from "./LeaseDetailsScreen/LeaseContractTermsTabScreen";
import LeasePaymentHistoryTabScreen from "./LeaseDetailsScreen/LeasePaymentHistoryTabScreen";
import LeaseScheduledPaymentsTabScreen from "./LeaseDetailsScreen/LeaseScheduledPaymentsTabScreen";
import LeaseRetrieve from "./LeaseRetrieval/LeaseRetrieve"
import LeaseVerify from "./LeaseRetrieval/LeaseVerify"

export const LEASES_SCREEN = "leases";

export const CURRENT_LEASES_SCREEN = "leases/current";
export const APPROVED_LEASES_SCREEN = "leases/approved";
export const PAID_OFF_LEASES_SCREEN = "leases/paid-off";

export const LEASE_DETAILS_SCREEN = "lease";
export const LEASE_SUMMARY_SCREEN = "lease/summary";
export const LEASE_ITEMS_SCREEN = "lease/items";
export const LEASE_CONTRACT_TERMS_SCREEN = "lease/contract-terms";
export const LEASE_PAYMENT_HISTORY_SCREEN = "lease/payment-history";
export const LEASE_SCHEDULED_PAYMENTS_SCREEN = "lease/scheduled-payments";
export const LEASE_RETRIEVE = "leases/retrieve";
export const LEASE_VERIFY = "leases/verify";

export default {
    [LEASES_SCREEN]: LeasesScreen,
    [CURRENT_LEASES_SCREEN] : CurrentLeasesTabScreen,
    [APPROVED_LEASES_SCREEN] : ApprovedLeasesTabScreen,
    [PAID_OFF_LEASES_SCREEN] : PaidOffLeasesTabScreen,
    [LEASE_DETAILS_SCREEN] : LeaseDetailsScreen,
    [LEASE_SUMMARY_SCREEN] : LeaseSummaryTabScreen,
    [LEASE_ITEMS_SCREEN] : LeaseItemsTabScreen,
    [LEASE_CONTRACT_TERMS_SCREEN] : LeaseContractTermsTabScreen,
    [LEASE_PAYMENT_HISTORY_SCREEN] : LeasePaymentHistoryTabScreen,
    [LEASE_SCHEDULED_PAYMENTS_SCREEN] : LeaseScheduledPaymentsTabScreen,
    [LEASE_RETRIEVE]: LeaseRetrieve,
    [LEASE_VERIFY]: LeaseVerify,
};
