<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>

import withContext from "@fndry-vue/fn-context/mixins/withContext";
import {withScreen} from "@fndry-vue/fn-screen"
import ProfileTabs from "../../components/ProfileTabs";
import {PAYMENT_METHODS_SCREEN, PROFILE_MAIN_SCREEN} from "@/features/profile/screens";

export default {
    name: "DashboardScreen",
    mixins: [
        withContext,
        withScreen,
    ],
    components: {
        ProfileTabs
    },
    screen() {
        return {
            title: this.$t('Profile'),
            data: {
                withButton: true,
                tabs : [
                    {
                        name: this.$t('Profile'),
                        route_name: PROFILE_MAIN_SCREEN
                    },
                    {
                        name: this.$t('Payment Methods'),
                        route_name: PAYMENT_METHODS_SCREEN
                    },
                ]
            }
        }
    },
    context(){
        return false;
    }
};
</script>
