import WelcomeLayout from "@/layouts/WelcomeLayout";

import screens, {
    WELCOME_SCREEN
} from "./screens";

const Plugin = {
    install: function(Vue, {router}) {
        router.addRoute({
            path: "",
            component: WelcomeLayout,
            children: [
                {
                    path: "welcome",
                    name: WELCOME_SCREEN,
                    component: screens[WELCOME_SCREEN],
                    meta: {
                        middleware: [
                            "guest"
                        ]
                    }
                }
            ]
        })
    }
}

export default Plugin;
