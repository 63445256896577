<template>
    <div>
        <div>
            <h4 class="text-center text-gray-600">{{$t("One moment please...")}}</h4>
        </div>
        <div>
            <loader :loading="true"></loader>
        </div>
    </div>
</template>

<script>

import {AUTH_REGISTER} from "./index";
import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";

export default {
    name: "AuthInvite",
    mixins: [
        withAutoGuard
    ],
    props: {
        token: String
    },
    data() {
        return {
            loading: true
        };
    },
    mounted() {
        //ensure the user is logged out
        this.$fnApi.call("/api/v2/auth/logout").then(() => {
            this.$router.push({
                name: AUTH_REGISTER,
                query: {
                    invite: this.token
                }
            });
        });
    }
};
</script>
