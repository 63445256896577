import FoundryPlugin from "./foundry";
import VendorsPlugin from "./vendors";
import en from "./../locales/en.json"

const Plugins = {};

Plugins.install = function(Vue, options) {

    /**
     * Foundry Plugins
     */
    Vue.use(VendorsPlugin);

    /**
     * Foundry Plugins
     */
    Vue.use(FoundryPlugin, options);

    /**
     * Add locale json
     */
    options.locale.addLocale('en', en)

};

export default Plugins;
