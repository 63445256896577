import ChatScreen from "./SupportScreen/ChatScreen";
import FaqScreen from "./SupportScreen/FaqScreen";
import SupportScreen from "./SupportScreen";
import TopicScreen from "./TopicScreen";

export const CHAT_SCREEN = "support/chat";
export const FAQ_SCREEN = "support/faq";
export const SUPPORT_SCREEN = "support";
export const TOPIC_SCREEN = "support/faq/topic"

export default {
    [CHAT_SCREEN] : ChatScreen,
    [FAQ_SCREEN] : FaqScreen,
    [SUPPORT_SCREEN] : SupportScreen,
    [TOPIC_SCREEN] : TopicScreen,
}
