import VerifyMyIdentity from "./PayAsGuest/VerifyMyIdentity"
import EnterOtp from "./PayAsGuest/EnterOtp"
import CurrentLeases from "./PayAsGuest/CurrentLeases"
import CardDetails from "./PayAsGuest/CardDetails"
import PaymentSuccess from "./PayAsGuest/PaymentSuccess"

export const GUEST_VERIFY_IDENTITY = "guest/verify-identity";
export const GUEST_ENTER_OTP = "guest/enter-otp";
export const GUEST_LEASES = "guest/leases";
export const GUEST_CARD_DETAILS = "guest/card-details";
export const GUEST_PAYMENT_SUCCESS = "guest/payment-success";

export default {
    [GUEST_VERIFY_IDENTITY]: VerifyMyIdentity,
    [GUEST_ENTER_OTP]: EnterOtp,
    [GUEST_LEASES]: CurrentLeases,
    [GUEST_CARD_DETAILS]: CardDetails,
    [GUEST_PAYMENT_SUCCESS]: PaymentSuccess,
};
