import DefaultLayout from "./DefaultLayout";
import GuestLayout from "./GuestLayout";
import ErrorLayout from "./DefaultLayout";
import AuthLayout from "./AuthLayout";

import {DEFAULT_LAYOUT, ERROR_LAYOUT, AUTH_LAYOUT, GUEST_LAYOUT} from "@fndry-vue/fn-layout";

export {
    DEFAULT_LAYOUT,
    AUTH_LAYOUT,
    ERROR_LAYOUT,
    GUEST_LAYOUT,
};

export default {
    [DEFAULT_LAYOUT]: DefaultLayout,
    [AUTH_LAYOUT]: AuthLayout,
    [ERROR_LAYOUT]: ErrorLayout,
    [GUEST_LAYOUT]: GuestLayout,
};
