import mutations, {defaultState} from "./mutations";
import getters from "./getters";

export const namespace = "fnFilter";

export const filterStore = (action) => {
    return namespace + "/" + action;
};

export const store_ = {
    namespaced: true,
    state: {...defaultState},
    getters,
    mutations
};

export default {
    /**
     * Install the auth store into the app store
     *
     * @param store {{registerModule: function(string,Object)}}
     */
    install: function(store){
        store.registerModule(namespace, store_);
    }
};
