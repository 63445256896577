/**
 * Adds a computed property to return the panels
 */
export default {
    computed: {
        /**
         * @return {Array} The array of panels objects
         */
        panels: function(){
            return this.$store.state.fnPanels.panels;
        }
    },
    methods: {
        /**
         * Close the panel by its id
         *
         * @param id
         */
        onPanelClose(id){
            this.$fnPanels.close(id);
        },
        /**
         * Cancel the panels entirely
         */
        onPanelsCancel(){
            this.$fnPanels.closeAll();
        }
    }
};
