import Filters from "./services/filters";
import FnFilters from "./components/Filters";
/**
 * FnFilters
 *
 * (c) 2020
 */
const FnFiltersPlugin = {};

FnFiltersPlugin.install = function(Vue, {store}) {
    Vue.prototype.$fnFilters = new Filters(Vue, store);
};

export default FnFiltersPlugin;



export {
    FnFilters
};
