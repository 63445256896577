<template>
    <router-view></router-view>
</template>

<script>
import withContext from "@fndry-vue/fn-context/mixins/withContext";
import {withScreen} from "@fndry-vue/fn-screen"

import DetailsTabs from "../../components/DetailsTabs";
import {
    CURRENT_LEASES_SCREEN,
    LEASE_CONTRACT_TERMS_SCREEN,
    LEASE_ITEMS_SCREEN,
    LEASE_PAYMENT_HISTORY_SCREEN,
    LEASE_SCHEDULED_PAYMENTS_SCREEN,
    LEASE_SUMMARY_SCREEN
} from "@/features/leases/screens";

export default {
    name: "LeaseDetailsScreen",
    props: {
        details: Object,
        lease : Object,
    },
    mixins: [
        withContext,
        withScreen,
    ],
    components : {
        DetailsTabs
    },
    screen() {
        return {
            class: 'details-screen',
            data: {
                withButton: false,
                noToggle: true,
                withBack: true,
                backRouteName: CURRENT_LEASES_SCREEN,
                tabs : [
                    {
                        name: this.$t('Summary'),
                        route_name: LEASE_SUMMARY_SCREEN,
                        params: {
                            details : {...this.details},
                            lease: {...this.lease},
                        }
                    },
                    {
                        name: this.$t('Items'),
                        route_name: LEASE_ITEMS_SCREEN,
                        params: {
                            details : {...this.details},
                            lease: {...this.lease},
                        }
                    },
                    {
                        name: this.$t('Contract Terms'),
                        route_name: LEASE_CONTRACT_TERMS_SCREEN,
                        params: {
                            details : {...this.details},
                            lease: {...this.lease},
                        }
                    },
                    {
                        name: this.$t('Payment History'),
                        route_name: LEASE_PAYMENT_HISTORY_SCREEN,
                        params: {
                            details : {...this.details},
                            lease: {...this.lease},
                        }
                    },
                    {
                        name: this.$t('Scheduled Payments'),
                        route_name: LEASE_SCHEDULED_PAYMENTS_SCREEN,
                        params: {
                            details : this.details,
                            lease: this.lease,
                        }
                    },
                ],
                details: {...this.details},
            }
        }
    },
    context(){
        return false;
    },
    mounted() {
        if(!this.details) {
            this.$router.push({
                name: CURRENT_LEASES_SCREEN
            })
        }
    }
}
</script>
