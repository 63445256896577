import type from "./type";
import rules from "./rules";
import label from "./label";
import element from "./element";

export default {
    mixins: [
        type,
        element,
        rules,
        label
    ],
    props: {
        /**
         * The value for the input
         */
        value: {
            required: false
        },
        /**
         * The state of the input
         */
        state: {
            type: Boolean,
            required: false,
            default: null
        },
        /**
         * The name of the input
         */
        name: {
            type: String,
            required: false
        },
        /**
         * The placeholder
         */
        placeholder: String,
        /**
         * If the input should be disabled
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * If the input should be required
         */
        required: {
            type: Boolean,
            default: false
        },
        /**
         * The help text for the input
         */
        help: String,
        /**
         * The state of the autocomplete on the input
         */
        autocomplete: String,
        /**
         * If the input should be read only
         */
        readonly: {
            type: Boolean,
            default: false
        },
        /**
         * If the browser should auto focus on the input
         */
        autofocus: {
            type: Boolean,
            default: false
        },
        /**
         * The size of the input to display
         */
        size: {
            type: String,
            required: false
        },
        /**
         * The default value of the input
         */
        default: {
            required: false
        },
        /**
         * The values object if part of a form
         */
        values: {
            type: Object,
            required: false
        },
        /**
         * A key to help uniquely identify this input in the form
         */
        nameKey: String
    },
    computed: {
        /**
         * The default value
         */
        defaultValue: function(){
            return this.default;
        }
    },
}
