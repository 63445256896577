export const getStatus = "getStatus";
export const getUser = "getUser";
export const getAuthGuard = "getAuthGuard";
export const getAuthUi = "getAuthUi";

export default {

    /**
     * Get the current status
     *
     * @param state
     * @returns {boolean}
     */
    [getStatus]: state => state.status,

    /**
     * Get the auth user
     *
     * @param state
     * @returns {*}
     */
    [getUser](state) {
        return state.user;
    },

    /**
     * Get the auth guard
     *
     * @param state
     * @returns {*}
     */
    [getAuthGuard](state) {
        return state.guard;
    },

    /**
     * Get the auth ui
     *
     * @param state
     * @returns {*}
     */
    [getAuthUi](state) {
        return state.ui;
    },


};
