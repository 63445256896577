<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>

import withContext from "@fndry-vue/fn-context/mixins/withContext";
import {withScreen} from "@fndry-vue/fn-screen"
import SupportTabs from "../../components/SupportTabs";
import {CHAT_SCREEN, FAQ_SCREEN} from "@/features/support/screens";

export default {
    name: "SupportScreen",
    mixins: [
        withContext,
        withScreen,
    ],
    components: {
        SupportTabs
    },
    screen() {
        return {
            title: this.$t('Support'),
            data: {
                withButton: true,
                tabs : [
                    {
                        name: this.$t('Live Chat'),
                        route_name: CHAT_SCREEN
                    },
                ]
            }
        }
    },
    context(){
        return false;
    }
};
</script>
