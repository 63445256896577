const register = {};

/**
 * The middleware pipeline
 *
 * @param {{to: *, from: *, next: function, store: *}} context The context being run
 * @param {Array} middleware The middleware to run
 * @param {Number} index The next middleware index to pull
 * @returns {*}
 */
const pipeline = (context, middleware, index) => {

    let params = null;

    let nextMiddleware = middleware[index];

    let result;

    if(nextMiddleware){

        if (nextMiddleware.indexOf(":") !== -1) {
            params = nextMiddleware.split(":");
            nextMiddleware = params.shift();
            params = params.shift();

            if (params.indexOf(",") !== -1) {
                params = params.split(",");
            }
        }

        const method = register[nextMiddleware];
        if (!method) {
            throw new Error("Middleware of " + nextMiddleware + " not found!");
        }

        result = method(params, context);

        if (!result) {
            result = pipeline(context, middleware, index + 1);
        }
    }

    return result || context.next;
};

const middleware = {

    /**
     * Register a middleware handler
     *
     * @param {String} key A unique name for the handlers. E.G. auth, guest, etc.
     * @param {function(params, {to: *, from: *, next: function, store: *})} method The method to get run by the middleware
     */
    register(key, method) {
        register[key] = method;
    },

    /**
     * Get the pipeline to run the middleware with
     *
     * @returns {function(*, *, *): void}
     */
    pipeline(store, auth) {
        return (to, from, next) => {
            if (!to.meta.middleware) {

                //no middleware, move on
                next();

            } else {

                //Get the middleware to run
                let _middleware = to.meta.middleware;
                if (!Array.isArray(_middleware)) {
                    _middleware = [_middleware];
                }

                //send to the pipeline
                pipeline({
                    to,
                    from,
                    next,
                    store,
                    auth
                }, _middleware, 0)();
            }
        };
    }
};


export default middleware;
