import {uniqueId} from "lodash";
import {ADD_PANEL, CLOSE_PANEL} from "./mutations";

export const openPanel = "openPanel";
export const closePanel = "closePanel";
export const closeAll = "closeAll";

export default {
    /**
     * Open a panel
     *
     * @param commit
     * @param panel
     * @return {string}
     */
    async [openPanel]({commit}, panel) {
        let id = uniqueId("FnPanel-");
        commit(ADD_PANEL, {id, panel});
        return id;
    },
    /**
     * Close a panel
     *
     * @param state
     * @param commit
     * @param id
     */
    async [closePanel]({commit}, id){
        commit(CLOSE_PANEL, id);
        return true;
    },
    /**
     * Close all the panels
     *
     * @param state
     * @param dispatch
     * @return {*}
     */
    async [closeAll]({state, dispatch}){
        let id = state.index[0];
        return dispatch(closePanel, id);
    }
}
;
