<template>
  <div>
    <div class="row row-cols-2 mb-2">
      <div class="col-2 text-left">{{ $t('Store') }}</div>
      <div class="col-10 text-right">{{ lease?.store_name  ?? '-'}}</div>
    </div>

    <div v-if="applicationResults?.message?.options">
      <template v-for="(option, index) in applicationResults?.message?.options">
        <div class="row row-cols-2 mb-2">
          <div class="col text-left">{{ $t('Offer') }}</div>
          <div class="col text-right">{{ option[0]  ?? '-'}}</div>
        </div>

        <div class="row row-cols-2 mb-2">
          <div class="col text-left">{{ $t('Value') }}</div>
          <div class="col text-right">{{ option[1]  ?? '-'}}</div>
        </div>

        <div class="row row-cols-2 mb-2" v-if="option[2]">
          <div class="col-5 text-left">{{ $t('Due at Signing') }}</div>
          <div class="col-7 text-right">{{ option[2]  ?? '-'}}</div>
        </div>

        <div class="row row-cols-2 mb-3">
          <div class="col text-left">{{ $t('Valid for') }}</div>
          <div class="col text-right">180 Days</div>
        </div>
      </template>
    </div>

    <div v-else-if="offerAmounts">
      <template v-for="(offer, index) in offerAmounts">
        <div class="row row-cols-2 mb-2">
          <div class="col text-left">{{ $t('Offer') }}</div>
          <div class="col text-right">{{ $t('Option') + ' ' + index }}</div>
        </div>

        <div class="row row-cols-2 mb-2">
          <div class="col text-left">{{ $t('Value') }}</div>
          <div class="col text-right">$ {{ offer ?? '-'}}</div>
        </div>

        <div class="row row-cols-2 mb-3">
          <div class="col text-left">{{ $t('Valid for') }}</div>
          <div class="col text-right">180 Days</div>
        </div>
      </template>
    </div>

    <div class="row mb-3">
      <div class="col text-center small text-light">{{ $t('*Take this offer and visit your specified retailer to choose your merchandise') }}</div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ApprovedLeaseItem",
  props: {
    lease: Object
  },
  computed: {
    applicationResults() {
      return JSON.parse(this.lease?.application_result)
    },
    offerAmounts() {
      return JSON.parse(this.lease?.offer_amounts)
    }
  }
}
</script>
