<template>
    <div class="p-5">
        <div>
            <h4 class="text-center text-gray-600">{{$t("One moment please...")}}</h4>
        </div>
        <div>
            <loader :loading="true" transparent></loader>
        </div>
    </div>
</template>

<script>

import {CURRENT_LEASES_SCREEN} from "@/features/leases/screens"
import {AUTH_LOGIN} from "./index";
import {withScreen} from "@fndry-vue/fn-screen";
import {WELCOME_SCREEN} from "@/features/welcome/screens";

export default {
    name: "AuthCheck",
    mixins: [
        withScreen
    ],
    screen() {
        return {
            title: this.$t(''),
            data: {
                noToggle: true,
                withButton: false,
                clearWave: true
            }
        }
    },
    props: {
        redirect: {
            type: [String,Object],
            default() {
                return this.$route.query.redirect;
            }
        }
    },
    data() {
        return {
            loading: true
        };
    },
    mounted(){
        this.checkAuthenticated();
    },
    methods: {
        async checkAuthenticated(){

            let token = sessionStorage.getItem("token");
            if (token) {
                //set the token to the fnApi calls
                this.$fnApi.$http.defaults.headers.common['Authorization'] = "Bearer " + token;

                let response = await this.$fnApi.call("/api/v1/mobile/token/login", "POST");
                if (response.status) {
                    this.onSuccess(response.data);
                    this.checkRedirect();
                    return;
                }
            }

            this.$fnApi.$http.defaults.headers.common['Authorization'] = null;

            //redirect to the login
            this.redirectToLogin();
        },
        checkRedirect(){
            const user = this.$fnAuth.getUser();

            if (this.redirect) {
                this.$router.push(this.redirect);
            } else {
                this.$router.push({name: CURRENT_LEASES_SCREEN});
            }
        },
        redirectToLogin()
        {
            sessionStorage.removeItem("token");
            this.$fnAuth.removeUser();
            this.$fnApi.$http.defaults.headers.common['Authorization'] = null;
            this.$router.push({
                name: WELCOME_SCREEN,
            });
        },
        onSuccess(data){
            let user = {
                ...data.user,
                mobile: {
                    ...data.mobile
                }
            }
            this.$fnAuth.setUser({user});
        }
    }
};
</script>
