<template>
    <div class="d-flex flex-column h-100 justify-content-between">
        <div>
            <h4 class="text-left text-primary">{{ subTitle }}</h4>
        </div>
        <div>
            <div class="mt-3">
                <form @submit.prevent="handleSubmit">
                    <fn-form-schema ref="formSchema" :value="model" @input="onUpdate" no-inject @validated="onValidated">
                        <template #default="{ updateField }">
                            <fn-form-group :schema="schema.last_name"></fn-form-group>
                            <fn-form-group :schema="schema.ssn"></fn-form-group>
                            <fn-form-group :schema="dateOfBirth"></fn-form-group>
                        </template>
                    </fn-form-schema>
                    <div class="form-group mt-4">
                        <fn-ui-button variant="primary" class="btn-block btn-rounded" :disabled="flags.invalid || submitting" :submitting="submitting" type="submit">{{submitButtonText}}</fn-ui-button>
                    </div>
                </form>
            </div>
            <slot name="further-actions"></slot>
        </div>
    </div>
</template>

<script>
import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";
import { withScreen } from "@fndry-vue/fn-screen";
import { FnUiButton } from "@fndry-vue/fn-ui";
import { FnFormGroup, FnFormSchema } from "@fndry-vue/fn-form";
import moment from "moment";

export default {
    name: "VerifyIdentityComponent",
    mixins: [
        withAutoGuard,
        withScreen
    ],
    props: {
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            required: true
        },
        submitButtonText: {
            type: String,
            required: true
        },
        showSkipButton: {
            type: Boolean,
            default: true
        },
        noToggle: {
            type: Boolean,
            default: false
        },
        withButton: {
            type: Boolean,
            default: false
        },
    },
    components: {
        FnFormSchema,
        FnFormGroup,
        FnUiButton
    },
    screen() {
        return {
            title: this.title,
            data: {
                noToggle: this.noToggle,
                withButton: this.withButton,
                clearWave: true
            }
        }
    },
    data() {
        return {
            loading: false,
            submitting: false,
            flags: {
                invalid: false,
                dirty: false
            },
            schema: {
                last_name: {
                    id: "id_last_name",
                    name: "last_name",
                    label: this.$t("Last Name"),
                    type: "text",
                    rules: "required",
                    required: true
                },
                ssn: {
                    id: "id_ssn",
                    name: "ssn",
                    label: this.$t("SSN/ITIN"),
                    type: "text",
                    rules: "required",
                    required: true
                },
            },
            model: {
                last_name: "",
                date_of_birth: "",
                ssn: "",
            }
        };
    },
    computed: {
        formUri() {
            return "";
        },
        dateOfBirth() {
            let max = moment().subtract(16, 'years').format('YYYY-MM-DD');
            return {
                id: "id_date_of_birth",
                name: "date_of_birth",
                label: this.$t("DOB"),
                type: "date",
                format: 'YYYY-MM-DD',
                mask: "9999-99-99",
                data: {
                    datetime: {
                        showDecadeNav: true,
                        buttonOnly: true,
                        buttonVariant: 'primary',
                        maxDate: max
                    }
                },
                required: true
            }
        },
    },
    methods: {
        onUpdate(value) {
            this.model = {
                ...value
            };
        },
        onValidated(flags) {
            this.flags = {
                ...flags
            };
        },
        handleSubmit() {
            this.$emit('submit', this.model, this.flags);
        }
    }
};
</script>

<style scoped lang="scss">
::v-deep {
    button#id_date_of_birth {
        z-index: 10;
        color: #fff;
    }
    #id_date_of_birth__dialog_ {
        top: -5px !important;
        transform: translate3d(-271px, -324px, 0px) !important;
    }
}
</style>
