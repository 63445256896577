//ACTIONS
import {findIndex, uniqueId} from "lodash";
import {RESET_CONTEXT, SET_CONTEXT} from "./mutations";

export const changeContext = "changeContext";
export const setContext = "setContext";
export const refreshContext = "refreshContext";
export const detachReaction = "detachReaction";
export const attachReaction = "attachReaction";
export const beforeContextChange = "beforeContextChange";
export const afterContextChange = "afterContextChange";

/**
 * The list of reactions we will process
 *
 * @type {*[]}
 */
let reactions = [];

/**
 * Determine if the context matches
 *
 * @param reaction
 * @param context
 * @param subject
 * @return {boolean}
 */
function reactionMatches(reaction, context, subject) {
    let match = false;
    if (reaction.react && typeof reaction.react === "function") {
        match = reaction.react(context, subject);
    } else if (Array.isArray(reaction.context)) {
        match = reaction.context.indexOf(context) !== -1;
    } else if (typeof reaction.context === "string") {
        match = reaction.context === context;
    }

    return match;
}

function processContext({state, commit}, {context, subject}) {
    if (state.context) {
        //loop through the reactions and process each reaction if it matches the current context
        reactions.forEach((reaction, index) => {
            if (reactionMatches(reaction, state.context, state.subject) && reaction.leave instanceof Function) {
                reactions[index].leave(state.context, state.subject);
            }
        });
        //reset us back to no context
        commit(RESET_CONTEXT);
    }

    //find the new reaction matches
    //loop through the reactions and process each reaction if it matches the context
    reactions.forEach((reaction, index) => {
        if (reactionMatches(reaction, context, subject)) {
            reactions[index].enter(context, subject);
        }
    });
}

export default {

    /**
     * Attach a reaction to the context reactions
     *
     * @param {Reaction} reaction
     *
     * @return {String|void} The key for the reaction or void if reaction could not be attached
     */
    // eslint-disable-next-line no-unused-vars
    [attachReaction]({state}, reaction){

        if (reaction.enter === undefined) {
            console.error("Reaction for context '" + reaction.context + "' has no enter function and will be ignored!");
            return;
        }

        let key = uniqueId("r-");
        reactions.push(reaction);
        return key;
    },

    /**
     * Detach a reaction in the context reactions
     *
     * @param key The key for the reaction to remove
     * @return {boolean}
     */
    // eslint-disable-next-line no-unused-vars
    [detachReaction]({state}, key){
        let index = findIndex(reactions, (reaction) => reaction.key === key);
        if (index != -1) {
            reactions.slice(index, 1);
            return true;
        } else {
            return false;
        }
    },

    /**
     * Set the context
     *
     * @param commit
     * @param dispatch
     * @param title
     * @param context
     * @param subject
     * @return {Promise<string>} Returns the key for the new context
     */
    async [setContext]({state, commit, dispatch}, {title, context, subject = undefined}){

        await dispatch(beforeContextChange);

        let key = uniqueId("ctx_");

        processContext({state, commit}, {
            context,
            subject
        });

        commit(SET_CONTEXT, {
            key,
            title,
            context,
            subject
        });

        await dispatch(afterContextChange);

        return key;
    },


    /**
     * @private
     */
    async [beforeContextChange](){},

    /**
     * @private
     */
    async [afterContextChange](){},

    /**
     * Refresh the context
     *
     * @param commit
     * @param state
     * @param dispatch
     * @param subject
     * @return {Promise<string|null>} Returns the key for the refreshed context or null if no change
     */
    async [refreshContext]({commit, state}, {subject}){
        if (subject) {

            let key = uniqueId("ctx_");

            let context = state.name;

            let processed = processContext({state, commit}, {context, subject});

            commit(SET_CONTEXT, {
                key:
                context,
                subject,
                ...processed
            });

            return key;
        }
        return null;
    },
};
