<template>
    <BaseLayout>
        <template #header-content>
            <div v-if="user" class="fn-ui-layout-column-header" @click="toggleLayoutColumnShow(primaryMenu)">
                <fn-ui-avatar :src="user.profile_url" type="image"></fn-ui-avatar>
                <div class="p-3 d-flex flex-column">
                    <span class="font-12">{{ $t('Good Morning') }}, </span>
                    <div class="font-24 font-weight-bold">{{ user.display_name }}</div>
                </div>
            </div>
        </template>
    </BaseLayout>
</template>

<script>
import {
    FnUiAvatar,
} from "@fndry-vue/fn-ui";
import BaseLayout from "./BaseLayout.vue";

export default {
    name: "AuthLayout",
    components: {   
        FnUiAvatar,
        BaseLayout,
    },
    computed: {        
        user: function() {
            let profile = this.$fnAuth.getUser();
            if (profile?.id) {
                return profile;
            }
            return null;
        },
    }
};
</script>
