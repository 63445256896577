import AuthRegister from "./AuthRegister";
import AuthLogin from "./AuthLogin";
import AuthForgot from "./AuthForgot";
import AuthReset from "./AuthReset";
import AuthCheck from "./AuthCheck";
import AuthLogout from "./AuthLogout";
import AuthVerify from "./AuthVerify";
import AuthInvite from "./AuthInvite";
import AuthOneTimeLogin from "./AuthOneTimeLogin";
import AuthSetPassword from "./AuthSetPassword";
import AuthForgotNotify from "./AuthForgotNotify";

export const AUTH_LOGIN = "auth/login";
export const AUTH_LOGIN_ONCE = "auth/login/one-time";
export const AUTH_REGISTER = "auth/register";
export const AUTH_SET_PASSWORD = "auth/set-password";
export const AUTH_FORGOT = "auth/forgot";
export const AUTH_FORGOT_NOTIFY = "auth/forgot/notify";
export const AUTH_RESET = "auth/reset";
export const AUTH_CHECK = "auth/check";
export const AUTH_LOGOUT = "auth/logout";
export const AUTH_VERIFY = "auth/verify";
export const AUTH_INVITE = "auth/invite";

export default {
    [AUTH_LOGIN]: AuthLogin,
    [AUTH_FORGOT]: AuthForgot,
    [AUTH_FORGOT_NOTIFY]: AuthForgotNotify,
    [AUTH_REGISTER]: AuthRegister,
    [AUTH_RESET]: AuthReset,
    [AUTH_CHECK]: AuthCheck,
    [AUTH_LOGOUT]: AuthLogout,
    [AUTH_VERIFY]: AuthVerify,
    [AUTH_INVITE]: AuthInvite,
    [AUTH_LOGIN_ONCE]: AuthOneTimeLogin,
    [AUTH_SET_PASSWORD]: AuthSetPassword
};
