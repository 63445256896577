import MediumEditor from "medium-editor";

global.MediumEditor = MediumEditor;

const MediumEditorTextcolor = require("medium-editor-textcolor/dist/medium-editor-textcolor.js");

export {
    MediumEditorTextcolor
};

export default MediumEditor;
