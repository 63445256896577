export const SET_LOADED = "SET_LOADED";
export const SET_LOADING = "SET_LOADING";

/**
 * @type {{loading: boolean, loaded: boolean}}
 */
export const defaultState = {
    loading: false,
    loaded: false,
};

export default {
    /**
     * Set the loaded state of the app
     *
     * @param state
     * @param loaded
     */
    [SET_LOADED](state, loaded){
        state.loaded = loaded;
    },
    /**
     * Set the loading state of the app
     *
     * @param state
     * @param loading
     */
    [SET_LOADING](state, loading){
        state.loading = loading;
    },
};
