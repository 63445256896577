export default {
    props: {
        compact: Boolean,
        show: Boolean,
    },
    data() {
        return {
            hovered : false,
            compactWhileHovered: false
        }
    },
    computed: {
        computedCompact() {
            if(this.compactWhileHovered) {
                return true;
            } else {
                return this.compact && (!this.show && !this.hovered);
            }
        }
    },
    methods: {
        onMouseOver() {
            this.hovered = true;
        },
        onMouseLeave() {
            this.hovered = false;
            this.compactWhileHovered = false;
        }
    }
}
