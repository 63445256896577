import store_, {modalStore} from "./store";

import Modal from "./components/Modal";
import Modals from "./components/Modals";
import ModalButton from "./components/ModalButton";
import modalsMixin from "./mixins/modalsMixin";
import modalFormMixin from "./mixins/modalFormMixin";
import {closeModal, openModal} from "./store/actions";
import {UPDATE_MODAL_PROPS} from "./store/mutations";
import ConfirmModal from "./components/ConfirmModal";
import AlertModal from "./components/AlertModal";

/**
 * Modals object
 */
class ModalsRegistry {

    constructor(Vue, store) {
        // Install the Auth Store
        store_.install(store);
        this.store = store;
    }

    /**
     * Open a modal
     *
     * @param {Object} component The component to render in the modal
     * @param {Object} props The props for the component
     * @param {Object} on The listener bindings
     * @return {Promise} Returns of promise which has the modal id as the accept method's first param
     */
    open({component, props, on}) {
        return new Promise((resolve, reject) => {
            this.store.dispatch(modalStore(openModal), {
                component,
                props,
                on: {
                    ...on,
                    resolve,
                    reject
                }
            });
        });
    }

    /**
     * Close a modal
     *
     * @return {Promise}
     */
    close(id = undefined){
        return this.store.dispatch(modalStore(closeModal), id);
    }

    /**
     * Close all the modals
     *
     * @return {Promise}
     */
    closeAll(){
        return this.store.dispatch(modalStore(closeModal));
    }

    /**
     * Update the modal props
     *
     * @param id
     * @param props
     */
    updateProps(id, props){
        this.store.commit(modalStore(UPDATE_MODAL_PROPS), {id, props});
    }

    /**
     * Remove a modal from the modals
     *
     * @param id
     */
    remove(id) {
        return this.close(id);
    }

    /**
     * Cancel all the modals
     * @return {Promise}
     * @deprecated Use closeAll
     */
    cancel(){
        return this.store.dispatch(modalStore(closeModal));
    }

    /**
     * Confirm
     * @param message
     * @param props
     * @param component
     * @return {Promise}
     */
    confirm(message, props = {}, on = {}, component = undefined){
        return this.open({
            component: component ?? ConfirmModal,
            props: {
                centered: true,
                ...props,
                message
            },
            on
        });
    }

    /**
     * Confirm
     * @param message
     * @param props
     * @param component
     * @return {Promise}
     */
    alert(message, props = {}, component = undefined){
        return this.open({
            component: component ?? AlertModal,
            props: {
                centered: true,
                ...props,
                message
            }
        });
    }
}


/**
 * FnModals
 *
 * (c) 2020
 */
const FnModalsPlugin = {};

FnModalsPlugin.install = function(Vue, {store}) {
    Vue.prototype.$fnModals = new ModalsRegistry(Vue, store);

    // Vue.component("fn-modal-button", ModalButton);

};

export default FnModalsPlugin;

export {
    Modals as FnModals,
    Modal as FnModal,
    ModalButton as FnModalButton,
    modalsMixin as fnModalsMixin,
    modalFormMixin as fnModalFormMixin,
    // ModalsRegistry as FnModalsRegistry
};

