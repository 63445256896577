import store_, {layoutStore} from "./store";
import {registerColumn, updateColumn} from "./store/actions";
import {getColumn, getColumns} from "./store/getters";
import layoutMixin from "./mixins/layoutMixin";
import {router} from "../fn-app";

/**
 * Constants
 */
export const AUTH_LAYOUT = "auth";
export const DEFAULT_LAYOUT = "default";
export const GUEST_LAYOUT = "guest";
export const ERROR_LAYOUT = "error";

class LayoutRegistry {

    constructor(Vue, store) {
        // Install the Auth Store
        store_.install(store);
        this.store = store;
    }

    /**
     * Register a layout column
     *
     * @param name
     * @param props
     */
    registerColumn(name, props) {
        this.store.dispatch(layoutStore(registerColumn), {name, ...props});
    }

    getColumn(name) {
        return this.store.getters[layoutStore(getColumn)](name);
    }

    getColumns() {
        return this.store.getters[layoutStore(getColumns)]();
    }

    toggleColumnCompact(name, compact) {
        const column = this.getColumn(name);

        const value = (compact !== undefined) ? compact : !column.compact;

        this.setColumnCompact(name, value);
    }

    toggleColumnShow(name) {
        const column = this.getColumn(name);

        this.store.dispatch(layoutStore(updateColumn), {
            name,
            show: !column.show
        });
    }

    setColumnCompact(name, compact) {
        this.store.dispatch(layoutStore(updateColumn), {
            name,
            compact
        });
    }

    setAllColumnCompact(compact, except) {
        const columns = this.getColumns();

        columns.forEach(column => {
            if (except && column.name === except) {
                return;
            }

            this.setColumnCompact(column.name, compact);
        });
    }

    setColumnShow(name, show) {
        this.store.dispatch(layoutStore(updateColumn), {
            name,
            show
        });
    }

    setAllColumnShow(show, except) {
        const columns = this.getColumns();

        columns.forEach(column => {
            if (except && column.name === except) {
                return;
            }

            this.setColumnShow(column.name, show);
        });
    }
}

const FnLayoutPlugin = {};

FnLayoutPlugin.install = function(Vue, {router, store}) {
    Vue.prototype.$fnLayout = new LayoutRegistry(Vue, store);

    /**
     * Close side navigation when route changed
     */
    router.beforeEach((to, from, next) => {
        Vue.prototype.$fnLayout.setAllColumnShow(false);

        next();
    });
}

export default FnLayoutPlugin;

export {
    layoutMixin as fnLayoutMixin,
};
