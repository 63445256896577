/**
 * FnScreen
 *
 * (c) 2023
 */

import Screen from "./services/screen";
import withScreen from "./mixins/withScreen"

const FnScreenPlugin = {};

FnScreenPlugin.install = function(Vue, {store}) {
    Vue.prototype.$fnScreen = new Screen(store, Vue);
};

export default FnScreenPlugin;

export {
    Screen,
    withScreen
};

