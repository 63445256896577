<template>
    <div>
        <div>
            <h4 class="text-center text-gray-600">{{$t('One Time Login')}}</h4>
        </div>
        <div>
            <loader v-if="loading" :loading="true"></loader>
            <div v-else>
                <p class="text-center text-gray-600">{{$t("Unable to process your one time login. It might have expired. Please try login below or contact support.")}}</p>
                <p><b-button variant="primary" block :to="loginRoute">{{$t("Login")}}</b-button></p>
            </div>
        </div>
    </div>
</template>

<script>
    import {AUTH_CHECK, AUTH_LOGIN} from "./index";
    import {setRedirectTo} from "ui-auth/src/features/auth";
    import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";

    export default {
        name: "AuthOneTimeLogin",
        mixins: [
            withAutoGuard
        ],
        props: {
            token: String,
            redirect: {
                type: [String,Object],
                default() {
                    return this.$route.query.redirect;
                }
            }
        },
        data() {
            return {
                loading: false
            };
        },
        computed: {
            formUri() {
                return "/api/v2/auth/verify";
            },
            loginRoute() {
                return {
                    name: AUTH_LOGIN
                };
            },
        },
        mounted(){
            if (this.redirect){
                setRedirectTo(this.redirect);
            }
            this.doLogin(this.$route.query.code);
        },
        methods: {
            doLogin(code){
                this.loading = true;
                const uri = "/api/v2/auth/verify";
                const data = this.getGuardUriParams({
                    token: this.token,
                    otp: code
                });

                this.$fnApi
                    .call(uri, "POST", data)
                    .then((response) => {
                        if (response.status) {
                            this.$fnNotify(this.$t("Welcome!"));
                            this.$fnAuth.setUser(response.data);

                            this.$router.push({
                                name: AUTH_CHECK
                            });
                        } else {
                            this.loading = false;
                        }
                    })
                ;
            }
        }
    };
</script>
