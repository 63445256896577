export default {
    props: {
        title: String
    },
    data() {
        return{
            formPanel: null,
            infoPanel: null,
        };
    },
    computed: {
        withInfo() {
            return !!this.infoPanel;
        },
        infoContent() {
            return (this.infoPanel) ? this.infoPanel.content : "";
        },
        infoTitle() {
            return (this.infoPanel) ? this.infoPanel.title : "";
        },
        formTitle() {
            return (this.formPanel) ? this.formPanel.title : this.title;
        },
        formHeading() {
            return (this.formPanel) ? this.formPanel.heading : "";
        },
        formInstruction() {
            return (this.formPanel) ? this.formPanel.instruction_text : "";
        },
        formIdentifier() {
            return (this.formPanel) ? this.formPanel.identifier : null;
        }
    },
    methods: {
        onSchemaSet(schema) {
            if (schema.form_panel) {
                this.formPanel = schema.form_panel;

                if (schema.form_panel.info_panel) {
                    this.infoPanel = schema.form_panel.info_panel;
                }
            }
        }
    },
};
