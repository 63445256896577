<template>
    <b-carousel class="payment-select" controls :interval="0" @sliding-end="handleChangePayment"> <!-- Add "controls" attribute to this tag for swipe controls-->
        <b-carousel-slide v-for="(paymentMethod, index) in paymentMethods" :key="index">
            <template #img>
                <card-item
                v-if="paymentMethod.payment_type === 'card' && isPaylianceCard(paymentMethod.provider)"
                :card="paymentMethod"
                :is-default-payment="isDefault('card', paymentMethod.id)"
                :is-payliance-card="isPaylianceCard(paymentMethod.provider)"
                no-actions
                ></card-item>
                <bank-item v-else :bank="paymentMethod" no-actions></bank-item>
            </template>
        </b-carousel-slide>
        <b-carousel-slide>
            <template #img>
                <div class="card-item card shadow-sm d-flex flex-column justify-content-center align-items-center my-3" @click="$emit('add-card')">
                    <span class="font-20 font-weight-medium">{{ $t('Add New Card') }}</span>
                    <icon class="text-primary" icon="plus-circle" size="2x"></icon>
                </div>
            </template>
        </b-carousel-slide>
    </b-carousel>
</template>

<script>
import BankItem from "../../profile/components/BankItem";
import CardItem from "../../profile/components/CardItem";

export default {
    name: "PaymentSelect",
    components: {
        BankItem,
        CardItem,
    },
    props: {
        banks : {
            type: Array,
            default:() => []
        },
        cards : {
            type: Array,
            default:() => []
        },
        default: Object,
        required: true
    },
    computed: {
        paymentMethods() {
            let default_payment = this.getDefaultPayment();
            let payment_methods = [];

            if(default_payment) {
                payment_methods.push(default_payment);
            }

            this.cards.forEach(item => {
                if(item?.id !== default_payment?.id) {
                    payment_methods.push({
                        payment_type : 'card',
                        ...item
                    })
                }
            })

            this.banks.forEach(item => {
                if(item?.id !== default_payment?.id) {
                    payment_methods.push({
                        payment_type : 'bank',
                        ...item
                    })
                }
            })

            return payment_methods;
        }
    },
    methods: {
        getDefaultPayment() {
            if(this.default?.id && this.default?.payment_type === 'bank') {
                let bank = this.banks.find((item) => {
                    return this.default?.id === item?.id;
                })
                if(bank) {
                    return {
                        payment_type : 'bank',
                        ...bank
                    }
                }
            } else {
                let activeCard = this.cards?.find(c => c.is_active);

                if(activeCard) {
                    return {
                        payment_type : 'card',
                        ...activeCard
                    }
                }
            }
            return null; 
        },
        handleChangePayment(index) {
            if(this.paymentMethods[index]) {
                this.$emit('payment-change', this.paymentMethods[index])
            } else {
                this.$emit('payment-change', null)
            }
        },
        isDefault(type, id) {
            return this.default?.payment_type === type && this.default?.id === id;``
        },
        isPaylianceCard(provider) {
            return provider == "payliance"
        },
    },
    mounted() {
        if(this.paymentMethods[0]) {
            this.$emit('payment-change', this.paymentMethods[0])
        }
    }
}
</script>
