<template>
    <div>
        <div class="text-primary font-weight-bold">{{ topic.title }}</div>

        <div class="mt-5 pt-5">
            <FnUiAccordion class="faq-accordion" :list="questions">
                <template v-slot:header(default)="item">
                    <div class="px-4 py-3 border-bottom border-light d-flex justify-content-between align-items-center pointer">
                        <div class="mr-3 bg-primary rounded-circle p-2">
                            <icon class="text-white" icon="bullseye-pointer" type="solid" size="xl"></icon>
                        </div>
                        <span class="font-16 text-dark">{{ item.title }}</span>
                        <icon class="ml-auto text-dark icon-not-collapsed" icon="chevron-down" size="xl"></icon>
                        <icon class="ml-auto text-dark icon-collapsed" icon="chevron-right" size="xl"></icon>
                    </div>
                </template>
            </FnUiAccordion>
        </div>
    </div>
</template>

<script>
import {FAQ_SCREEN} from "../screens/index";
import withContext from "@fndry-vue/fn-context/mixins/withContext";
import {withScreen} from "@fndry-vue/fn-screen"
import {FnUiAccordion} from "@fndry-vue/fn-ui";

export default {
    name: "TopicScreen",
    mixins: [
        withContext,
        withScreen,
    ],
    components: {
        FnUiAccordion
    },
    props: {
        topic : Object
    },
    screen() {
        return {
            class: 'details-screen',
            data: {
                withButton: false,
                noToggle: true,
                withBack: true,
            }
        }
    },
    context(){
        return false;
    },
    computed: {
        questions() {
            if(this.topic?.questions.length > 0) {
                return this.topic.questions.map((item, index) => {
                    return {
                        title: item.question,
                        text : item.answer,
                        name: 'default',
                        visible: index === 0,
                        headerContainerClass : 'question-header'
                    }
                })
            }
            return []
        }
    },
    mounted() {
        if(!this.topic) {
            this.$router.push({
                name: FAQ_SCREEN
            })
        }
    },
}
</script>

<style lang="scss">
.faq-accordion {
    .card {
        box-shadow: none;
        background-color: transparent;
    }
    .question-header {
        background-color: transparent;
        padding: 0;

        .not-collapsed .icon-collapsed {
            display: none;
        }
        .collapsed .icon-not-collapsed {
            display: none;
        }
    }
}
</style>
