<template>
    <VerifyIdentityComponent 
        @submit="onSubmit" 
        :sub-title="$t('ENTER THE FOLLOWING DETAILS...')"
        :submit-button-text="$t('Retrieve my Account')"
        :title="$t('Retrieve my Account')"
        ref="verifyIdentityComponent"
    >
        <template #further-actions>
            <div class="mt-4 pointer">
                <router-link class="text-dark font-14" :to="dashboardRoute">
                    {{$t("Skip for now")}}
                </router-link>
            </div>
        </template>
    </VerifyIdentityComponent>
</template>

<script>
import VerifyIdentityComponent from '@/features/leases/components/VerifyIdentityComponent';
import { LEASE_VERIFY } from "@/features/leases/screens/index";
import { CURRENT_LEASES_SCREEN } from "@/features/leases/screens";
import { AUTH_CHECK } from "@/features/auth/screens/index";

export default {
    name: "LeaseRetrieve",
    components: {
        VerifyIdentityComponent
    },
    computed: {
        dashboardRoute() {
            return {
                name: AUTH_CHECK,
            };
        },
    },
    methods: {
        async onSubmit(model, flags) {
            this.$refs.verifyIdentityComponent.submitting = true;

            if (flags.invalid) {
                return;
            }

            let response = await this.$fnApi.call("/api/v1/mobile/leases/retrieve", "POST", {
                ...model
            });

            if (response.status) {
                this.onSuccess(model);
            } else {
                if (response.code == 404) {
                    this.$refs.formSchema.setErrors(response.error ?? "Unknown error.");
                    this.$fnNotify({ message: this.$t("No previous application found!"), type: 'error' });
                } else if (response.code == 409) {
                    this.$fnNotify(this.$t("Leases already connected to your account."));
                    this.$router.push({
                        name: CURRENT_LEASES_SCREEN
                    });
                } else {
                    this.$fnNotify({ message: this.$t("No previous application found!"), type: 'error' });
                }
            }

            this.$refs.verifyIdentityComponent.submitting = false;
        },
        onSuccess(model) {
            this.$fnNotify(this.$t("Verification email sent. Please check and proceed."));

            this.$router.push({
                name: LEASE_VERIFY,
                params: {
                    last_name: model.last_name,
                    date_of_birth: model.date_of_birth,
                    ssn: model.ssn
                }
            });
        }
    }
};
</script>
