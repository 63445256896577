<template>
    <b-modal :id="modalId" v-model="modalShow" title="Payment" visible v-on="$listeners" ok-only :ok-title="$t('Confirm Payment')"
        @ok="handleConfirmPayment" centered>
            <loader :loading="loadingPayment" class="w-100">

        <template v-bind="{ ok }">

                <fn-form-schema ref="form" v-model="form" @validated="handleValidated">
                    <template>
                        <div class="row mb-2">
                            <div class="col font-18 text-primary font-weight-bold">{{ $t('Payment for ') + store_name }}
                            </div>
                        </div>
                        <div>
                            <div class="row row-cols-2 mb-2">
                                <div class="col text-left">{{ $t('Start Date') }}</div>
                                <div class="col text-right">
                                    <fn-format-date format="MM/DD/YYYY" :value="start_date" v-if="start_date"
                                        :ignore-time-zone="true"></fn-format-date>
                                    <span v-else>---</span>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col">
                                <label class="mb-0" for="pay_amount">{{ $t('How much do you want to pay?') }}</label>
                                <fn-type-money-input id="pay_amount" :format="'$0,0.00'" v-model=form.pay_amount
                                    :minLength="0" :maxLength="999999"></fn-type-money-input>
                            </div>
                        </div>

                    </template>
                </fn-form-schema>
        </template>
</loader>

    </b-modal>

</template>

<script>
import { fnModalFormMixin } from "@fndry-vue/fn-modals";
import { FnFormSchema, FnFormGroup } from "@fndry-vue/fn-form";
import { FnTypeMoneyInput } from "@fndry-vue/fn-types";

const PayModal = {
    name: "PayModal",
    mixins: [
        fnModalFormMixin
    ],
    props: {
        loan_number: Number,
        store_name: String,
        start_date: String,
    },
    components: {
        FnFormSchema,
        FnFormGroup,
        FnTypeMoneyInput
    },
    data() {
        return {
            form: {
                pay_amount: 0
            },
            loadingPayment: false,
            modalShow: true,
        };
    },
    computed: {
        modalId() {
            return  "loan_" + (this.loan_number ?? "");
        }
    },
    methods: {
        async handleConfirmPayment(bvModalEvent) {

            bvModalEvent.preventDefault();

            if(this.loadingPayment)
                return;

            this.loadingPayment = true;

            await this.$fnApi.call('/api/v1/mobile/guest/check-payment-amount-validity', "POST",
                {
                    loan_number: this.loan_number ?? "",
                    amount: this.form?.pay_amount,
                })
                .then((response) => {
                    if (response.status) {
                        this.modalShow = false;
                        this.$emit("resolve", this.form);
                    } else {
                        this.$fnNotify({
                            message: this.$t("Please enter a valid amount or log into your account to see your minimum due. You may also call Customer Service"),
                            type: "error"
                        });
                    }
                }).finally(() => {
                    this.loadingPayment = false;
                });
        },
    }
}

export default PayModal;
</script>

<style scoped></style>
