export const getModal = "getModal";
export const getModalProps = "getModalProps";

export default {
    [getModal]: (state) => (id) => {
        return state.modals[id];
    },
    [getModalProps]: (state) => (id) => {
        return state.modals[id].props;
    }
};
