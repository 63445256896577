import {$_fnForm, $_fnSchemaStore} from "../components/common";
import SchemaStore from "../stores/schema";

/**
 * The withSchemaStore mixin connects a component to a schema store for the given schema
 */
export default {
    provide: function () {
        return {
            /**
             * This will provide the locally created or pass in schema store to subcomponents that will inject it
             */
            [$_fnSchemaStore]: this.schemaStore
        };
    },
    props: {
        /**
         * @type {Object|undefined} The value prop if provided
         */
        value: {
            required: false
        },
        /**
         * The schema to load into the store (optional)
         *
         * @type {{}}
         */
        schema: {
            type: Object
        },

        /**
         * Flag to force the schema store creation
         *
         * This is used when doing forms within forms
         */
        noInject: Boolean,

        /**
         * The schema store if managed further up (optional)
         *
         * If the schema isn't provided, the schemaStore must be provided
         *
         * @type {SchemaStore}
         */
        schemaStore: {
            type: Object,
            default(){
                if (!this.noInject && this[$_fnForm] && this[$_fnForm].schemaStore) {
                    return this[$_fnForm].schemaStore;
                } else {
                    return new SchemaStore(this.value);
                }
            }
        }
    },
    computed: {
        schemaState(){
            return this.schemaStore.state;
        },
    },
    created(){
        if (!this.schemaStore.isLoaded() && this.schema) {
            this.schemaStore.load(this.schema, this.value);
        }
    },
    mounted(){
        this.schemaStore.listen(this.$listeners);
    }
};
