import Axios from "axios";
import jQuery from "jquery";
import {forEach, merge, get} from "lodash";

window.jQuery = window.$ = jQuery;

import VueLang from "@eli5/vue-lang-js";
import FnApiPlugin from "@fndry-vue/fn-api";

import makeRouter from "./bootstrap/routes";
import makeStore from "./bootstrap/store";

import locale from "./bootstrap/locale";
import middleware from "./bootstrap/middleware";

import {appStore} from "./store";
import {
    SET_LOADED,
} from "./store/mutations";
import makeConfig from "./bootstrap/config";

function env(key, def = null){
    return process.env.hasOwnProperty(key) ? process.env[key] : def;
}

/**
 * Plugin
 * (c) 2019
 * @license MIT
 */
const AppPlugin = {};

/**
 * Plugin API
 */
AppPlugin.install = function (Vue, {config}) {
    /**
     * @type {(function(*, *=): any)|*}
     */
    Vue.prototype.$config = config;
};

let router, store, config;

/**
 * Create the Foundry Vue Application
 *
 * @param {Vue} Vue
 * @param {String} el The base querystring to locate the root element that Vue will attach to. This should be #app.
 * @param {String} template The root Vue template to run the application with. This should be "<App />" and use your main App.vue component.
 * @param {Object} components The root components to register if any
 * @param {Array} features The array of features that have been added to the application through AppFeatures.
 * @param {Object} config The config to override the defaults in the application
 * @param {Object} layouts The layouts available to the application and features
 * @returns {*} The Vue
 */
const createApp = (Vue, {el, template, components, features, config: appConfig, layouts}) => {

    /**
     * Make the config method with the application configuration details
     *
     * @type {(function(*, *=): any)|*}
     */
    config = makeConfig(appConfig);

    /**
     * Load the base FnApiPlugin for calling api services
     */
    Vue.use(AppPlugin, {config});

    /**
     * Make the store object
     *
     * @type {Store}
     */
    store = makeStore(Vue);

    /**
     * Make the router object
     *
     * @type {VueRouter}
     */
    router = makeRouter(Vue);

    /**
     * Load the base FnApiPlugin for calling api services
     */
    Vue.use(FnApiPlugin, {Axios, jQuery, store, config});

    /**
     * Process all the features and run Vue.use on each, passing in the router, store, locale, layouts, config, and middleware
     */
    if (features && features.length > 0) {
        forEach(features, (feature) => {
            Vue.use(feature, {
                router,
                store,
                locale,
                layouts,
                config,
                middleware
            });
        });
    }

    /**
     * Add the base translations
     */
    Vue.use(VueLang, {
        locale: config("app.locale"),
        messages: locale.getLocales(),
        fallback: config("app.fallbackLocale")
    });

    /**
     * Create the root Vue instance
     *
     * @type {Vue}
     */
    const App = new Vue({
        el,
        template,
        router,
        components,
        store
    });

    /**
     * Run the init on all features which allows the features to work with the Vue instances now that it is created
     */
    forEach(features, (feature) => {
        if (feature.init instanceof Function) {
            feature.init(App);
        }
    });

    /**
     * Mark the application as loaded
     */
    App.$store.commit(appStore(SET_LOADED), true);

    return App;
};

export default createApp;

export class AppFeatures {

    constructor() {
        /**
         * App features
         *
         * @type {*[]}
         */
        this.features = [];
    }

    /**
     * Add a Feature to the App
     *
     * @param feature
     */
    addFeature(feature){
        this.features.push(feature);
    }

    /**
     * Add an array of features
     *
     * @param features
     */
    addFeatures(features){
        forEach(features, (feature) => {
            this.addFeature(feature);
        });
    }
}

export {
    router,
    store,
    env,
    config,
    appStore,
};
