<template>
  <div>
    <div class="row mb-2">
      <div class="col font-18 text-primary font-weight-bold">{{ $t('Next Installment') }}</div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Lease Name') }}</div>
      <div class="col text-right">{{ lease.store_name + ' ' +$t('Payment') }}</div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Installment Amount') }}</div>
      <div class="col text-right">
        <fn-format-currency v-if="pending.amount" :value="pending.amount"></fn-format-currency>
        <fn-format-currency v-else :value="pending.amount_ach"></fn-format-currency>
      </div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Due Date') }}</div>
      <div class="col text-right">
        <fn-format-date format="MM/DD/YYYY" :value="pending.pay_at" :ignore-time-zone="true"></fn-format-date>
      </div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Payment Method') }}</div>
      <div class="col text-right">{{ isDefaultPaymentCard ? $t('Credit Card') : $t('Bank') }}</div>
    </div>

    <div class="row row-cols-2 mb-3" v-if="isDefaultPaymentCard">
      <div class="col text-left">{{ $t('Card Ending With') }}</div>
      <div class="col text-right">**** **** **** {{ defaultPayment.payment_details.last4 }}</div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <b-progress :max="100" class="installment-progress">
          <label class="progress-text position-absolute">{{ getPercent(lease.extra.PctPymtMade) +' '+ $t('Paid off') }}</label>
          <b-progress-bar :value="25" class="text-center"></b-progress-bar>
        </b-progress>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col">
        <div class="btn btn-outline-dark btn-block btn-lg px-5 d-flex justify-content-between align-items-center">
          <span class="font-14">{{ $t('More Details') }}</span>
          <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="btn btn-outline-dark btn-block btn-lg px-5 d-flex justify-content-between align-items-center" @click="payEarly">
          <span class="font-14">{{ $t('Pay Early') }}</span>
          <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PendingPaymentItem",
  props: {
    pending: Object,
    lease: Object,
    defaultPayment: Object,
  },
  computed: {
    isDefaultPaymentCard() {
      return this.defaultPayment.payment_type === 'card';
    },
  },
  methods: {
    getPercent(val) {
      return val*100 + "%";
    },
    payEarly() {
        this.$emit('pay-early')
    }
  }
}
</script>
