<template>
    <VerifyIdentityComponent 
        @submit="onSubmit" 
        :sub-title="$t('ENTER THE FOLLOWING DETAILS...')"
        :submit-button-text="$t('Verify My Identity')"
        :title="$t('Verify Identity')"
        :no-toggle="true"
        :with-button="true"
        ref="verifyIdentityComponent"
    >
        <template #further-actions>    
            <div class="mt-4 pointer">
                <router-link class="text-dark font-14" :to="loginRoute" >
                    {{ $t('Or Cancel and Login') }}
                </router-link>
            </div>
        </template>

    </VerifyIdentityComponent>    
</template>

<script>
import VerifyIdentityComponent from '@/features/leases/components/VerifyIdentityComponent';
import { GUEST_ENTER_OTP } from '@/features/payasguest/screens';
import { AUTH_LOGIN } from '@/features/auth/screens';

export default {
    name: "VerifyMyIdentity",
    components: {
        VerifyIdentityComponent
    },
    computed: {
        loginRoute() {
            return {
                name: AUTH_LOGIN,
            }
        }
        
    },
    methods: {
        async onSubmit(model, flags) {
            this.$refs.verifyIdentityComponent.submitting = true;

            if (flags.invalid) {
                return;
            }

            let response = await this.$fnApi.call("/api/v1/mobile/guest/verify-identity", "POST", {
                ...model
            });

            if (response.status) {
                this.onSuccess(model, response.data?.token);
            } else {
                this.$fnNotify({ message: this.$t("No lease found!"), type: 'error' });
                this.$refs.verifyIdentityComponent.$refs.formSchema.setErrors(response.error ?? "Unknown error.");
            }

            this.$refs.verifyIdentityComponent.submitting = false;
        },
        onSuccess(model, token) {
            this.$fnNotify(this.$t("Verification email sent. Please check and proceed."));

            this.$router.push({
                name: GUEST_ENTER_OTP,
                params: {
                    last_name: model.last_name,
                    date_of_birth: model.date_of_birth,
                    ssn: model.ssn,
                    token: token
                }
            });
        }
    }
};
</script>
