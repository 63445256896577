// GETTERS
import {filter, forEach, get, has} from "lodash";

export const getFilterGroups = "getFilterGroups";
export const getFilterInputs = "getFilterInputs";
export const getFilterValues = "getFilterValues";
export const getActiveFilters = "getActiveFilters";

export default {

    /**
     * Get a filter's groups
     *
     * @param state
     * @return {function(*): *}
     */
    [getFilterGroups]: (state) => (name) => {
        return state.filters[name].groups;
    },

    /**
     * Get a filter's inputs
     *
     * @param state
     * @return {function(*, *=): *}
     */
    [getFilterInputs]: (state) => (name, group = null) => {
        return filter(state.filters[name].inputs, (input) => {
            return input.group === group;
        });
    },

    /**
     * Get a filters values
     *
     * @param state
     * @return {function(*): *}
     */
    [getFilterValues]: (state) => (name) => {
        return state.filters[name].values;
    },

    /**
     * Get all the active filters for a filter name
     *
     * @param state
     * @return {function(*): *[]}
     */
    [getActiveFilters]: (state) => (name) => {
        let active = [];

        forEach(state.filters[name].inputs, (input) => {
            if (has(state.filters[name].values, input.name)) {
                //todo update to get the correct display value
                let rawValue = get(state.filters[name].values, input.name, undefined);
                if (rawValue !== undefined) {
                    active.push({
                        name: input.name,
                        input: input,
                        rawValue,
                        value: rawValue
                    });
                }
            }
        });

        return active;
    }

};
