//internal
import Auth from "../features/auth";
import Dashboard from "../features/dashboard";
import Leases from "../features/leases";
import PayAsGuest from "../features/payasguest";
import Shop from "../features/shop";
import Profile from "../features/profile"
import Welcome from "../features/welcome";
import Support from "../features/support";
import Errors from "../features/errors";

export default [

    Dashboard,
    Leases,
    Shop,
    Support,
    Profile,
    Welcome,
    PayAsGuest,
    // Demo,

    //note: this must be last
    Auth,
    Errors
];
