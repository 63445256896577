import {findIndex} from "lodash";

export const TOGGLE_CALLING = "TOGGLE_CALLING";
export const PULL_DOWNLOAD = "PULL_DOWNLOAD";
export const PUSH_DOWNLOAD = "PUSH_DOWNLOAD";
export const UPDATE_DOWNLOAD_PROGRESS = "UPDATE_DOWNLOAD_PROGRESS";
export const RESET_DOWNLOAD_PROGRESS = "RESET_DOWNLOAD_PROGRESS";

/**
 *
 * @type {{downloads: {}, calling: boolean, downloadsIndex: *[]}}
 */
export const defaultState = {
    /**
     * @param {boolean} calling If the service is busing calling the server
     */
    calling: false,

    /**
     * @param {{*}} downloads List of files being downloaded
     */
    downloads: [],

    /**
     * An index of the downloads
     */
    downloading: {
        key: null,
        progress: null
    },

};

export default {
    /**
     * Set the calling state
     *
     * @param {object} state
     * @param {object} user
     */
    [TOGGLE_CALLING](state, calling){
        state.calling = calling;
    },

    /**
     * Add a download to be processed
     *
     * @param state
     * @param download
     */
    [PUSH_DOWNLOAD](state, download){
        state.downloads.push(download);
    },

    /**
     * Remove a download no longer processing
     *
     * @param state
     * @param {String} key
     */
    [PULL_DOWNLOAD](state, key){
        let index = findIndex(state.downloads, (_key) => _key == key);
        if (index !== -1) {
            state.downloads.splice(index, 1);
        }
    },

    /**
     * Update the download progress for a download
     *
     * @param state
     * @param {String} key
     * @param {Number} progress
     */
    [UPDATE_DOWNLOAD_PROGRESS](state, progress){
        state.downloading = {
            ...progress
        };
    },

    /**
     * Reset the downloading state
     *
     * @param state
     */
    [RESET_DOWNLOAD_PROGRESS](state){
        state.downloading = {
            key: null,
            progress: null
        };
    }

};
