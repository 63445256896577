
export default {
    methods: {
        getAbilityMenu(items) {
            let items_ = [];
            items.forEach((item) => {
                let can = true;

                if (can && item.guard) {
                    can = this.$fnAuth.hasGuard(item.guard);
                }

                if (can && item.ability) {
                    can = this.$fnAuth.hasAbility(item.ability);
                }

                if( can ) {
                    let item_ = item;
                    if(item.children && item.children.length > 0) {
                        item_.children = this.getAbilityMenu(item.children);
                    }
                    items_.push(item_);
                }
            });
            return items_;
        }
    }
};
