export default {
    data(){
        return {
            form: null,
            flags: {
                dirty: false
            }
        };
    },
    methods: {
        onValidated(flags){
            this.flags = flags;
        },
        handleClose(){
            //check if the form has been modified
            if (this.flags && this.flags.dirty) {
                this.$bvModal.msgBoxConfirm("Looks like you have made some changes to the form. Are you sure you want to close the form?", {
                    title: "Confirm",
                    okVariant: "danger",
                    okTitle: "Yes close",
                    cancelVariant: "success",
                    cancelTitle: "Take me back",
                    centered: true
                })
                    .then(value => {
                        if (value) {
                            this.emitClose();
                        }
                    })
                    .catch((err) => {
                        // An error occurred
                        console.log(err);
                    });
            } else {
                this.emitClose();
            }
        },
        emitClose(){
            this.$emit("close");
        }
    }
};
