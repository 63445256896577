import {forEach} from "lodash";

/**
 * @type [{en: {}}] Add your message overrides here
 */
let _locales = [];

const locale = {

    /**
     * Add a set of locals files to the application
     *
     * @param {String} locale
     * @param {Object} messages
     */
    addLocale(locale, messages){
        _locales.push({
            locale,
            messages
        });
    },

    /**
     * Get the locales added to the application
     * @returns {{"en.String to translate": "Translated string"}}
     */
    getLocales(){
        let _messages = {};
        forEach(_locales, ({messages, locale}) => {
            forEach(messages, (message, key) => {
                let string = `${locale}.${key}`;
                _messages[string] = message;
            });
        });
        return _messages;
    }
};

export default locale;
