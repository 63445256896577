<template>
    <div :key="authKey" class="d-flex flex-column h-100 justify-content-around">
        <div>
            <h4 class="text-left text-primary">{{ $t('Welcome back!') }}</h4>
        </div>
        <div class="mt-auto">
            <div class="mt-3">
                <fn-form no-autoload no-buttons :uri="formUri" :uri-params="formUriParams" uri-method="POST"
                         v-model="form" @success="onSuccess" @fail="onFail">
                    <template v-slot="{loading, loaded, submitting, submit, flags}">
                        <fn-form-schema>
                            <fn-form-group :schema="inputs.email"></fn-form-group>
                            <fn-form-group :schema="inputs.password">
                                <template v-slot:end>
                                    <router-link :to="forgotRoute"><small>{{ $t("Forgot your password?") }}</small>
                                    </router-link>
                                </template>
                            </fn-form-group>
                        </fn-form-schema>
                        <div class="form-group">
                            <fn-ui-button variant="primary" class="btn-block btn-rounded"
                                          :disabled="!flags.valid || loading || submitting" :submitting="submitting"
                                          type="submit">{{ $t("Log In") }}
                            </fn-ui-button>
                            <fn-ui-button variant="primary" class="btn-block btn-rounded" @click="goToVerifyMyIdentity" type="button">{{ $t("Pay As Guest") }}
                            </fn-ui-button>
                        </div>
                    </template>
                </fn-form>
            </div>
            <div class="mt-4 pointer">
                <router-link class="text-dark font-14" :to="registerRoute" >
                    {{ $t('Or Create My Account') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {AUTH_CHECK, AUTH_VERIFY, AUTH_FORGOT, AUTH_REGISTER} from "./index";
import {GUEST_VERIFY_IDENTITY} from "@/features/payasguest/screens";
import {LEASE_RETRIEVE} from "@/features/leases/screens"
import {setRedirectTo} from "ui-auth/src/features/auth";
import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";
import {withScreen} from "@fndry-vue/fn-screen";
import {AUTH_GUARD_CLIENT} from "ui-auth/src/features/auth/constants";

export default {
    name: "AuthLogin",
    components: {},
    mixins: [
        withAutoGuard,
        withScreen
    ],
    props: {
        redirect: {
            type: [String,Object],
            default() {
                return this.$route.query.redirect;
            }
        }
    },
    screen() {
        return {
            title: this.$t('Login'),
            data: {
                noToggle: true,
                withButton: true,
                clearWave: true
            }
        }
    },
    data() {
        return {
            errors: [],
            inputs: {
                email: {
                    key: "email",
                    id: "email",
                    name: "email",
                    label: this.$t("Email Address"),
                    placeholder: this.$t("Email Address"),
                    type: "email",
                    rules: "required|email",
                    required: true,
                    autocomplete: "email"
                },
                password: {
                    key: "email",
                    id: "password",
                    name: "password",
                    label: this.$t("Password"),
                    placeholder: this.$t("Password"),
                    type: "password",
                    rules: "required",
                    required: true,
                    autocomplete: "current-password"
                }
            },
            form: {
                email: "",
                password: "",
                confirm: null
            }
        };
    },
    computed: {
        formUri() {
            return "/api/v1/mobile/login";
        },
        formUriParams() {
            return this.getGuardUriParams({
                ui: this.ui
            });
        },
        forgotRoute() {
            return this.getGuardRoute(AUTH_FORGOT);
        },
        user: function () {
            return this.$fnAuth.getUser();
        },
        authKey: function() {
            return this.ui + "_" + this.guard;
        },
        registerRoute() {
            return {
                name: AUTH_REGISTER,
            }
        }
    },
    created() {
        this.guard = AUTH_GUARD_CLIENT;
        this.ui = "customer";
    },
    mounted() {
        //check if the guest param is true, then force the current user to log out
        if (this.$route.params.guest) {
            sessionStorage.removeItem("token");
            this.$fnAuth.removeUser();
            this.$fnApi.$http.defaults.headers.common['Authorization'] = null;
        }

        if (this.redirect){
            setRedirectTo(this.redirect);
        }
    },
    methods: {
        goToVerifyMyIdentity() {
            this.$router.push({
                name: GUEST_VERIFY_IDENTITY
            });
        },
        onSuccess({data}) {

            this.$fnNotify(this.$t("Welcome Back!"));

            let user = {
                ...data.user,
                mobile: {
                    ...data.mobile
                }
            }

            //set the token to the localStorage
            sessionStorage.setItem("token", data.token);
            this.$fnAuth.setUser({user});
            this.$fnApi.$http.defaults.headers.common['Authorization'] = "Bearer " + data.token;

            if(user.mobile?.lease_details?.length > 0) {

                this.$router.push({
                    name: AUTH_CHECK
                });

            } else {

                this.$router.push({
                    name: LEASE_RETRIEVE
                });

            }
        },
        onFail(response) {
            if ((response.code === 403 && response.data.code && response.data.code === 1001)
                || (response.code === 449 && response.data.code && response.data.code === 1005)) {
                const route = this.getGuardRoute(AUTH_VERIFY, {
                    token: response.data.token
                });

                this.$router.push(route);
            }
        }
    }
};
</script>
