<template>
    <div class="payment-details-card">
        <div class="payment-status">
            <h3><i class="fas fa-check-circle"></i> Payment Successful</h3>
            <p class="amount">${{ this.amount }}</p>
        </div>
        <div class="payment-details">


      <div class="detail-item"><strong>Payment Date:</strong> {{ paymentDate }}</div>
      <div class="detail-item"><strong>Payment Time:</strong> {{ paymentTime }}</div>
      <div class="detail-item"><strong>Payment Method:</strong>Card</div>
      <div class="detail-item">{{ $t("We have emailed you your receipt.") }}</div>
        </div>
        <div class="form-group">
            <div class="account-prompt">
                <p>We notice you might not have an account yet, click Create Account to create your account so that you can easily manage your leases through our Customer Portal.</p>
            </div>
            <fn-ui-button variant="outline-primary" class="btn-block" @click="goToRegister">{{ $t("Create Account")
                }}</fn-ui-button>
            <fn-ui-button variant="primary" class="btn-block" @click="goToLogin">{{ $t("Login") }}</fn-ui-button>
        </div>
    </div>
</template>

<script>
import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";
import {withScreen} from "@fndry-vue/fn-screen";
import { AUTH_LOGIN, AUTH_REGISTER } from "../../../auth/screens";
import moment from "moment";


export default {
    name: "PaymentSuccess",
    mixins: [
        withAutoGuard,
        withScreen
    ],
    screen() {
        return {
            title: this.store_name ?? this.$t('Payment Successful!'),
            data: {
                noToggle: true,
                withButton: true,
                clearWave: true
            }
        }
    },
    data() {
        return {
        paymentDate: moment().format('MM/DD/YYYY'),
        paymentTime: moment().format('hh:mm A')
        }
    },
    props: {
        store_name : {
            type: String
        },
        amount : {
            type: Number,
            default: 0
        },
    },
    mounted() {
      if (!this.amount && !this.store_name) {
        this.$fnNotify({message: this.$t("Invalid token or session expired!"), type: 'error'});
        this.goToLogin();
      }
    },
    methods: {
        goToRegister() {
            this.$router.push({
                name: AUTH_REGISTER
            })
        },
        goToLogin() {
            this.$router.push({
                name: AUTH_LOGIN
            })
        }
    }
}
</script>

<style scoped>
.payment-details-card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  margin: auto;
}

.store-info {
  background-color: #fdd835;
  color: #1a237e;
  padding: 10px;
  text-align: center;
  border-radius: 8px 8px 0 0;
}

.store-info h2 {
  margin: 0;
  font-size: 1.5em;
}

.store-info p {
  margin: 5px 0;
}

.payment-status {
  text-align: center;
  margin: 20px 0;
}

.payment-status h3 {
  color: #4caf50;
  font-size: 1.2em;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-status h3 i {
  margin-right: 10px;
}

.payment-status .amount {
  color: #2196f3;
  font-size: 2em;
  margin: 10px 0 0 0;
}

.payment-details {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 100px;
}

.payment-details .detail-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 5px 0;
}

.payment-details p {
  margin: 5px 0;
}

.account-prompt {
  text-align: center;
  margin-top: 20px;
}

.account-prompt p {
  margin-bottom: 20px;
}

.account-prompt b-button {
  width: 100%;
}
</style>