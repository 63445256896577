import {DEFAULT_LAYOUT} from "../../layouts";
import screens, {
    PROFILE_SCREEN,
    PROFILE_MAIN_SCREEN,
    PAYMENT_METHODS_SCREEN,
    ADD_CARD_SCREEN,
    ADD_BANK_SCREEN,
    CHANGE_EMAIL_SCREEN,
    CHANGE_PASSWORD_SCREEN,
} from "@/features/profile/screens";
import {CURRENT_LEASES_SCREEN} from "@/features/leases/screens";

const Plugin = {
    install: function (Vue, {router, layouts}) {
        router.addRoutes([
            {
                path: "/",
                component: layouts[DEFAULT_LAYOUT],
                children: [
                    {
                        path: "profile",
                        component: screens[PROFILE_SCREEN],
                        children: [
                            {
                                path: "main",
                                name: PROFILE_MAIN_SCREEN,
                                component: screens[PROFILE_MAIN_SCREEN],
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                            {
                                path: "payment-methods",
                                name: PAYMENT_METHODS_SCREEN,
                                component: screens[PAYMENT_METHODS_SCREEN],
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                            {
                                path: "add-card",
                                name: ADD_CARD_SCREEN,
                                component: screens[ADD_CARD_SCREEN],
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                            {
                                path: "add-bank",
                                name: ADD_BANK_SCREEN,
                                component: screens[ADD_BANK_SCREEN],
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                            {
                                path: "change-email",
                                name: CHANGE_EMAIL_SCREEN,
                                component: screens[CHANGE_EMAIL_SCREEN],
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                            {
                                path: "change-password",
                                name: CHANGE_PASSWORD_SCREEN,
                                component: screens[CHANGE_PASSWORD_SCREEN],
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            }
                        ]
                    },
                ]
            }
        ])
    },
    init: function(App){
        App.$fnMenus.addItems("main", [
            {
                to: {name: PROFILE_MAIN_SCREEN},
                text: App.$t("Profile")
            }
        ]);
    }
};
export default Plugin;
