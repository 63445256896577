import {DEFAULT_LAYOUT} from "../../layouts";
import screens, {
    DASHBOARD_SCREEN,
    DASHBOARD_MAIN_TAB,
    DASHBOARD_PAYMENT_HISTORY_TAB,
    DASHBOARD_PAYMENT_DETAILS_SCREEN,
    DASHBOARD_PAY_EARLY_SCREEN
} from "./screens";

export const DASHBOARD = "dashboard";
export const MAX_PAYABLE_AMOUNT = 999999.99;

const Plugin = {
    install: function(Vue, {router, layouts}){
        router.addRoutes([
            {
                path: "/",
                component: layouts[DEFAULT_LAYOUT],
                redirect: {name:DASHBOARD},
                children: [
                    {
                        path: "dashboard",
                        component: screens[DASHBOARD_SCREEN],
                        children : [
                            {
                                path: '',
                                name: DASHBOARD,
                                component: screens[DASHBOARD_MAIN_TAB],
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                            {
                                path: 'payment-history',
                                name: DASHBOARD_PAYMENT_HISTORY_TAB,
                                component: screens[DASHBOARD_PAYMENT_HISTORY_TAB],
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            }
                        ]
                    },
                    {
                        path : 'payment-details',
                        name: DASHBOARD_PAYMENT_DETAILS_SCREEN,
                        component: screens[DASHBOARD_PAYMENT_DETAILS_SCREEN],
                        props: true,
                        meta: {
                            middleware: [
                                "auth"
                            ]
                        },
                    },
                    {
                        path : 'payment-early',
                        name: DASHBOARD_PAY_EARLY_SCREEN,
                        component: screens[DASHBOARD_PAY_EARLY_SCREEN],
                        props: true,
                        meta: {
                            middleware: [
                                "auth"
                            ]
                        },
                    }
                ]
            }
        ]);
    },
    init: function(App){

    }
};

export default Plugin;
