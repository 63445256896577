import TextInput from "./inputs/TextInput";
import TextAreaInput from "./inputs/TextAreaInput";
import TelInput from "./inputs/TelInput";
import UrlInput from "./inputs/UrlInput";
import TimeInput from "./inputs/TimeInput";
import RatingInput from "./inputs/RatingInput";
import SelectInput from "./inputs/SelectInput";
import PasswordInput from "./inputs/PasswordInput";
import NumberInput from "./inputs/NumberInput";
import MoneyInput from "./inputs/MoneyInput";
import HiddenInput from "./inputs/HiddenInput";
import EmailInput from "./inputs/EmailInput";
import DateInput from "./inputs/DateInput";
import DateTimeInput from "./inputs/DateTimeInput";
import CheckboxInput from "./inputs/CheckboxInput";
import CheckboxesInput from "./inputs/CheckboxesInput";
import FileInput from "./inputs/FileInput";
import CountryInput from "./inputs/CountryInput";
import RadioInput from "./inputs/RadioInput";
import TagInput from "./inputs/TagInput";
import TimecodeInput from "./inputs/TimecodeInput";
import CollectionInput from "./inputs/CollectionInput";
import MultiCollectionInput from "./inputs/MultiCollectionInput";
import MultiSelectInput from "./inputs/MultiSelectInput";
import TreeSelectInput from "./inputs/TreeSelectInput";
import PercentageInput from "./inputs/PercentageInput";
import DigitInput from "./inputs/DigitInput";
import RowContainer from "./containers/RowContainer";
import ColumnContainer from "./containers/ColumnContainer";
import SectionContainer from "./containers/SectionContainer";
import Tab from "./containers/Tab";
import DivContainer from "./containers/DivContainer";

export const defaultInputs = {
    "text": TextInput,
    "textarea": TextAreaInput,
    "tel": TelInput,
    "url": UrlInput,
    "time": TimeInput,
    "rating": RatingInput,
    "select": SelectInput,
    "password": PasswordInput,
    "number": NumberInput,
    "money": MoneyInput,
    "hidden": HiddenInput,
    "email": EmailInput,
    "date": DateInput,
    "datetime": DateTimeInput,
    "checkbox": CheckboxInput,
    "checkboxes": CheckboxesInput,
    "file": FileInput,
    "country": CountryInput,
    "radio": RadioInput,
    "tag": TagInput,
    "timecode" : TimecodeInput,
    "multiselect": MultiSelectInput,
    "treeselect": TreeSelectInput,
    "percentage" : PercentageInput,
    "digit" : DigitInput,
};

export const defaultContainers = {
    "row": RowContainer,
    "column": ColumnContainer,
    "section": SectionContainer,
    "tab": Tab,
    "div": DivContainer
};

let types = {};

const registerType = (key, component) => {
    types[key] = component;
};

const registerTypes = (types) => {
    Object.keys(types).forEach((key) => {
        registerType(key, types[key]);
    });
};

const getTypes = () => {
    return types;
};

const getType = (type) => {
    return types[type];
};

const registerDefaultTypes = () => {
    Object.keys(defaultInputs).forEach((key) => {
        registerType(key, defaultInputs[key]);
    });
    Object.keys(defaultContainers).forEach((key) => {
        registerType(key, defaultContainers[key]);
    });
};

export {
    registerDefaultTypes,
    registerTypes,
    registerType,
    getTypes,
    getType
};

export {
    UrlInput as FnTypeUrlInput,
    TimeInput as FnTypeTimeInput,
    TextInput as FnTypeTextInput,
    TextAreaInput as FnTypeTextAreaInput,
    TelInput as FnTypeTelInput,
    SelectInput as FnTypeSelectInput,
    RatingInput as FnTypeRatingInput,
    PasswordInput as FnTypePasswordInput,
    NumberInput as FnTypeNumberInput,
    MoneyInput as FnTypeMoneyInput,
    HiddenInput as FnTypeHiddenInput,
    EmailInput as FnTypeEmailInput,
    DateInput as FnTypeDateInput,
    DateTimeInput as FnTypeDateTimeInput,
    CheckboxInput as FnTypeCheckboxInput,
    CheckboxesInput as FnTypeCheckboxesInput,
    FileInput as FnTypeFileInput,
    CountryInput as FnTypeCountryInput,
    RadioInput as FnTypeRadioInput,
    TagInput as FnTypeTagInput,
    TimecodeInput as FnTypeTimecodeInput,
    CollectionInput as FnTypeCollectionInput,
    MultiCollectionInput as FnTypeMultiCollectionInput,
    MultiSelectInput as FnTypeMultiSelectInput,
    TreeSelectInput as FnTypeTreeSelectInput,
    PercentageInput as FnTypePercentageInput,
    DigitInput as FnTypeDigitInput,

    RowContainer as FnTypeRowContainer,
    ColumnContainer as FnTypeColumnContainer,
    SectionContainer as FnTypeSectionContainer,
    DivContainer as FnTypeDivContainer

};

/**
 * FnTypesPlugin
 * (c) 2020
 */
const FnTypesPlugin = {};

/**
 * Types Plugin install
 *
 * @param {Object} Vue The vue instance
 * @param {function} extend The extend method provided by vee-validate
 */
FnTypesPlugin.install = function (Vue) {


};

export default FnTypesPlugin;
