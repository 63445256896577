<template>
    <div class="bg-container welcome" style="background-size: contain">
        <fn-ui-layout>
            <div class="fn-layout__screen w-100 position-relative">
                <router-view></router-view>
            </div>
        </fn-ui-layout>
    </div>
</template>

<script>
import {FnUiLayout} from "@fndry-vue/fn-ui";

export default {
    name: "WelcomeLayout",
    components: {FnUiLayout}
}
</script>
