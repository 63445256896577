export default {
    message: field => 'The ' + field + ' value is not in the allowed values.',
    validate: (value, args) => {
        for (let i in args) {
            //todo figure out a better way than eval
            if (value == eval(args[i])) {
                return true;
            }
        }
        return false;
    }
}
