import appStore from "../store";
import Vuex from "vuex";


const makeStore = (Vue) => {
    Vue.use(Vuex);

    const store = new Vuex.Store({
        state: {},
        mutations: {}
    });

    // App Store
    appStore.install(store);

    return store;
};


export default makeStore;
