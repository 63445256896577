<template>
    <fn-form no-autoload no-buttons ref="fnForm" :uri="formUri" uri-method="PUT" v-model="form" @success="onSuccess" @fail="onFail" @cancel="handleCancel">
        <template v-slot="{loading, loaded, submit, flags, cancel}">
            <fn-form-schema>
                <fn-form-group :schema="inputs.email"></fn-form-group>
                <fn-form-group :schema="inputs.password"></fn-form-group>
            </fn-form-schema>
            <fn-ui-button variant="outline-primary" class="btn-block"
                          :disabled="!flags.valid || loading || submitting" :submitting="submitting"
                          type="submit" @click.prevent="handleSubmit">{{ $t("Change Email") }}
            </fn-ui-button>
            <fn-ui-button variant="outline-primary" class="btn-block" @click="cancel">{{ $t("Cancel") }}</fn-ui-button>
        </template>
    </fn-form>
</template>

<script>

import {PROFILE_MAIN_SCREEN} from "@/features/profile/screens/index";
import {fnResponseToNotification} from "@fndry-vue/fn-notify";

export default {
    name: "ChangeEmailScreen",
    data() {
        return {
            formUri: '/api/v1/mobile/users',
            inputs: {
                email: {
                    key: "email",
                    id: "email",
                    name: "email",
                    label: this.$t("Change Email"),
                    placeholder: this.$t("Change Email"),
                    type: "email",
                    rules: "required|email",
                    required: true,
                    autocomplete: "email"
                },
                password: {
                    key: "password",
                    id: "password",
                    name: "password",
                    label: this.$t("Current Password"),
                    placeholder: this.$t("Current Password"),
                    type: "password",
                    rules: "required",
                    required: true,
                    autocomplete: "current-password"
                },
            },
            form: {
                email: "",
                password: "",
            },
            submitting : false
        }
    },
    methods: {
        goToProfile() {
            this.$router.push({
                name: PROFILE_MAIN_SCREEN
            })
        },
        onSuccess(response) {
            if(response.status && response.data.user) {
                let user = response.data.user;
                this.$fnAuth.setUser({user});
                this.goToProfile();
            } else {
                this.$fnNotify(fnResponseToNotification(response))
            }
        },
        handleCancel() {
            this.goToProfile();
        },
        onFail(response) {
            this.$fnNotify(fnResponseToNotification(response))
        },
        handleSubmit() {
            this.submitting = true
            this.$fnApi
                .call(this.formUri, 'PUT', this.form)
                .then((response) => {
                    if(response.status) {
                        this.onSuccess(response)
                    } else {
                        this.onFail(response)
                    }
                })
                .finally(() => {
                    this.submitting = false;
                })
        }
    }
}
</script>
