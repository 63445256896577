export default {
    data() {
        return {
            windowWidth: window.innerWidth,
            mobileWidth: 768,
        };
    },
    computed: {
        isMobileView() {
            return (this.windowWidth <= this.mobileWidth);
        },
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    },
    methods: {
        toggleLayoutColumnCompact(name, compact) {
            if (this.isMobileView) {
                this.$fnLayout.setAllColumnCompact(false);
            } else {
                this.$fnLayout.setAllColumnCompact(true, name);

                this.$fnLayout.toggleColumnCompact(name, compact);
            }
        },
        toggleLayoutColumnShow(name) {
            this.$fnLayout.toggleColumnShow(name);
        },
        getLayoutColumn(name) {
            return this.$fnLayout.getColumn(name);
        },
    },
    watch: {
        isMobileView: {
            handler(newValue) {
                if (newValue) {
                    this.$fnLayout.setAllColumnCompact(false);
                }
            }
        }
    }
};
