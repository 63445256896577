// MUTATIONS
export const SET_CONTEXT = "SET_CONTEXT";
export const RESET_CONTEXT = "RESET_CONTEXT";

/**
 * The default state of the context state
 *
 * @type {{subject: null, context: null}}
 */
export const defaultState = {

    /**
     * A unique key which will be changed everytime the context is changed
     */
    key: null,

    /**
     * The current context
     *
     * The context name can be made up of parts to further refine the context
     *
     * E.G. mail.sent would result on a context of ['mail', 'sent']
     *
     */
    context: null,

    /**
     * A string title for the active context
     */
    title: null,

    /**
     * The object associated with the current context
     *
     * This can be empty
     */
    subject: null,

};

/**
 * Default reaction object
 *
 * @type {{context: (array|string), leave(Context), name: string, react(string, (null|Object)), enter(string, (null|Object))}}
 */
// eslint-disable-next-line no-unused-vars
const Reaction = {
    /**
     * The name of this reaction
     *
     * @type {string}
     */
    name: "",

    /**
     * The context name we need to react to
     *
     * @type {array|string}
     */
    context: "",

    /**
     * Optional to allow the reaction to determine if it should react
     *
     * @param {string} context The context
     * @param {null|Object} subject The context subject if set
     */
    react(context, subject){},

    /**
     * Callback for when the context matches and this reaction will be run
     *
     * @param {string} context The context
     * @param {null|Object} subject The context subject if set
     */
    enter(context, subject){},

    /**
     * Optional callback for when the context this reaction is for, is being left
     *
     * @param {string} context The context
     * @param {null|Object} subject The context subject if set
     */
    leave(context, subject){},
};

export default {
    [SET_CONTEXT](state, {key, title, context, subject}){
        state.name = context;
        state.title = title;
        state.subject = subject;
        state.key = key;

    },
    [RESET_CONTEXT](state){
        Object.keys(defaultState).forEach((key) => {
            state[key] = defaultState[key];
        });
    }
};
