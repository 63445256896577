// GETTERS
//import {sortItems} from "../helpers";

export const getMenu = "getMenu";
export const getMenuItems = "getMenuItems";

export default {
    [getMenu]: (state) => (name) =>{
        if (state.menus[name] && state.menus[name].items.length > 0) {
            return state.menus[name];
        } else {
            return undefined;
        }
    },
    [getMenuItems]: (state) => (name) =>{
        if (state.menus[name] && state.menus[name].items.length > 0) {
            return state.menus[name].items;
        } else {
            return [];
        }
    }
};
