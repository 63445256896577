import {env} from "@fndry-vue/fn-app";

export default {
    app: {
        name: "Okinus Customer Portal",
        footer: "Okinus Credit Solutions",
        titles: {
            okinus: "Okinus Customer Portal",
            breeze: "Breeze Customer Portal",
        }, 
        phone: {
            okinus: "800-472-1334",
            breeze: "855-330-3959",
        }, 
        email: {
            okinus: "information@okinus.com",
            breeze: "info@breezelease.com",
        }, 
        footers: {
            okinus: "Okinus Credit Solutions",
            breeze: "Breeze Leasing",
        },            
        env: env("NODE_ENV"),
        ui: "customer",
        locale: 'en',
    },
    api: {
        url: env("VUE_APP_API_URL"),
        google_api_key : 'AIzaSyB85BVfLhMyQoBOApJj-kBAdph32er27Mg'
    }
};
