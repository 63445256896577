import moment from "moment";
import { AUTH_LOGIN } from "@/features/auth/screens";
export default {
    data() {
        return {
            loadingLeases: false,
            allLeases: [],
            currentLeasesExtraData : [],
        }
    },
    computed: {
        currentLeases() {
            if(this.currentLeasesExtraData.length > 0) {
                let currentLoanNumbers = this.currentLeasesExtraData.map((item) => parseInt(item.LoanNumber));
                return this.allLeases.filter((l) => {
                    if(currentLoanNumbers.includes(l.loan_number)){
                        l.extra = this.currentLeasesExtraData.filter((lease) => parseInt(lease.LoanNumber) === l.loan_number)[0];
                        return l;
                    }
                });
            } else return [];
        },
    },
    methods: {
        getLeases(token, applicantId) {
            this.loadingLeases = true;
            this.$fnApi.call('/api/v1/mobile/guest/leases', "POST",
                {
                  token: token,
                  applicant_id: applicantId,
                })
                .then((response) => {
                    if(response.status) {
                        this.allLeases = response.data.all;
                        this.currentLeasesExtraData = response.data.current;
                    } else {
                        this.$fnNotify({message: this.$t("Invalid token or session expired!"), type: 'error'});
                        this.$router.push({
                            name: AUTH_LOGIN
                        });
                    }
                }).finally(()=>{
                this.loadingLeases = false
            })
        },
        getCurrentLeaseFromLoan(loanNumber) {
            return this.currentLeases.find((item) => {
                return item.loan_number === parseInt(loanNumber)
            })
        },
        getLeaseFromLoan(loanNumber) {
            return this.allLeases.find((item) => {
                return item.loan_number === parseInt(loanNumber)
            })
        },
        getLeaseById(id) {
            return this.allLeases.find((item) => {
                return item.lease_id === id
            })
        },
        leaseHasExpired(date)  {
            let createdAt = moment(date,'YYYY-MM-DD HH:mm:ss');
            let now = moment();

            return now.diff(createdAt, 'days') > 181
        },
    }
}
