const Finder = {
    install: function(Vue, options){

    }
};

export const StoreStatus = {
    Active: "Active",
    Pending: "Pending",
    Suspended: "Suspended",
};

export default Finder;
