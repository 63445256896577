<template>
  <div>
    <div class="row mb-3">
      <div class="col font-18 text-primary font-weight-bold">{{ $t('Items') }}</div>
    </div>
    <b-table-lite v-if="items.length > 0" class="font-12" small borderless :fields="fields" :items="items">
        <template #cell(total) = {item}>
            <span>{{ getTotal(item) }}</span>
        </template>
        <template #cell(qty) = {item}>
            <span>{{ item.quantity }}</span>
        </template>
        <template #custom-foot>
        <tr>
          <td class="text-right text-light font-10" colspan="3">Total</td>
          <td colspan="1">{{ total }}</td>
        </tr>
        </template>
    </b-table-lite>
    <p class="small text-center" v-else>{{ $t('No line items were captured at the creation of your lease.') }}</p>
  </div>
</template>

<script>

export default {
    name: "LeaseItemsTabScreen",
    props: {
        lease : Object,
    },
    data() {
        return {
          fields: ['description', 'qty', 'unit_price', 'total'],
        }
    },
    computed: {
        items() {
          if(this.lease?.computation?.items) {
            return this.getItemsArray(this.lease.computation.items);
          }
          return this.getItemsArray(this.lease?.lease_params?.items) ?? [];
        },
        total() {
          return this.items.reduce((total, item) => {
            return this.getTotal(item) + total
          }, 0)
        }
    },
    methods: {
        getTotal(row) {
            return parseInt(row.quantity) * parseFloat(row.unit_price)
        },
        getItemsArray(items) {
        if (Array.isArray(items)) {
          return items;
        } else if (typeof items === 'object' && items !== null) {
          return Object.values(items);
        } else {
          return [];
        }
      }
    }
}
</script>
