export default {
    props: {
        /**
         * The minimum length of the input value
         */
        minLength: {
            type: Number,
            required: false
        },
        /**
         * The maximum length of the input value
         */
        maxLength: {
            type: Number,
            required: false
        }
    }
}
