<template>
    <fn-form no-autoload no-buttons ref="fnForm" :uri="formUri" v-model="form" @success="onSuccess" @fail="onFail" @cancel="handleCancel">
        <template v-slot="{loading, loaded, submitting, submit, flags, cancel}">
            <fn-form-schema>
                <fn-form-group :schema="inputs.current_password"></fn-form-group>
                <fn-form-group :schema="inputs.password"></fn-form-group>
                <fn-form-group :schema="inputs.password_confirmation"></fn-form-group>
                <fn-ui-button variant="outline-primary" class="btn-block"
                              :disabled="!flags.valid || loading || submitting" :submitting="submitting"
                              type="submit">{{ $t("Change Password") }}
                </fn-ui-button>
                <fn-ui-button variant="outline-primary" class="btn-block" @click="cancel">{{ $t("Cancel") }}</fn-ui-button>
            </fn-form-schema>
        </template>
    </fn-form>
</template>

<script>

import {PROFILE_MAIN_SCREEN} from "@/features/profile/screens/index";
import {fnResponseToNotification} from "@fndry-vue/fn-notify";

export default {
    name: "ChangePasswordScreen",
    data() {
        return {
            formUri: '/api/v1/mobile/password',
            inputs: {
                current_password: {
                    key: "current_password",
                    id: "current_password",
                    name: "current_password",
                    label: this.$t("Current Password"),
                    placeholder: this.$t("Current Password"),
                    type: "password",
                    rules: "required",
                    required: true,
                    autocomplete: "current-password"
                },
                password: {
                    key: "password",
                    id: "password",
                    name: "password",
                    label: this.$t("New Password"),
                    placeholder: this.$t("New Password"),
                    type: "password",
                    rules: "required",
                    required: true,
                },
                password_confirmation: {
                    key: "password_confirmation",
                    id: "password_confirmation",
                    name: "password_confirmation",
                    label: this.$t("Confirm Password"),
                    placeholder: this.$t("Confirm Password"),
                    type: "password",
                    rules: "required|confirmed:password",
                    required: true,
                },
            },
            form: {
                current_password: "",
                password: "",
                password_confirmation: null,
            }
        }
    },
    methods: {
        goToProfile() {
            this.$router.push({
                name: PROFILE_MAIN_SCREEN
            })
        },
        onSuccess(response) {
            if(response.status) {
                this.goToProfile();
            } else {
                this.onFail(response)
            }
        },
        handleCancel() {
            this.goToProfile();
        },
        onFail(response) {
            this.$fnNotify({
                message: response.error?.password?.[0] ?? response.error,
                type: 'error'
            })
        }
    }
}
</script>
