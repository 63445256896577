export const getPanel = "getPanel";
export const getPanelProps = "getPanelProps";

export default {
    [getPanel]: (state) => (id) => {
        return state.panels[id];
    },
    [getPanelProps]: (state) => (id) => {
        return state.panels[id].props;
    }
};
