<template>
    <div class="d-flex flex-column h-100 justify-content-between">
        <div>
            <h4 class="text-left text-primary">{{ $t('Verify Identity') }}</h4>
        </div>
        <div>
            <p class="lead text-center text-gray-600">{{ $t("An code was sent to your email. Please check it and enter it below.") }}</p>
            <div class="mt-3">
                <form @submit.prevent="onSubmit">
                    <fn-form-schema ref="formSchema" :value="model" @input="onUpdate" no-inject @validated="onValidated">
                        <fn-form-group :schema="schema.code"></fn-form-group>
                    </fn-form-schema>

                    <div class="form-group mt-4">
                        <fn-ui-button variant="primary" class="btn-block btn-rounded" :disabled="flags.invalid || submitting" :submitting="submitting" type="submit">{{$t("Continue")}}</fn-ui-button>
                    </div>
                    <div class="form-group mt-4">
                        <fn-ui-button variant="outline-primary" class="btn-block btn-rounded" @click="redirectToHome">{{$t("Cancel")}}</fn-ui-button>
                    </div>

                </form>

            </div>
            <div class="mt-4 pointer">
                <router-link class="text-dark font-14" :to="dashboardRoute">
                    {{$t("Skip for now")}}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {AUTH_CHECK} from "@/features/auth/screens/index";
    import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";
    import {withScreen} from "@fndry-vue/fn-screen";
    import {FnUiButton} from "@fndry-vue/fn-ui";

    import {FnFormGroup, FnFormSchema} from "@fndry-vue/fn-form";
    import {CURRENT_LEASES_SCREEN} from "@/features/leases/screens"

    export default {
        name: "LeaseVerify",
        mixins: [
            withAutoGuard,
            withScreen
        ],
        components: {
            FnFormSchema,
            FnFormGroup,
            FnUiButton
        },
        screen() {
            return {
                title: this.$t('Retrieve my Account'),
                data: {
                    noToggle: true,
                    withButton: true,
                    clearWave: true
                }
            }
        },
        data() {
            return {
                loading: false,
                submitting: false,
                flags: {
                    invalid: false,
                    dirty: false
                },
                schema: {
                    code: {
                        id: "id_code",
                        name: "code",
                        label: this.$t("Code"),
                        type: "text",
                        rules: "required",
                        required: true
                    }
                },
                model: {
                    code: "",
                    last_name: this.$route.params?.last_name ?? "",
                    date_of_birth: this.$route.params?.date_of_birth ?? "",
                    ssn: this.$route.params?.ssn ?? "",
                }
            };
        },
        computed: {
            formUri() {
                return "";
            },
            dashboardRoute() {
                return {
                    name: AUTH_CHECK,
                };
            },
            dob() {
                return this.$route.params?.date_of_birth ?? "";
            },
            ssn() {
                return this.$route.params?.ssn ?? "";
            }
        },
        methods: {
            onUpdate(value){
                this.model.code = value.code ?? "";
                this.model.date_of_birth = this.$route.params?.date_of_birth ?? "",
                this.model.ssn = this.$route.params?.ssn ?? ""
            },
            onValidated(flags) {
                this.flags = {
                    ...flags
                };
            },
            async onSubmit(){
                this.submitting = true;

                if (this.flags.invalid === true) {
                    return;
                }

                let token = sessionStorage.getItem("token");
                if (token) {

                    let response = await this.$fnApi.call("/api/v1/mobile/leases/verify", "POST", {
                        ...this.model
                    });

                    if (response.status) {
                        this.onSuccess();
                    } else {
                        if (response.code == 404) {
                            this.$refs.formSchema.setErrors(response.error ?? this.$t("Unknown error."));
                            this.$fnNotify({message: this.$t("Record not found or previous applications not found, please confirm the details you're using are correct!"), type: 'error'});
                        } else if (response.data["maxed"] || response.code == 406) {
                            this.$fnNotify({message: this.$t("Pin verification attempts exceeded"), type: 'error'});
                        } else {
                            this.$fnNotify({message: this.$t("Unknown error occurred. Please try again."), type: 'error'});
                        }
                    }
                }

                this.submitting = false;

            },
            redirectToHome() {
                this.$router.push({
                    name: CURRENT_LEASES_SCREEN
                });
            },
            onSuccess(){
                this.$fnNotify(this.$t("Previous applications retrieved!"));

                this.redirectToHome();

            }
        }
    };
</script>
