
export default {
    data(){
        return {
            form: null,
            flags: {
                dirty: false,
                validated: null,
                valid: null
            }
        };
    },
    computed: {
        valid: function(){
            return this.flags.valid;
        }
    },
    methods: {
        handleValidated(flags){
            this.flags = flags;
        },
        handleCancel(bvModalEvent){
            //check if the form has been modified
            if (this.flags && this.flags.dirty) {
                bvModalEvent.preventDefault();
                this.$fnModals.confirm("Looks like you have made some changes to the form. Are you sure you want to close the form?", {
                    title: "Confirm",
                    okVariant: "danger",
                    okTitle: "Yes close",
                    cancelVariant: "success",
                    cancelTitle: "Take me back"
                })
                    .then(value => {
                        if (value) {
                            this.$emit("reject");
                        }
                    })
                    .catch((err) => {
                        // An error occurred
                        console.log(err);
                    });
            } else {
                this.$emit("reject");
            }
        },
        handleOk(bvModalEvent){
            if (this.valid) {
                this.$emit("resolve", this.form);
            } else {
                bvModalEvent.preventDefault();
            }
        },
    }
};
