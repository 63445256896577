// MUTATIONS
export const SET_SCREEN = "SET_SCREEN";
export const RESET_SCREEN = "RESET_SCREEN";

/**
 * The default state of the context state
 *
 */
export const defaultState = {
    title: "",
    class: {},
    loading: false,
    breadcrumbs: [],
    noBreadcrumbs: false,
    noContext: false,
    noFooter: false,
    noHeader: false,
    data: {}
};

export default {
    [SET_SCREEN](state, payload){
        Object.keys(defaultState).forEach((key) => {
            state[key] = payload[key] ?? defaultState[key];
        });
    },
    [RESET_SCREEN](state, payload){
        Object.keys(defaultState).forEach((key) => {
            state[key] = defaultState[key];
        });
    },
};
