/**
 * FnContext
 *
 * (c) 2020
 */

import Context from "./services/context";

const FnContextPlugin = {};

FnContextPlugin.install = function(Vue, {store}) {
    const fnContext = new Context(store, Vue);
    Vue.prototype.$fnContext = fnContext;
};

export default FnContextPlugin;

export {
    Context
};
