<template>
    <loader class="vh-100 bg-white" :loading="loading" height="100vh">
        <b-carousel class="welcome-carousel h-auto" indicators v-if="!loading">
            <b-carousel-slide v-for="(slide,index) in slides" :key="index">
                <template #img>
                    <div class="welcome-carousel__item">
                        <img class="welcome-carousel__img" :src="slide.english_image">
                    </div>
                </template>
                <template>
<!--                    <div v-if="slide.has_application_button" class="btn btn-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center" @click="handleApply">-->
<!--                        <span class="font-14">{{ $t('Apply Now') }}</span>-->
<!--                        <icon class="ml-auto" icon="long-arrow-right"></icon>-->
<!--                    </div>-->
                    <div v-if="slide.has_login_button" class="btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-center align-items-center" @click="handleLogin">
                        <span class="font-14">{{ $t('Login') }}</span>
                    </div>
                    <div v-if="slide.has_login_button" class="btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-center align-items-center" @click="handlePayAsGuest">
                        <span class="font-14">{{ $t('Pay As Guest') }}</span>
                    </div>
                    <div v-if="slide.has_help_button" class="btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-center align-items-center" @click="handleHelp">
                        <span class="font-14">{{ $t('Help') }}</span>
                    </div>
                    <div class="btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-center align-items-center" @click="handleRegister">
                        <span class="font-14">{{ $t('Register') }}</span>
                    </div>
                </template>
            </b-carousel-slide>
        </b-carousel>
    </loader>
</template>

<script>
import {withScreen} from "@fndry-vue/fn-screen";
import {AUTH_LOGIN, AUTH_REGISTER} from "../../auth/screens";
import {GUEST_VERIFY_IDENTITY} from "@/features/payasguest/screens";

export default {
    name: "WelcomeScreen",
    mixins: [withScreen],
    screen() {
        return {
            title: this.$t(''),
            data: {
                noHeader:true
            }
        }
    },
    data() {
        return {
            loading: false,
            slides: []
        }
    },
    mounted() {
        this.getSlides();
    },
    methods: {
        getSlides() {
            this.loading = true;
            this.$fnApi.call(`/api/v1/mobile/slides`, "GET").then((response) => {
                let {slides} = response.data
                if(slides.length === 0) { //if no slides found, proceed skip welcome screen and proceed to login
                    this.handleLogin();
                }
                this.slides = slides;
            }).catch(() => {
                this.handleLogin();
            }).finally(() => {
                this.loading = false;
            })
        },
        handleApply() {
            //todo implement apply button
        },
        handleLogin() {
            this.$router.push({
                name: AUTH_LOGIN
            })
        },
        handlePayAsGuest() {
            this.$router.push({
                name: GUEST_VERIFY_IDENTITY
            })
        },
        handleHelp() {
            //todo implement faq route
        },
        handleRegister() {
            this.$router.push({
                name:AUTH_REGISTER
            })
        }
    }
}
</script>
