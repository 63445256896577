import {forEach, merge} from "lodash";

/**
 * A renderless component for doing calls to a URI and pass the results with other properties and functions to the
 * view.
 *
 * This component can wrap any other components where you are wanting to fetch data from the server for rendering
 * such as browsing a list of data or reading a specific record from the server.
 *
 * @displayName FnRequest
 */
export default {
    name: "ApiRequest",
    render() {
        /**
         @slot The default slot
            @binding {boolean} loading The current loading state
            @binding {boolean} loaded If we have loaded a response from the server
            @binding {Object} response The response returned from the server
            @binding {Object} params The current active params
            @binding {function} fetch The fetch method to call if wanting to fetch the records (By default fetch is called on mounted)
            @binding {function} refresh To trigger a refresh of the response based on the active params
            @binding {function} reset Reset the params and fetch the response again
            @binding {function} toggleSortBy Toggle the sort (use the params to determine which sortBy is active and is sortDesc is true|false)
            @binding {Object} selected The current selected rows
            @binding {boolean} allSelected If all the rows are selected
            @binding {function} toggleSelect Toggle selection of a row
            @binding {function} toggleSelectAll Toggle selecting all row's
            @binding {function} clearSelected Clears the currently selected entries
            @binding {function} goToFirstPage Go to first page
            @binding {function} goToLastPage Go to last page
            @binding {function} goToNextPage Go to next page
            @binding {function} goToPrevPage Go to prev page
            @binding {function} goToPage Go to a specific page
            @binding {function} columnsToFields Converts the given columns to fields for rendering in a table using b-table

         */
        return this.$scopedSlots.default({
            loading: this.loading,
            loaded: this.loaded,
            response: this.response,
            meta: this.meta,
            filters: this.filters,
            params: this.params,
            fetch: this.fetch,
            refresh: this.refresh,
            reset: this.reset,
            toggleSortBy: this.toggleSortBy,
            selected: this.selected,
            toggleSelect: this.toggleSelect,
            toggleSelectAll: this.toggleSelectAll,
            clearSelected: this.clearSelected,
            goToFirstPage: this.goToFirstPage,
            goToLastPage: this.goToLastPage,
            goToNextPage: this.goToNextPage,
            goToPrevPage: this.goToPrevPage,
            goToPage: this.goToPage,
            columnsToFields: this.columnsToFields
        });
    },
    props: {
        /**
         * The URI to fetch results from
         */
        uri: {
            type: String,
            required: true
        },
        /**
         * The URI Parameters to send to the server
         */
        uriParams: {
            type: Object,
            default(){
                return {};
            }
        },
        /**
         * The HTTP method to use when calling the URI
         */
        uriMethod: {
            type: String,
            default: "GET"
        },
        /**
         * The default parameters to use in calling the server
         */
        defaultParams: {
            type: Object,
            default(){
                return {
                    limit: 25,
                    page: 1,
                    sortBy: null,
                    sortDesc: null
                };
            }
        }
    },
    data: function(){
        return {
            /**
             * The loading state of the component
             *
             * @type {boolean}
             */
            loading: false,

            /**
             * If the component has loaded the response from the server
             *
             * @type {boolean}
             */
            loaded: false,

            /**
             * The active parameters of the component
             *
             * @type {Object}
             */
            params: Object.assign({}, this.defaultParams, this.uriParams),

            /**
             * The response received from the URI
             *
             * @type {Object|undefined}
             */
            response: undefined,

            /**
             * The list of selected items
             *
             * @type {Object}
             */
            selected: {},

            /**
             * The
             *
             * @type {boolean}
             */
            allSelected: false,

            /**
             *
             */
            filters: null
        };
    },
    computed: {
        /**
         * Updates the selected count
         *
         * @returns number
         */
        selectedCount: function(){
            return Object.keys(this.selected).length;
        },
        meta: function(){
            if (this.response?.meta) {
                return this.response.meta;
            } else {
                return {};
            }
        }
    },
    mounted(){
        this.fetch();
    },
    methods:{

        /**
         * Fetch results from the given uri
         */
        fetch(params){
            if (this.uri == null) {
                return;
            }
            this.onLoading();
            this.loading = true;

            let _params = merge({}, this.params, params);

            this.$fnApi.call(this.uri, this.uriMethod, _params)
                .then((response) => {
                    if (response.status) {
                        this.setResponse(response);
                    } else {
                        this.$fnNotify({
                            message: response.error,
                            type: "error"
                        });
                    }
                    this.loaded = true;
                    this.onLoaded(response);
                    if (response.meta && response.meta.filters) {
                        this.filters = response.meta.filters;
                    }
                })
                .finally(() => {
                    this.loading = false;
                })
            ;
        },

        /**
         * Sets the response received from the uri
         */
        setResponse(response){
            this.allSelected = false;
            this.response = response;
        },

        /**
         * Refresh the current uri
         */
        refresh(){
            this.fetch();
        },

        /**
         * Reset the browse to the beginning
         */
        reset(){
            this.params = Object.assign({}, this.defaultParams, this.uriParams);
            this.fetch();
        },

        /**
         * On Fetching event
         */
        onLoading(){
            /**
             * Loading results from the given URI
             *
             * @property {Object} params The parameters being sent
             */
            this.$emit("loading", this.params);
        },

        /**
         * On Fetched event
         */
        onLoaded(response){
            /**
             * Loaded results from the given URI
             *
             * @property {object} response The response from the server
             */
            this.$emit("loaded", response);
        },

        /**
         * Go to first page
         */
        goToFirstPage(){
            this.goToPage(1);
        },

        /**
         * Go to last page
         */
        goToLastPage(){
            this.goToPage(this.response.meta.last_page);
        },

        /**
         * Go to next page
         */
        goToNextPage(){
            this.goToPage(this.response.meta.current_page + 1);
        },

        /**
         * Go to previous page
         */
        goToPrevPage(){
            this.goToPage(this.response.meta.current_page - 1);
        },

        /**
         * Got to a specific page
         *
         * @param {number} number The page to go to
         */
        goToPage(number){
            this.params = Object.assign({}, this.params, {page: number});
            this.fetch();
        },


        /**
         * Toggle Selecting an id
         *
         * @param id
         * @param value
         */
        toggleSelect(id, value){
            if (this.selected[id] === undefined) {
                this.selected[id] = value;
            } else {
                delete this.selected[id];
            }
            this.selected = {...this.selected};
        },

        /**
         * Toggle selecting all entries
         *
         * @param {boolean} select If to toggle the select on or off
         * @param {string} keyColumn The key in the result set for each roe that contains the identifier. Defaults to 'id'
         */
        toggleSelectAll(select, keyColumn = "id"){
            this.allSelected = select;
            forEach(this.response.data, (item) => {
                this.toggleSelect(item[keyColumn], select, false);
            });
            this.selected = {...this.selected};

            /**
             * The selected values
             *
             * @property {array} selected An array of the selected items
             */
            this.$emit("selected", Object.keys(this.selected));
        },

        /**
         * Clear all selected values
         */
        clearSelected(){
            this.allSelected = false;
            this.selected = {};

            /**
             * The selected records
             *
             * @property {array} selected An array of the selected rows (the values are those passed in with toggleSelect)
             */
            this.$emit("selected", this.selected);
        },

        /**
         * Determines if a row is selected
         *
         * @param {*} The selected keyColumn value of the item being checked for
         */
        handleIsSelected(value){
            return this.selected[value] === true;
        },

        /**
         * Toggle the sort of the results
         *
         * @param sortBy
         * @param sortDesc
         */
        toggleSortBy(sortBy, sortDesc){
            this.params = Object.assign({}, this.params, {
                page: 1,
                sortBy: sortBy,
                sortDesc: sortDesc
            });
            this.fetch();
        },
        columnsToFields: function(columns, noActions = false)
        {
            let fields = [];
            if (this.selectable) {
                fields.push({
                    key: "selectable",
                    label: "Select"
                });
            }
            forEach(columns, (column) => {
                if (column.display) {
                    fields.push(column);
                }
            });
            if (!noActions) {
                fields.push({
                    key: "actions",
                    label: "Actions",
                    thClass: "text-right buttons",
                    tdClass: "text-right buttons"
                });
            }
            return fields;
        }
    },
    watch: {
        uriParams: function(newVal, oldVal){
            if (newVal !== oldVal) {
                this.params = Object.assign({}, this.params, newVal);
                this.refresh();
            }
        }
    }
};
