export default {
    methods: {
        async _reverseGeocodeLocation(lat,lng) {
            let url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&zoom=3&format=json`;
            let response = await fetch(url);
            return await response.json();
        },
        async getCountryCode(lat,lng) {
            let data = await this._reverseGeocodeLocation(lat,lng);
            return await data.address.country_code.toUpperCase();
        }
    }
}
