import FormGroup from "./FormGroup";
import {getType} from "@fndry-vue/fn-types";

/**
 * Schema Node is responsible for controlling the individual rendered elements in the tree based
 * on the type of the schema, if it is an input, and if it is visible
 */
export default {
    functional: true,
    props: {
        nameKey: String,
        schema: {
            type: Object,
            required: true
        },
        isInput: {
            type: Boolean,
            required: false
        },
        /**
         * Disable the displaying of the labels above inputs
         *
         * This should only be disabled if the inputs have a placeholder
         */
        noLabel: Boolean,
    },
    render(createElement, context) {

        let visible = (context.props.schema.visible === undefined) ? true : context.props.schema.visible;
        if (visible) {

            if (context.props.isInput) {
                //Create a FormGroup
                return createElement(FormGroup, {
                    props: {
                        schema: context.props.schema,
                        noLabel: context.props.noLabel,
                        nameKey: context.props.nameKey
                    },
                    /*
                     * Bind up to the root form schema the listeners so that changes on the inputs go straight up
                     * to the FormSchema instead of flowing up through the entire tree.
                     */
                    on: {
                        blur: () => {
                            //todo is this needed?
                            if (context.listeners.blur) context.listeners.blur(context.props.schema.name);
                        },
                        focus: () => {
                            //todo is this needed?
                            if (context.listeners.focus) context.listeners.focus(context.props.schema.name);
                        }
                    },
                });
            } else {

                /*
                 * Here we use getType to fetch from the registered types which component we should use as the component
                 */
                const type = getType(context.props.schema.type);
                if (type === undefined) {
                    console.warn(`Input type of ${context.props.schema.type} not set. Did you forget to set it?`);
                    return;
                }

                return createElement(type, {
                    class: context.props.schema.className,
                    props: context.props.schema,
                }, context.children);
            }
        }
    }
};
