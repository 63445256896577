<template>
    <div class="d-flex flex-column align-items-stretch">
        <div class="row">
            <div class="col d-flex flex-column align-items-center">
                <fn-ui-avatar class="h-100" size="xl" type="image" :src="avatar"></fn-ui-avatar>
                <h4>{{ displayName }}</h4>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col">{{ $t('Mobile Number') }}</div>
            <div class="col text-right">{{ user.cell_phone ?? 'N/A' }}</div>
        </div>

        <div class="row mt-2">
            <div class="col">{{ $t('Username') }}</div>
            <div class="col text-right">{{ user.email }}</div>
        </div>

        <loader :loading="loading">
            <fn-form class="mt-4" no-autoload no-buttons v-model="form">
                <template v-slot="{loading, loaded, submit, flags}">
                    <div class="row">
                        <div class="col-12">{{ $t('General Notifications') }}</div>
                        <div class="col-8 font-10 mt-1">{{ $t('These are general notifications regarding system payments etc') }}</div>
                        <div class="col-2 offset-2 mt-1">
                            <fn-form-group :schema="inputs.notifications"></fn-form-group>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-12">{{ $t('Marketing Notifications') }}</div>
                        <div class="col-8 font-10 mt-1">{{ $t('These are general notifications regarding system payments etc') }}</div>
                        <div class="col-2 offset-2 mt-1">
                            <fn-form-group :schema="inputs.solicitations"></fn-form-group>
                        </div>
                    </div>
                </template>
            </fn-form>
        </loader>

        <div class="row mt-3">
            <div class="col" @click="retrieveLeases()">
                <div class="btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center">
                    <span class="font-14">{{ $t('Retrieve Past Leases') }}</span>
                    <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col" @click="changeEmailAddress()">
                <div class="btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center">
                    <span class="font-14">{{ $t('Change Email Address') }}</span>
                    <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col" @click="changePassword()">
                <div class="btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center">
                    <span class="font-14">{{ $t('Change Password') }}</span>
                    <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col" @click="logout()">
                <div class="btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center">
                    <span class="font-14">{{ $t('Logout') }}</span>
                    <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {FnUiAvatar} from "@fndry-vue/fn-ui";
import {AUTH_LOGOUT} from "../../../auth/screens";
import {CHANGE_EMAIL_SCREEN, CHANGE_PASSWORD_SCREEN} from "../../screens";
import defaultAvatar from "@theme/default/assets/images/default-avatar.svg"
import {LEASE_RETRIEVE} from "@/features/leases/screens"

export default {
    name: "ProfileTab",
    components: {
        FnUiAvatar
    },
    data() {
        return {
            formUri: '/api/v1/mobile/settings',
            loading: false,
            inputs: {
                notifications: {
                    key: "notifications",
                    id: "notifications",
                    name: "notifications",
                    type: "checkbox",
                    switch: true,
                },
                solicitations: {
                    key: "solicitations",
                    id: "solicitations",
                    name: "solicitations",
                    type: "checkbox",
                    switch: true,
                }
            },
            form: {
                notifications: false,
                solicitations: false
            }
        }
    },
    mounted() {
        this.form.notifications = Boolean(this.user?.phone_settings?.notifications)
        this.form.solicitations = Boolean(this.user?.phone_settings?.solicitations)
    },
    computed: {
        user() {
          return this.$fnAuth.getUser();
        },
        displayName() {
            if(this.user?.first_name && this.user?.last_name) {
                return this.user.first_name + ' ' + this.user.last_name;
            }
            return this.user.full_name;
        },
        avatar() {
            if(!this.user.avatar) {
                return defaultAvatar;
            }
            return this.user.avatar
        }
    },
    methods: {
        retrieveLeases() {
            this.$router.push({
                name: LEASE_RETRIEVE
            })
        },
        changeEmailAddress() {
            this.$router.push({
                name: CHANGE_EMAIL_SCREEN
            })
        },
        changePassword() {
            this.$router.push({
                name: CHANGE_PASSWORD_SCREEN
            })
        },
        logout() {
            this.$router.push({
                name: AUTH_LOGOUT
            })
        }
    },
    watch: {
        form: {
            handler : function(newVal, oldVal) {
                this.loading = true;
                this.$fnApi.call(this.formUri, "POST", newVal).then(response => {
                    if(response.status && response.data.settings) {
                        let user = {...this.user, phone_settings: response.data.settings}
                        this.$fnAuth.setUser({user});
                    }
                }).finally(() => {
                    this.loading = false;
                })
            },
            deep: true,
            immediate: false
        }
    }
}
</script>
