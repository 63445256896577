import Theme from "@theme/ok-customer";

import {FnUiIcon, FnUiPanel, FnUiLoader, FnUiIconLayers, FnUiPaginate} from "@fndry-vue/fn-ui";
import {FontAwesomeLayers} from "@fortawesome/vue-fontawesome";

/**
 * Load Images, Assets, Styles and theme files
 */
import "./scss/style.scss";

import "./icons";

const ThemePlugin = {};

ThemePlugin.install = function(Vue){

    Vue.component("loader", FnUiLoader);
    Vue.component("icon", FnUiIcon);
    Vue.component("icon-layers", FontAwesomeLayers);
    Vue.component("panel", FnUiPanel);
    Vue.component("paginate", FnUiPaginate);
    Vue.component("icon-layers", FnUiIconLayers);
    Vue.component("fn-ui-panel", FnUiPanel);

    Vue.use(Theme, {
        fontType: "light"
    });

};

export default ThemePlugin;

