let id = 0;

/**
 * File Drag Drop displays a drag and drop area which emits a change event with the list of files that were dragged and
 * dropped for further handling.
 *
 * Be sure to include it's SCSS file `@fndry-vue/fn-ui/scss/components/_file-drag-drop.scss` into your project for the styling.
 *
 */
export default {
    name: "FileDragDrop",
    render(h){

        let children = [];

        const cancelPropagation = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };
        const dragEnter = (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.isDragOver = true;
        };
        const dragLeave = (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.isDragOver = false;
        };

        let fileId = "FnUiFileDragDrop" + id++;

        let uploadIcon = this.$slots["upload-icon"] || [h("span", {
            class: this.icon
        })];

        //add icon button
        children.push(h("label", {
            class: "fn-file-input-icon-container",
            attrs: {
                for: fileId,
            },
        }, [
            h("div", {
                class: "fn-file-input-icon"})
            ]
        ));

        //add the input field
        children.push(h("input", {
            class: "file-input",
            attrs: {
                type: "file",
                id: fileId,
                multiple: this.multiple,
                accept: this.accept && this.accept.length > 0 ? this.accept.join(",") : "*"
            },
            on: {
                change: this.handleSelectFiles
            }
        }));

        let labelChildren = [];

        //add the file icon
        labelChildren.push(h("div", {
            class: "file-drop-icon"
        }, uploadIcon));

        if (!this.noText) {
            //determine the label text
            let labelText = h("div",{class: "file-dragndrop-labeltext"}, this.labelText);
            labelChildren.push(labelText);
            if (this.hasAdvancedUpload) {
                labelChildren.push(h("span", {class: "file-dragndrop-dragtext"}, " Or " + this.dragText));
            }

        }

        //load the label
        children.push(h("label", {
            ref: "label",
            attrs: {
                "for": fileId
            }
        }, labelChildren));

        children = [h("div", {class: "file-elements"}, children), h("div", {class: "file-drop-background"})];

        let dropUpload = h("div", {
            ref: "dropUpload",
            class: {
                "file-drop-upload": true,
            },
            on: {
                "dragend": dragLeave,
                "dragleave": dragLeave,
                "drop": this.handleDropFiles,
            },
        }, children);

        let wrapChildren = [];
        if (this.wrap) {
            wrapChildren = [dropUpload, this.$slots.default];
        } else {
            wrapChildren = [dropUpload];
        }

        /**
         * @slot Default slot when the component is `wrap` enabled
         */
        let slot = this.$slots.default;

        return h("div", {
            class: {
                "is-dragover": this.isDragOver,
                "is-wrap": slot !== undefined,
                "fn-ui-file-drag-drop": true,
                "has-advanced-upload": this.hasAdvancedUpload
            },
            on: {
                "drag": cancelPropagation,
                "dragstart": dragEnter,
                "dragenter": dragEnter,
                "dragover": dragEnter,
            }
        }, wrapChildren);
    },
    props: {
        /**
         * Indicates the drag drop is wrapping elements and show render only when files are drag over the contents
         */
        wrap: Boolean,
        /**
         * If the input should support multiple file upload
         */
        multiple: {
            type: Boolean,
            default: false
        },
        /**
         * The label text to display
         */
        labelText: {
            type: String,
            default: "Add your files"
        },
        /**
         * The drag text to display
         */
        dragText: {
            type: String,
            default: "browse your device"
        },
        /**
         * The drop text to display when the files are dragged over the drop area
         */
        dropText: {
            type: String,
            default: "Drop it like it's hot!"
        },
        /**
         * The icon class to use for the component
         */
        icon: {
            type: String,
            default: "fa fa-upload"
        },
        noText: Boolean,

        /**
         * Array of acceptable file extensions
         */
        accept: Array
    },
    data() {
        return {
            files: [],
            isDragOver: false
        };
    },
    computed: {
        hasAdvancedUpload: function(){
            let div = document.createElement("div");
            return (("draggable" in div) || ("ondragstart" in div && "ondrop" in div)) && "FormData" in window && "FileReader" in window;
        }
    },
    methods: {
        handleSelectFiles(evt) {
            evt.preventDefault();
            evt.stopPropagation();
            this.isDragOver = false;
            this.onChange(evt.target.files);
        },
        handleDropFiles(evt){
            evt.preventDefault();
            evt.stopPropagation();
            this.isDragOver = false;
            this.onChange(evt.dataTransfer.files);
        },
        onChange(files){
            /**
             * Emitted when a file is added either via the drap n drop or select a file.
             *
             * The emitted property is a `FileList` object containing an array of File objects that were dropped.
             *
             * @event change
             * @property {FileList} files A FileList object containing an array of File objects
             */
            this.$emit("change", files);
        }
    }
};
