<template>
  <div>
    <div class="d-flex pb-1 mb-2 pointer" @click="handleCancel">
      <fn-ui-icon-layers style="width: 50px" class="align-self-center">
        <icon icon="circle" type="solid" style="color:#DADEE3;opacity: 0.15" size="3x"></icon>
        <icon icon="calendar" type="solid" class="text-primary"></icon>
      </fn-ui-icon-layers>
      <div class="flex-grow-1 ml-1">
        <div class="font-14">{{ lease.store_name + ' ' +$t('Payment') }}</div>
        <div class="font-12">{{ lease.store_name }}</div>
        <div class="font-10">SCHEDULED ON: <fn-format-date format="MM/DD/YYYY" :value="payment.created_at" :ignore-time-zone="true"></fn-format-date></div>
      </div>

      <div class="text-right">
        <div class="text-primary">
          <fn-format-currency :value="payment.amount"></fn-format-currency>
        </div>
        <div class="font-10 text-muted">
          <strong>PAY AT:</strong> <fn-format-date format="MM/DD/YYYY" :value="payment.pay_at" :ignore-time-zone="true"></fn-format-date>
        </div>
      </div>
    </div>
    <div class="text-right">
      <fn-ui-button variant="outline-primary" @click="handleCancel" :disabled="saving" :submitting="saving">Cancel</fn-ui-button>
    </div>
    <div>
      <hr/>
    </div>
  </div>
</template>

<script>
import {FnUiIconLayers} from "@fndry-vue/fn-ui";

export default {
  name: "ScheduledPaymentItem",
  props: {
    payment: Object,
    lease : Object,
  },
  components: {
    FnUiIconLayers
  },
  methods: {
    handleCancel() {
      this.$fnModals.alert("Please contact Okinus at 1-800-472-1334 or via Chat for assistance.");
    }
  }
}
</script>
