import FnPanelsPlugin from "@fndry-vue/fn-panels";
import FnNotifyPlugin from "@fndry-vue/fn-notify";
import {FnUiPaginate} from "@fndry-vue/fn-ui";
import FnTypesPlugin, {registerDefaultTypes, registerType } from "@fndry-vue/fn-types";
import FnFormPlugin from "@fndry-vue/fn-form";
import FnMenusPlugin from "@fndry-vue/fn-menus";
import FnFormatPlugin from "@fndry-vue/fn-format";
import FnContextPlugin from "@fndry-vue/fn-context";
import FnFiltersPlugin from "@fndry-vue/fn-filters";
import {FnTypeMediumEditor} from "@fndry-vue/fn-type-medium-editor";
import FnModalsPlugin from "@fndry-vue/fn-modals";
import FnAuthPlugin from "@fndry-vue/fn-auth";
import FnLayoutPlugin from "@fndry-vue/fn-layout";
import FnScreenPlugin from "@fndry-vue/fn-screen"

const FoundryPlugin = {};

FoundryPlugin.install = function(Vue, options){

    Vue.use(FnAuthPlugin, options);
    Vue.use(FnContextPlugin, options);
    Vue.use(FnMenusPlugin, options);
    Vue.use(FnPanelsPlugin, options);
    Vue.use(FnModalsPlugin, options);
    Vue.use(FnNotifyPlugin, options);
    Vue.use(FnFiltersPlugin, options);
    Vue.use(FnFormatPlugin);
    Vue.use(FnTypesPlugin);
    Vue.use(FnFormPlugin);
    Vue.use(FnLayoutPlugin, options);
    Vue.use(FnScreenPlugin, options);

    registerDefaultTypes();
    registerType("html", FnTypeMediumEditor);

    Vue.component("paginate", FnUiPaginate);

};

export default FoundryPlugin;
