// MUTATIONS
import {merge, remove, filter} from "lodash";

export const ADD_MENU = "ADD_MENU";
export const ADD_ITEMS = "ADD_ITEMS";
export const UPDATE_ITEMS = "UPDATE_ITEMS";
export const REMOVE_ITEMS = "REMOVE_ITEMS";

export default {

    /**
     * Add a menu with items
     *
     * @param {Object} state
     * @param {String} name The name of menu to be changed
     * @param {{text: String, items: Array}} menu the new menu text and items
     */
    [ADD_MENU](state, {name, menu}){
        if (!state.menus[name]) {
            state.menus[name] = merge({}, {
                text: "Menu",
                items: []
            }, menu);
        }
    },

    /**
     * Add new items to a given menu
     *
     * @param {Object} state
     * @param {String} name The name of menu to be changed
     * @param {Array} items The new list of items
     * @param {boolean} clear To clear the existing items for the given tag
     */
    [ADD_ITEMS](state, {name, items, tag, clear = false}){

        let _items = state.menus[name].items;

        if (items == null) items = [];

        if (tag) {
            items.forEach((item, index) => {
                items[index].tag = tag;
            });
            if (clear) {
                _items = filter(_items, (item) => {
                    if (!item.tag || item.tag !== tag) {
                        return true;
                    } else {
                        return false;
                    }
                });
            }
        }

        state.menus[name] = {
            ...state.menus[name],
            items: [
                ..._items,
                ...items
            ]
        };
        state.menus = {...state.menus};
    },

    /**
     * Update the items of a given menu
     *
     * @param {Object} state
     * @param {String} name The name of menu to be changed
     * @param {Array} items The new list of items
     */
    [UPDATE_ITEMS](state, {name, items}){
        state.menus[name] = {
            ...state.menus[name],
            items: [...items]
        };
    },

    /**
     * Remove items from a give menu
     *
     * @param {Object} state
     * @param {String} name The name of menu to be changed
     * @param {String} tag The tag name to search for and remove
     */
    [REMOVE_ITEMS](state, {name, tag}){
        let items = [];

        if (tag !== null) {
            items = [...state.menus[name].items];
            remove(items, (item) => {
                if (item.tag && item.tag === tag) {
                    return true;
                } else {
                    return false;
                }
            });
        }
        state.menus[name] = {
            ...state.menus[name],
            items: [...items]
        };
    }
};
