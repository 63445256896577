import ProfileScreen from "./ProfileScreen";

import ProfileTab from "./ProfileScreen/ProfileTab";
import PaymentMethodsTab from "./ProfileScreen/PaymentMethodsTab";

import AddCardScreen from "./AddCardScreen";
import AddBankScreen from "./AddBankScreen";

import ChangeEmailScreen from "./ChangeEmailScreen";
import ChangePasswordScreen from "./ChangePasswordScreen";

export const PROFILE_SCREEN = "profile";

export const PROFILE_MAIN_SCREEN = "profile/main";
export const PAYMENT_METHODS_SCREEN = "profile/payments";

export const ADD_CARD_SCREEN = "profile/payments/add-card";
export const ADD_BANK_SCREEN = "profile/payments/add-bank";

export const CHANGE_EMAIL_SCREEN = "profile/change-email";
export const CHANGE_PASSWORD_SCREEN = "profile/change-password"

export default {
    [PROFILE_SCREEN] : ProfileScreen,
    [PROFILE_MAIN_SCREEN] : ProfileTab,
    [PAYMENT_METHODS_SCREEN] : PaymentMethodsTab,
    [ADD_CARD_SCREEN] : AddCardScreen,
    [ADD_BANK_SCREEN] : AddBankScreen,
    [CHANGE_EMAIL_SCREEN] : ChangeEmailScreen,
    [CHANGE_PASSWORD_SCREEN] : ChangePasswordScreen,
}
