<template>
  <div>
    <div class="row mb-2">
      <div class="col font-18 text-primary font-weight-bold">{{ $t('Contract Terms') }}</div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Lease Start Date') }}</div>
      <div class="col text-right">
        <fn-format-date format="MM/DD/YYYY" :value="lease.date_of_loan" :ignore-time-zone="true"></fn-format-date>
      </div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Lease Payment Date') }}</div>
      <div class="col text-right">
        <fn-format-date v-if="lease.extra" format="MM/DD/YYYY" :value="lease.extra.nextDueDate" :ignore-time-zone="true"></fn-format-date>
        <span v-else>{{ $t('N/A') }}</span>
      </div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Cash Price') }}</div>
      <div class="col text-right">
        <fn-format-currency  :value="lease.extended ? lease.extended.cash_price : 0"></fn-format-currency>
      </div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Down Payment') }}</div>
      <div class="col text-right">
        <fn-format-currency  :value="lease.extended ? lease.extended.down_payment_retained_1st : 0"></fn-format-currency>
      </div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Lease Payments') }}</div>
      <div class="col text-right">
        <fn-format-currency  :value="lease.extra ? lease.extra.PymtPerMonth : 0"></fn-format-currency>
      </div>
    </div>


    <div class="row row-cols-2 mb-2">
      <div class="col-8 text-left">{{ $t('Number of Payments') }}</div>
      <div class="col-4 text-right">{{ lease.term }}</div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <div class="btn btn-outline-dark btn-block btn-lg px-5 d-flex justify-content-between align-items-center" @click="downloadContract" :class="{'disabled':loadingContract}">
          <span class="font-14">{{ $t('Download PDF Contract') }}</span>
          <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: "LeaseContractTermsTabScreen",
    props: {
        lease : Object,
    },
    data() {
        return {
            loadingContract: false
        }
    },
    methods: {
        downloadContract() {
            if(this.loadingContract) {
                return;
            }

            this.$fnNotify({
                message: this.$t('Downloading the contract. Please wait...'),
                type: "info"
            });

            this.loadingContract = true
            this.$fnApi
                .call(`/api/v1/mobile/leases/${this.lease.lease_id}/contract`, "GET")
                .then(response => {
                    if(response?.error) {
                        this.$fnNotify({
                            message: response.error,
                            type: "error"
                        });
                    } else if(response?.data?.contract) {
                        let a = document.createElement("a");
                        a.href = response.data.contract;
                        a.download = "contract.pdf";
                        a.click();
                    }
                }).finally(() => {
                    this.loadingContract = false;
                })
        }
    }
}
</script>
