import Modal from "./Modal";
import {modalStore} from "../store";
import {REMOVE_MODAL} from "../store/mutations";

/**
 * Modals
 *
 * This is responsible for containing and rendering the modals being loaded in the modals registry.
 *
 * As a modal is added with $fnModals.add(), $fnModals.store.modals is updated with that added modal. This then
 * drips down into this component as a computed property and will then cause the change in the modals being displayed.
 *
 */
export default {
    name: "Modals",
    components: {
        Modal
    },
    computed: {
        /**
         * @return {Array} The array of modals objects
         */
        modals: function(){
            return this.$store.state.fnModals.modals;
        },
        /**
         * @return {number}
         */
        count: function(){
            return Object.keys(this.modals).length;
        },
    },
    render(createElement) {

        let modals = [];

        Object.keys(this.modals).forEach((id) => {
            let modal = this.modals[id];
            if (modal) {
                modals.push(createElement(Modal, {
                    key: id,
                    props: {
                        id: id
                    },
                    on: {
                        hidden: () => {
                            this.$store.commit(modalStore(REMOVE_MODAL), id);
                        },
                    }
                }));
            }

        });

        return createElement("div", {
            class: [
                {
                    "fn-modals": true,
                    "fn-modals--with-modals": this.count > 0,
                    "fn-modals--without-modals": this.count === 0
                },
                "fn-modals--modals-" + this.count
            ],
        }, modals);
    }
};
