export default {
    name: "fn-types-tab",
    props: {
        tab: {
            type: String,
            required: true
        },
        className: {
            type: String,
            required: false
        },
        content: {
            type: String,
            required: true
        }
    },
    render(h){
        return h(this.tab, {
            'class': this.className ? this.className : "",
            domProps: {
                innerHTML: this.content
            }
        });
    },
};
