import {AUTH_LAYOUT} from "../../layouts";
import screens, {
    AUTH_CHECK,
    AUTH_FORGOT,
    AUTH_INVITE,
    AUTH_LOGIN,
    AUTH_LOGIN_ONCE,
    AUTH_LOGOUT,
    AUTH_RESET,
    AUTH_SET_PASSWORD,
    AUTH_VERIFY,
    AUTH_FORGOT_NOTIFY, AUTH_REGISTER
} from "./screens";

import {
    makeAbilityMiddleware,
    makeAuthMiddleware,
    makeGuestMiddleware,
    makeSuperAdminMiddleware
} from "@fndry-vue/fn-auth/middleware";

let redirectTo = null;

export const setRedirectTo = (url) => {
    redirectTo = url;
};

export const getRedirectTo = () => {
    return redirectTo;
};

const Plugin = {
    install: function(Vue, {router, layouts, middleware}){

        //register the middleware
        middleware.register("auth", makeAuthMiddleware(({to, next, auth}) => {
            next({
                name: AUTH_CHECK,
                params: {
                    redirect: to,
                    auth: true
                }
            });
        }));
        middleware.register("guest", makeGuestMiddleware(({to, next, auth}) => {
            next({
                name: AUTH_LOGIN,
                params: {
                    redirect: to,
                    guest: true
                }
            });
        }));
        middleware.register("ability", makeAbilityMiddleware(({to, next, auth}) => {
            next("/403");
        }));
        middleware.register("superAdmin", makeSuperAdminMiddleware(({to, next, auth}) => {
            next("/403");
        }));

        //add the routes
        router.addRoutes([
            {
                path: "/",
                redirect: {
                    path: "/login",
                },
                component: layouts[AUTH_LAYOUT],
                children: [
                    {
                        path: "login",
                        name: AUTH_LOGIN,
                        component: screens[AUTH_LOGIN],
                        props: true
                    },
                    {
                        path: "invite/:token",
                        name: AUTH_INVITE,
                        component: screens[AUTH_INVITE],
                        props: true
                    },
                    {
                        path: "verify/:token",
                        name: AUTH_VERIFY,
                        component: screens[AUTH_VERIFY],
                        props: true
                    },
                    {
                        path: "login-once/:token",
                        name: AUTH_LOGIN_ONCE,
                        component: screens[AUTH_LOGIN_ONCE],
                        props: true
                    },
                    {
                        path: "forgot",
                        name: AUTH_FORGOT,
                        component: screens[AUTH_FORGOT],
                        props: true
                    },
                    {
                        path: "forgot/notify",
                        name: AUTH_FORGOT_NOTIFY,
                        component: screens[AUTH_FORGOT_NOTIFY],
                        props: true
                    },
                    {
                        path: "reset",
                        name: AUTH_RESET,
                        component: screens[AUTH_RESET],
                        props: true
                    },
                    {
                        path: "set-password",
                        name: AUTH_SET_PASSWORD,
                        component: screens[AUTH_SET_PASSWORD],
                        props: true
                    },
                    {
                        path: "check",
                        name: AUTH_CHECK,
                        component: screens[AUTH_CHECK],
                        props: true
                    },
                    {
                        path: "register",
                        name: AUTH_REGISTER,
                        component: screens[AUTH_REGISTER],
                    },
                    {
                        path: "logout",
                        name: AUTH_LOGOUT,
                        component: screens[AUTH_LOGOUT]
                    }
                ]
            }
        ]);

        // //add the messages
        // locale.addLocales({
        //     en: en
        // });

    }
};

export default Plugin;
