import {Button, ButtonPromise} from "./components/button";
import {CardWidget} from "./components/card-widget";
import {FileDragDrop} from "./components/file-drag-drop";
import {FileUpload} from "./components/file-upload";
import {FilterForm} from "./components/filter";

import {Loader} from "./components/loader";
import {Paginate} from "./components/paginate";
import {Screen} from "./components/screen";

import {Icon} from "./components/icon";
import Avatar from "./components/avatar";
import Breadcrumb from "./components/breadcrumb";
import ActionButtons from "./components/action-buttons/ActionButtons";
import Accordion from "./components/accordion";
import ProgressCircle from "./components/progress-circle";
import StagedProgressBar from "./components/staged-progress-bar";
import {TreeGridTable, TreeGridTableRow} from "./components/tree-grid-table";

import Breakpoint from "./components/breakpoint/Breakpoint";

import detailMixin from "./mixins/detailMixin";
import tableCardColumnSelectable from "./mixins/tableCardColumnSelectable";

import {FontAwesomeLayers} from "@fortawesome/vue-fontawesome";

let defaultFontType = "light";

const FnUiPlugin = {
    install: function(Vue, options){

        if (options?.fontType) {
            defaultFontType = options.fontType;
        }

        Vue.component("fn-ui-button", Button);
        Vue.component("fn-ui-button-promise", ButtonPromise);

        Vue.component("fn-ui-card-widget", CardWidget);
        Vue.component("fn-ui-file-drag-drop", FileDragDrop);
        Vue.component("fn-ui-file-upload", FileUpload);
        Vue.component("fn-ui-filter-form", FilterForm);

        Vue.component("fn-ui-loader", Loader);
        Vue.component("fn-ui-paginate", Paginate);
        Vue.component("fn-ui-screen", Screen);
    }
};

export default FnUiPlugin;

/**
 * Constants
 */
export const AUTH_LAYOUT = "auth";
export const DEFAULT_LAYOUT = "default";
export const ERROR_LAYOUT = "error";

export {
    defaultFontType,

    ButtonPromise as FnUiButtonPromise,
    Button as FnUiButton,
    CardWidget as FnUiCardWidget,
    FileDragDrop as FnUiFileDragDrop,
    FileUpload as FnUiFileUpload,
    FilterForm as FnUiFilterForm,
    Loader as FnUiLoader,
    Paginate as FnUiPaginate,
    Screen as FnUiScreen,
    detailMixin as fnUiDetailMixin,
    tableCardColumnSelectable as fnUiTableCardColumnSelectableMixin,

    Icon as FnUiIcon,
    FontAwesomeLayers as FnUiIconLayers,
    Avatar as FnUiAvatar,
    Breadcrumb as FnUiBreadcrumb,
    ActionButtons as FnUiActionButtons,
    Accordion as FnUiAccordion,
    ProgressCircle as FnUiProgressCircle,
    StagedProgressBar as FnUiStagedProgressBar,

    TreeGridTable as FnUiTreeGridTable,
    TreeGridTableRow as FnUiTreeGridTableRow,

    Breakpoint as FnUiBreakpoint
};

/**
 * Card related compoents
 */
import CardLayout from "./components/card-layout";
import {CardTab, CardTabItem} from "./components/card-tab";
import {TableCard, DataTable} from "./components/table-card";
import {NavCard, NavItem} from "./components/nav-card"
import ProfileCard from "./components/profile-card"

export {
    TableCard as FnUiTableCard,
    DataTable as FnUiDataTable,
    CardLayout as FnUiCardLayout,
    CardTab as FnUiCardTab,
    CardTabItem as FnUiCardTabItem,
    NavCard as FnUiNavCard,
    NavItem as FnUiNavItem,
    ProfileCard as FnUiProfileCard,
};

/**
 * Panel components
 */

import {
    Panel,
    PanelForm,
    PanelSidebar,
    PanelInfoForm
} from "./components/panel";

export {
    Panel as FnUiPanel,
    PanelForm as FnUiPanelForm,
    PanelSidebar as FnUiPanelSidebar,
    PanelInfoForm as FnUiPanelInfoForm
};

/**
 * Layout related components
 */
import {
    Layout,
    LayoutColumn,
    LayoutColumnContent,
    LayoutRow,
    LayoutColumnHeader,
    LayoutColumnHeaderBranded,
    LayoutNavigation,
    LayoutNavigationHeader,
    LayoutNavigationLink,
    LayoutNavigationGroup,
    LayoutNavigationList,
} from "./components/layout";

export {
    Layout as FnUiLayout,
    LayoutColumn as FnUiLayoutColumn,
    LayoutColumnContent as FnUiLayoutColumnContent,
    LayoutRow as FnUiLayoutRow,
    LayoutColumnHeader as FnUiLayoutColumnHeader,
    LayoutColumnHeaderBranded as FnUiLayoutColumnHeaderBranded,
    LayoutNavigation as FnUiLayoutNavigation,
    LayoutNavigationHeader as FnUiLayoutNavigationHeader,
    LayoutNavigationLink as FnUiLayoutNavigationLink,
    LayoutNavigationGroup as FnUiLayoutNavigationGroup,
    LayoutNavigationList as FnUiLayoutNavigationList,
};

/**
 * Reporting
 */
import {
    ReportHeader,
    ReportSection,
    ReportInput,
    ReportSwitch,
    ReportTable,
    ReportRow,
    ReportColumn,
    ReportColumnHeader,
    ReportControls,
    ReportColumnValue,
    ReportColumnTotal,
    ReportColumnSelector,
    ReportMenuSection,
    ReportMenuVersion,
    ReportMenuHistory,
    ReportMenuHistoryItem,
    ReportMenuHierarchy,
    ReportMenuSelectHeader,
    ReportMenuSearchSection,
    ReportMenuItemsSelection,
    ReportTaggableList,
    ReportMenuSummarySection,
    ReportMenuSummarySectionData,
    ReportHierarchySelector,
    ReportCurrencyControl,
    ReportRangeSelector,
} from "./components/report";

export {
    ReportHeader as FnUiReportHeader,
    ReportSection as FnUiReportSection,
    ReportInput as FnUiReportInput,
    ReportSwitch as FnUiReportSwitch,
    ReportTable as FnUiReportTable,
    ReportRow as FnUiReportRow,
    ReportColumn as FnUiReportColumn,
    ReportColumnHeader as FnUiReportColumnHeader,
    ReportControls as FnUiReportControls,
    ReportColumnValue as FnUiReportColumnValue,
    ReportColumnTotal as FnUiReportColumnTotal,
    ReportColumnSelector as FnUiReportColumnSelector,
    ReportMenuSection as FnUiReportMenuSection,
    ReportMenuVersion as FnUiReportMenuVersion,
    ReportMenuHistory as FnUiReportMenuHistory,
    ReportMenuHistoryItem as FnUiReportMenuHistoryItem,
    ReportMenuHierarchy as FnUiReportMenuHierarchy,
    ReportMenuSelectHeader as FnUiReportMenuSelectHeader,
    ReportMenuSearchSection as FnUiReportMenuSearchSection,
    ReportMenuItemsSelection as FnUiReportMenuItemsSelection,
    ReportTaggableList as FnUiReportTaggableList,
    ReportMenuSummarySection as FnUiReportMenuSummarySection,
    ReportMenuSummarySectionData as FnUiReportMenuSummarySectionData,
    ReportHierarchySelector as FnUiReportHierarchySelector,
    ReportCurrencyControl as FnUiReportCurrencyControl,
    ReportRangeSelector as FnUiReportRangeSelector,
};

/**
 * Menu
 */

import {
    PrimaryMenuColumn,
    ContextMenuColumn,
    HeaderMenuColumn,
} from "./components/menu";

export {
    PrimaryMenuColumn as FnUiPrimaryMenuColumn,
    ContextMenuColumn as FnUiContextMenuColumn,
    HeaderMenuColumn as FnUiHeaderMenuColumn,
};

/**
 * Navbar
 */
import NavBar from "./components/nav-bar"
export {
    NavBar as FnUiNavBar
};
