import {merge} from "lodash";

import FormGroup from "./components/FormGroup";
import Form from "./components/Form";
import FormSchema from "./components/FormSchema";
import FormButton from "./components/FormButton";

//validation and rules
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import locale from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import * as fnrules from "./rules";

/**
 * FnFormPlugin
 * (c) 2020
 */
const FnFormPlugin = {};

/**
 * Form Plugin install
 *
 * @param {Object} Vue The vue instance
 */
FnFormPlugin.install = function (Vue) {

    Vue.component("validation-provider", ValidationProvider);
    Vue.component("validation-observer", ValidationObserver);

    //base rules
    Object.keys(rules).forEach(rule => {
        extend(rule, {
            ...rules[rule], // copies rule configuration
            message: locale.messages[rule] // assign message
        });
    });

    //local rules
    Object.keys(fnrules).forEach(rule => {
        extend(rule, {
            ...fnrules[rule], // copies rule configuration
            message: locale.messages[rule] // assign message
        });
    });

    Vue.component("fn-form", Form);
    Vue.component("fn-form-schema", FormSchema);
    Vue.component("fn-form-group", FormGroup);
    Vue.component("fn-form-button", FormButton);

    /**
     * Add a panel form to the layout
     * @param panel
     */
    Vue.prototype.$fnFormPanel = function(panel) {
        this.$fnPanels.add(merge({}, panel, {component: Form, form: true}));
    };

};

export default FnFormPlugin;

export {
    FormGroup as FnFormGroup,
    Form as FnForm,
    FormSchema as FnFormSchema,
    FormButton as FnFormButton
}
