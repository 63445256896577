<template>
    <loader :loading="loading || loadingCards || loadingBanks">
        <div v-if="customerCards.length > 0">
            <div class="row mb-3" v-for="(card, index) in customerCards" :key="index">
                <div class="col d-flex justify-content-center">
                    <card-item
                        :card="card"
                        :is-default-payment="isDefault('card', card.id)"
                        :is-payliance-card="isPaylianceCard(card.provider)"
                        @make-default="makeDefault('card', card.id)"
                        @delete="deleteCard(card.id)"
                        @reverify="reverifyCard(card)"
                    ></card-item>
                </div>
            </div>
        </div>

        <div v-if="customerBanks.length > 0">
            <div class="row mb-2" v-for="(bank, index) in customerBanks" :key="index">
                <div class="col d-flex justify-content-center">
                    <bank-item
                        :bank="bank"
                        :is-default-payment="isDefault('bank', bank.id)"
                        @make-default="makeDefault('bank', bank.id)"
                        @delete="deleteBank(bank.id)"
                    ></bank-item>
                </div>
            </div>
        </div>

        <div class="row my-3">
            <div class="col" @click="addNewCard()">
                <div class="btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center">
                    <span class="font-14">{{ $t('Add New Credit Card') }}</span>
                    <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col" @click="addNewBank()">
                <div class="btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center">
                    <span class="font-14">{{ $t('Add New ACH Account') }}</span>
                    <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
                </div>
            </div>
        </div>
    </loader>
</template>

<script>
import customerDetails from "../../../dashboard/mixins/customerDetails";
import CardItem from "../../components/CardItem";
import BankItem from "../../components/BankItem";
import {ADD_CARD_SCREEN, ADD_BANK_SCREEN} from "@/features/profile/screens";
import {fnResponseToNotification} from "@fndry-vue/fn-notify";

export default {
    name: "PaymentMethodsTab",
    mixins: [customerDetails],
    components: {
        CardItem,
        BankItem,
    },
    data() {
        return {
            loading : false,
        }
    },
    methods: {
        refresh() {
            this.getCustomerCards();
            this.getCustomerBanks();
        },
        addNewCard() {
            this.$router.push({
                name: ADD_CARD_SCREEN
            })
        },
        addNewBank() {
            this.$router.push({
                name: ADD_BANK_SCREEN
            })
        },
        isDefault(type, id) {
            return this.defaultPayment.payment_type === type && this.defaultPayment.id === id;``
        },
        isPaylianceCard(provider) {
            return provider == "payliance"
        },
        makeDefault(type, id) {
            this.loading = true;
            this.$fnApi.call('/api/v1/mobile/customers/payments/default', 'POST', {
                type: type,
                id: id
            }).then(response => {
                if(response.status) {
                    this.refresh();
                } else {
                    this.$fnNotify(fnResponseToNotification(response))
                }
            })
            .finally(() => {
                this.loading = false;
            });
        },
        deleteCard(id) {
            if(id != null) {
                this.$fnApi.call(`/api/v1/mobile/cards/${id}/delete`, 'POST').then(response => {
                    if(response.status) {
                        this.refresh();
                    } else {
                        this.$fnNotify(fnResponseToNotification(response))
                    }
                })
            }
        },
        reverifyCard(card) {
            this.$router.push({
                name: ADD_CARD_SCREEN,
                params: {
                    old_card: card,
                }
            });
        },
        deleteBank(id) {
            if(id != null) {
                this.$fnApi.call(`/api/v1/mobile/banks/${id}/delete`, 'POST').then(response => {
                    if(response.status) {
                        this.refresh();
                    } else {
                        this.$fnNotify(fnResponseToNotification(response))
                    }
                })
            }
        }
    }
}
</script>
