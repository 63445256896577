/**
 * Panel
 *
 * This component is a functional component for the panel that is being loaded
 */
import {REMOVE_PANEL} from "../store/mutations";
import {panelStore} from "../store";
import {debounce} from "lodash";

export default {
    name: "Panel",
    props: {
        /**
         * The panel offset (allows us to control positioning of the panel)
         */
        offset: {
            type: Number,
            default() {
                return 0;
            }
        },
        id: {
            type: String,
            required: true
        }
    },
    computed: {
        /**
         * The panel details
         *
         * @see PanelRegistry.add()
         */
        panel: function(){
            return this.$store.state.fnPanels.panels[this.id];
        }
    },
    render(createElement) {

        let _class = [
            "fn-panel",
            "fn-panel--offset-" + this.offset,
            {
                "fn-panel--visible": this.panel.visible,
                "fn-panel--hidden": !this.panel.visible,
            }
        ];
        if (this.panel.stacked) {
            _class.push("fn-panel--stacked");
        } else {
            _class.push("fn-panel--not-stacked");
        }
        _class.push(this.panel.size);

        let on = {};
        if (this.panel.on) {
            Object.keys(this.panel.on).forEach((key) => {
                on[key] = (...params) => {
                    let result = this.panel.on[key](...params);
                    if ((result === true) || (key === "close")) {
                        this.$listeners.close();
                    }
                };
            });
        }
        if (on.close === undefined){
            on.close = this.$listeners.close;
        }

        let component = createElement(this.panel.component, {
            class: "fn-panel-slot",
            props: {
                ...this.panel.props,
                id: this.id
            },
            on
        });
        return createElement("div", {
            class: _class,
            attrs: {
                id: this.panel.id
            },
        }, [component]);
    },
    watch: {
        "panel.visible": function(newVal){
            if (newVal === false) {
                let overlay = window.document.getElementById(`${this.id}-overlay`);
                if (overlay) {
                    overlay.classList.add("closing");
                }
                debounce(() => {
                    this.$store.commit(panelStore(REMOVE_PANEL), this.id);
                }, 500)();
            }
        }
    },
};

