<template>
  <div class="current-lease-item">
    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Lease Name') }}</div>
      <div class="col text-right">{{ lease.store_name + ' ' +$t('Payment') }}</div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col-2 text-left">{{ $t('Store') }}</div>
      <div class="col-10 text-right">{{ lease.store_name ?? ''}}</div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Last Payment') }}</div>
      <div class="col text-right">
        <fn-format-date format="MM/DD/YYYY" :value="lease.extra.lastPaymentdate" v-if="lease.extra.lastPaymentdate" :ignore-time-zone="true"></fn-format-date>
        <span v-else>---</span>
      </div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Next Due Date') }}</div>
      <div class="col text-right">
        <fn-format-date format="MM/DD/YYYY" :value="lease.extra.nextDueDate" v-if="lease.extra.nextDueDate" :ignore-time-zone="true"></fn-format-date>
        <span v-else>---</span>
      </div>
    </div>

    <div class="row row-cols-2 mb-2" v-if="!allowAnyPaymentAmount">
      <div class="col text-left">{{ $t('Minimum Due') }}</div>
      <div class="col text-right">
        <fn-format-currency :value="lease.extra.MinDue" v-if="lease.extra.MinDue"></fn-format-currency>
        <span v-else>---</span>
      </div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Total Due') }}</div>
      <div class="col text-right">
        <fn-format-currency :value="lease.extra.totalDue" v-if="lease.extra.totalDue"></fn-format-currency>
        <span v-else>-</span>
      </div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Balance') }}</div>
      <div class="col text-right">
        <fn-format-currency :value="lease.extra.balance" v-if="lease.extra.totalDue"></fn-format-currency>
        <span v-else>---</span>
      </div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{  $t(displayedPayOffDateLabel) }}</div>
      <div class="col text-right">
        <fn-format-date format="MM/DD/YYYY" :value="displayedPayOffDate" v-if="displayedPayOffDate" :ignore-time-zone="true"></fn-format-date>
        <span v-else>---</span>
      </div>
    </div>

    <div class="row row-cols-2 mb-3">
      <div class="col text-left">{{ $t('Provider') }}</div>
      <div class="col text-right">{{ $t(getProvider(lease?.store_type))}}</div>
    </div>

    <div class="row mb-2">
      <div class="col" @click="goToDetails()">
        <div class="btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center">
          <span class="font-14">{{ $t('More Details') }}</span>
          <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col" @click="handlePayEarly()">
        <div class="btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center">
          <span class="font-14">{{ $t('Make Payment') }}</span>
          <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {CURRENT_LEASES_SCREEN, LEASE_SUMMARY_SCREEN} from "@/features/leases/screens";
import {DASHBOARD_PAY_EARLY_SCREEN} from "@/features/dashboard/screens"
import moment from "moment";

export default {
  name: "CurrentLeaseItem",
  props: {
    lease: Object
  },
  computed: {
    allowAnyPaymentAmount: function() {
      return this?.lease?.extra?.AllowAnyPaymentAmount == "1";
    },
    displayedPayOffDate: function() {
      if(this?.lease?.extra?.EPODueDate == null) {
        return this?.lease?.extra?.PayoffGoodThruDate;
      }
      const EPODueDate = moment(this?.lease?.extra?.EPODueDate, "YYYYMMDD");
      const currentDate = moment();

      if (EPODueDate?.isValid() && EPODueDate?.isSameOrAfter(currentDate, 'day')) {
        return this?.lease?.extra?.EPODueDate;
      } else {
        return this?.lease?.extra?.PayoffGoodThruDate;
      }
    },
    displayedPayOffDateLabel: function() {
      if(this?.lease?.extra?.EPODueDate == null) {
        return "Payoff Due Date";
      }
      const EPODueDate = moment(this?.lease?.extra?.EPODueDate, "YYYYMMDD");
      const currentDate = moment();
      
      if (EPODueDate?.isValid() && EPODueDate?.isSameOrAfter(currentDate, 'day')) {
        return "Early Payoff Date";
      } else {
        return "Payoff Due Date";
      }
    }
  },
  methods: {
    goToDetails() {
      this.$router.push({
        name: LEASE_SUMMARY_SCREEN,
        params: {
          id: this.lease.lease_id,
          details: {
            store_name: this.lease.store_name,
            contact: this.lease.store_phone,
            address: `${this.lease.store_street1}, ${this.lease.store_city}, ${this.lease.store_state}`,
          },
          lease : this.lease
        }
      })
    },
    handlePayEarly() {
      let pending = {
        minDue: this.allowAnyPaymentAmount ? 1 : this.lease.extra.MinDue,
        amount: this.lease.extra.MinDue,
        amount_ach: this.lease.extra.MinDue,
        pay_at: this.lease.extra.nextDuedate,
      };
      this.$router.push({
          name: DASHBOARD_PAY_EARLY_SCREEN,
          params: {
              pending: pending,
              lease: this.lease,
              details: {
                  store_name: this.lease.store_name,
                  contact: this.lease.store_phone,
                  address: `${this.lease.store_street1}, ${this.lease.store_city}, ${this.lease.store_state}`,
              },
              backRouteName: CURRENT_LEASES_SCREEN
          }
      });
    },
    getProvider(store_type) {
      if(store_type?.toUpperCase()?.trim() === "HVAC") {
        return "Breeze";
      } else {
        return "Okinus";
      }
    }
  }
}
</script>
