import store_, {authStore} from "../store";
import {
    REMOVE_GUEST,
    REMOVE_USER, SET_AUTH_GUARD, SET_AUTH_UI,
    SET_GUEST,
    SET_SESSION_EXPIRED,
    SET_USER,
    UPDATE_USER
} from "../store/mutations";
import {
    getAuthGuard, getAuthUi,
    getStatus,
    getUser,
} from "../store/getters";

/**
 * The Auth Service object
 */
class Auth {

    /**
     * @param store The vuex store instance
     */
    constructor(store) {
        // Install the Auth Store
        store_.install(store);

        this.store = store;

        /**
         * A list of the passes the currently logged in user has previously passed
         *
         * @type {{}}
         */
        this.qualifies = {};
    }

    /**
     * Are we logged in
     *
     * @returns {boolean}
     */
    isLoggedIn() {
        const user = this.getUser();

        return !!(user && user.id);
    }

    /**
     * Is the current user an admin
     *
     * @returns {boolean}
     */
    isAdmin() {
        const user = this.getUser();

        return (user && (user.is_admin || user.is_super_admin));
    }

    /**
     * Is the current user a super admin
     *
     * @returns {boolean}
     */
    isSuperAdmin() {
        const user = this.getUser();

        return (user && user.is_super_admin);
    }

    /**
     * Check if user is the current auth user
     *
     * @param {object} user
     * @returns {boolean}
     */
    isUser(user) {
        const authUser = this.getUser();

        return (user && this.isLoggedIn() && authUser.id === user.id);
    }

    /**
     * Check if the id is the current auth user id
     *
     * @param id
     * @returns {boolean}
     */
    isUserId(id) {
        const user = this.getUser();

        return (user && user.id === id);
    }

    /**
     * Checks if the current user has the required guard
     *
     * @param guard
     * @returns {boolean}
     */
    hasGuard(guard) {
        const user = this.getUser();

        if (!user) return false;

        if (Array.isArray(guard)) {
            return (guard.indexOf(user.guard) !== -1);
        }

        return (user.guard === guard);
    }

    /**
     * Determines if the current user has the given ability
     *
     * @param ability
     * @returns {boolean}
     */
    hasAbility(ability) {
        if (!this.isLoggedIn()) {
            return false;
        }

        if (this.isAdmin()) {
            return true;
        }

        const user = this.getUser();

        let abilities = [];

        if (typeof ability === "function") {
            return ability(this);
        } else if (Array.isArray(ability)) {
            abilities = ability;
        } else if (ability.indexOf("|") !== -1) {
            abilities  = ability.split("|");
        } else {
            abilities = [ability];
        }

        let can = false;

        for(const _ability of abilities) {
            if (this.qualifies._ability) {
                can = this.qualifies[_ability];
            } else if (user.abilities && user.abilities.indexOf(_ability) !== -1) {
                this.qualifies[_ability] = true;
                can = true;
            } else {
                this.qualifies[_ability] = false;
            }
            if (can) {
                break;
            }
        }
        return can;
    }

    /**
     * Resolve by a promise if the user has the given ability
     *
     * @param {string} ability
     * @returns {Promise<unknown>}
     */
    resolveAbility(ability) {
        return new Promise((resolve, reject) => {
            if (this.hasAbility(ability)) {
                resolve();
            } else {
                reject();
            }
        });
    }

    /**
     * Get the current status
     *
     * @returns {boolean}
     */
    getStatus() {
        return this.store.getters[authStore(getStatus)];
    }

    /**
     * Get the auth user
     *
     * @returns {{abilities: Array, guard: String, is_super_admin: Boolean, is_admin: Boolean}}
     */
    getUser() {
        return this.store.getters[authStore(getUser)];
    }

    /**
     * Return the list of abilities for the current user
     *
     * @returns {Array}
     */
    getAbilities() {
        const user = this.getUser();

        return (user && user.abilities) ? user.abilities : [];
    }

    /**
     * Set the authenticated User
     *
     * @param {object} user
     */
    setUser(user) {
        return this.store.commit(authStore(SET_USER), user);
    }

    /**
     * Remove the authenticated user
     */
    removeUser() {
        return this.store.commit(authStore(REMOVE_USER));
    }

    /**
     * Update the details about the currently logged in user
     *
     * @param {object} user
     */
    updateUser(user) {
        return this.store.commit(authStore(UPDATE_USER), user);
    }

    /**
     * Mark the session expired status
     *
     * @param {boolean} expired
     */
    setSessionExpired(expired) {
        return this.store.commit(authStore(SET_SESSION_EXPIRED), expired);
    }

    /**
     * Set the user as guest
     */
    setGuest() {
        return this.store.commit(authStore(SET_GUEST));

    }

    /**
     * Remove the guest user
     */
    removeGuest() {
        return this.store.commit(authStore(REMOVE_GUEST));
    }

    /**
     * Check auth user ability
     *
     * @param {string|Array|function} methodOrAbility The method of the policy to call, or the ability name or array of names, or closure that will be called if no policy is supplied
     * @param {{}} policy (Optional) The policy Object
     * @param {*} args (Optional) The arguments to be passed to the policy method being called
     * @returns {boolean}
     */
    can(methodOrAbility, policy = undefined, args = undefined) {
        // Check for user
        if (!this.getUser()) {
            return false;
        }

        // Check ability using provided policy
        if (policy) {
            // eslint-disable-next-line no-prototype-builtins
            if (policy.hasOwnProperty("before")) {
                if (policy.before(this)) {
                    return true;
                }
            }
            // eslint-disable-next-line no-prototype-builtins
            if (policy.hasOwnProperty(methodOrAbility)) {
                return policy[methodOrAbility](this, args);
            }
        }

        // Check if the ability is from user permissions
        else if (this.hasAbility(methodOrAbility)) {
            return true;
        }

        return false;
    }

    setAuthGuard(guard) {
        return this.store.commit(authStore(SET_AUTH_GUARD), guard);
    }

    setAuthUi(ui) {
        return this.store.commit(authStore(SET_AUTH_UI), ui);
    }

    getAuthGuard() {
        return this.store.getters[authStore(getAuthGuard)];
    }

    getAuthUi() {
        return this.store.getters[authStore(getAuthUi)];
    }

}

export default Auth;
