<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>

import withContext from "@fndry-vue/fn-context/mixins/withContext";
import {withScreen} from "@fndry-vue/fn-screen"
import LeasesTabs from "../../components/LeasesTabs";
import {APPROVED_LEASES_SCREEN, CURRENT_LEASES_SCREEN, PAID_OFF_LEASES_SCREEN} from "@/features/leases/screens";

export default {
    name: "LeasesScreen",
    mixins: [
        withContext,
        withScreen,
    ],
    components : {
        LeasesTabs
    },
    screen() {
        return {
            title: this.$t('All Leases'),
            data: {
                withButton: true,
                tabs : [
                    {
                        name: this.$t('Current'),
                        route_name: CURRENT_LEASES_SCREEN
                    },
                    {
                        name: this.$t('Approved'),
                        route_name: APPROVED_LEASES_SCREEN
                    },
                    {
                        name: this.$t('Paid off'),
                        route_name: PAID_OFF_LEASES_SCREEN
                    },
                ]
            }
        }
    },
    context(){
        return false;
    }
};
</script>
