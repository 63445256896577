import {uniqueId} from "lodash";
import {ADD_MODAL, CLOSE_MODAL} from "./mutations";

export const openModal = "openModal";
export const closeModal = "closeModal";
export const closeAll = "closeAll";

export default {
    /**
     * Open a modal
     *
     * @param commit
     * @param modal
     * @return {string}
     */
    async [openModal]({commit}, modal) {
        let id = uniqueId("FnModal-");
        commit(ADD_MODAL, {id, modal});
        return id;
    },
    /**
     * Close a modal
     *
     * @param state
     * @param commit
     * @param id
     */
    async [closeModal]({commit}, id){
        commit(CLOSE_MODAL, id);
        return true;
    },
    /**
     * Close all the modals
     *
     * @param state
     * @param dispatch
     * @return {*}
     */
    async [closeAll]({state, dispatch}){
        let id = state.index[0];
        return dispatch(closeModal, id);
    }
}
;
