import fileDownload from "js-file-download";
import store_, {apiStore} from "../store";
import {
    PUSH_DOWNLOAD,
    UPDATE_DOWNLOAD_PROGRESS,
    RESET_DOWNLOAD_PROGRESS,
    TOGGLE_CALLING
} from "../store/mutations";
import {uniqueId, merge} from "lodash";

/**
 * Makes a new response for returning to the application
 *
 * @param {Boolean} status The status of the response
 * @param {null|array|object} data The response data from the api
 * @param {String} error The error message if applicable
 * @param {String} code The response code which follows HTTP status code standards
 * @returns {{status: *, code: *, data: *, error: *}}
 */
function makeResponse(status, data = null, error = null, code = null)
{
    return {
        status: status,
        code: code,
        data: data,
        error: error
    };
}

/**
 * Replace any {*} placeholder with their param equivalent
 *
 * @param url (string) The url
 * @param params (object) The parameter we are sending
 */
function updateUrlPlaceholders(url, params, remove)
{
    if (remove === undefined) {
        remove = false;
    }
    let _params = {...params};
    let regex = new RegExp("{.*}", "g");
    if (regex.test(url)) {
        Object.keys(_params).forEach(function(key){
            let value = _params[key];
            if (url.search("\{" + key + "\}") > -1) {
                url = url.replace("\{" + key + "\}", value);
                if (remove) {
                    delete _params[key];
                }
            }
        });
    }
    return {
        url: url,
        data: _params
    };
}

/**
 * Builds a query string
 *
 * @param data
 * @return {string}
 */
export const buildQuery = (data) => {

    // If the data is already a string, return it as-is
    if (typeof (data) === "string") return data;

    return toQueryString(data, false);

};

function toQueryString(obj, urlEncode) {
    //
    // Helper function that flattens an object, retaining key structure as a path array:
    //
    // Input: { prop1: 'x', prop2: { y: 1, z: 2 } }
    // Example output: [
    //     { path: [ 'prop1' ],      val: 'x' },
    //     { path: [ 'prop2', 'y' ], val: '1' },
    //     { path: [ 'prop2', 'z' ], val: '2' }
    // ]
    //
    function flattenObj(x, path) {
        let result = [];

        path = path || [];
        Object.keys(x).forEach(function (key) {
            if (!x.hasOwnProperty(key)) return;

            var newPath = path.slice();
            newPath.push(key);

            var vals = [];
            if (x[key] === undefined) {
                return;
            } else if (typeof x[key] === "object" && x[key] !== null) {
                vals = flattenObj(x[key], newPath);
            } else {
                vals.push({ path: newPath, val: x[key] });
            }
            vals.forEach(function (obj) {
                return result.push(obj);
            });
        });

        return result;
    } // flattenObj

    // start with  flattening `obj`
    let parts = flattenObj(obj); // [ { path: [ ...parts ], val: ... }, ... ]

    // convert to array notation:
    parts = parts.map(function (varInfo) {
        if (varInfo.path.length == 1) varInfo.path = varInfo.path[0];else {
            let first = varInfo.path[0];
            let rest = varInfo.path.slice(1);
            varInfo.path = first + "[" + rest.join("][") + "]";
        }
        return varInfo;
    }); // parts.map

    // join the parts to a query-string url-component
    let queryString = parts.map(function (varInfo) {
        return varInfo.path + "=" + varInfo.val;
    }).join("&");
    if (urlEncode) {
        return encodeURIComponent(queryString);
    } else {
        return queryString;
    }
}

/**
 * Generates a url based on the provided url and params
 *
 * This will do regular url replacements
 *
 * @param url
 * @param params
 * @returns {string}
 */
export const route = (url, params) => {

    let data = updateUrlPlaceholders(url, {...params}, true);
    let queryString = "";
    if (data.data && Object.keys(data.data).length > 0) {
        queryString = buildQuery( data.data );
        if (data.url.indexOf("?") === -1) {
            queryString = "?" + queryString;
        } else {
            queryString = "&" + queryString;
        }
    }

    return data.url + queryString;
};

/**
 * Creates the ApiService object
 *
 * @param {Object} Axios The instance of Axios to use for the calls
 * @constructor
 */
class ApiService {

    constructor(Axios, store, config)
    {
        if (store) {
            // Install the Auth Store
            store_.install(store);
            this.store = store;
        }

        /**
         * Configure axios
         */
        this.$http = Axios.create({
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Accept": "application/json"
            },
            validateStatus: function (status) {
                //this forces that all response from the server are resolved and not rejected
                return true;
            },
            baseURL: config("api.url")
        });

        // Format nested params correctly
        this.$http.interceptors.request.use(config => {
            config.paramsSerializer = params => {
                return buildQuery(params);
            };
            return config;
        });

        this.$http.defaults.timeout = config("api.timeout", 1000);
    }

    /**
     * Call handler
     *
     * @param options
     * @param that
     * @returns {Promise<unknown>}
     */
    handleCall(options) {
        this._commit(TOGGLE_CALLING, true);
        return this.$http(options)
            .then(function (response) {
                if (options.responseType === "blob") {
                    return response;
                } else if (response.data.hasOwnProperty("status")) {
                    return response.data;
                }  else if(response.status && response.status >= 500) {
                    return makeResponse(false, null, response.statusText, response.status);
                } else {
                    return makeResponse(false, null, "Unknown Response", 500);
                }
            })
            .catch((err) => {
                let msg = err?.message ?? "Unknown Error";
                console.warn(msg);
                return makeResponse(false, null, msg, 500);
            })
            .finally(() => {
                this._commit(TOGGLE_CALLING, false);
            })
        ;
    }


    /**
     * Call the API endpoint
     *
     * This will standardise the response from the server to the known response structure regardless of if the
     * call state.
     *
     * It will return a promise which wraps an Axios call, and will resolve or reject the promise accordingly.
     *
     * Resolved promises are mapped to any stats = true response from the server
     * Rejected promises are mapped to any status = false response from the server
     *
     * @param {string} url The exact doc item to return
     * @param {string} method The method to use for the call. GET, PUT, POST, DELETER
     * @param {object} params The parameters to send to the server
     * @returns {Promise}
     */
    call(url, method = "GET", data = {}) {
        if (method === undefined) {
            method = "GET";
        } else {
            method = method.toUpperCase();
        }

        let _params = Object.assign({}, data);

        //prepare the options for the http call
        let options = {
            method: method,
            url: url,
            maxRedirects: 0,
            withCredentials: true,
            timeout: 60000 * 3,
            headers: {
                "accept": "application/json",
                "content-type": "application/json",
            }
        };

        //do any parameter replacements in the URL
        options = Object.assign(options, updateUrlPlaceholders(url, _params, true));

        if (method === "GET") {
            options.params = options.data;
            delete(options.data);
        }

        return this.handleCall(options);
    }

    /**
     * Upload a file to the API
     *
     * @param {string} url The url to send the file to
     * @param {} file The file input object we are going to send
     * @param {function} onUploadProgress A callback function to provide the progressEvent back to the caller
     * @param {object} overrideOptions An options object that will override default options
     * @returns {Promise}
     * @see https://developer.mozilla.org/en-US/docs/Web/API/ProgressEvent
     */
    upload(url, file, onUploadProgress, overrideOptions = {}){
        let method = "POST";

        let formData = new FormData();
        formData.append("file", file);

        //prepare the options for the http call
        let options = merge({}, {
            method: method,
            url: url,
            maxRedirects: 0,
            withCredentials: true,
            timeout: 60000 * 3,
            headers: {
                "accept": "application/json",
                "content-type": "multipart/form-data",
            },
            onUploadProgress: function( progressEvent ) {
                onUploadProgress(progressEvent);
            },
            data: formData
        }, overrideOptions);

        return this.handleCall(options);
    }

    /**
     * Download a file from the server
     *
     * This will trigger a file save dialog
     *
     * @param {string} url The url to call to download the file
     * @param {string} filename The filename to give the file being downloaded
     * @param {Object} params The url params to add to the url, including replace any {placeholders}.
     * @param {boolean} withCredentials Send a credentialed request (The server must specify specify an origin in the value of the Access-Control-Allow-Origin header)
     * @return {String|void} Key of download file added or void if no store attached
     */
    download(url, filename, params = {}, withCredentials = true){

        if (!this.store) {
            window.open(this.uri(url, params), "_self");
            return;
        }

        let key = uniqueId("fnApiDownload");

        //push it to the state
        this._commit(PUSH_DOWNLOAD, {
            key,
            url,
            filename,
            params,
            withCredentials
        });

        this.processDownloads();

        return key;
    }

    async processDownloads(){
        if (this.store.state.fnApi.downloading.key !== null || this.store.state.fnApi.downloads.length === 0) {
            return;
        }

        let download = this.store.state.fnApi.downloads.shift();

        if (download !== undefined) {
            let options = {
                method: "GET",
                url: route(download.url, download.params),
                withCredentials: download.withCredentials,
                timeout: 60000 * 3,
                headers: {
                    "accept": "application/json",
                    "content-type": "application/json",
                },
                responseType: "blob",
                onDownloadProgress: (evt) => {
                    //update the progress
                    this._commit(UPDATE_DOWNLOAD_PROGRESS, {
                        ...download,
                        progress: parseInt((evt.loaded / evt.total) * 100)
                    });
                }
            };

            let response = await this.handleCall(options)
                .then(async function (response) {
                    if (response.status === 200) {
                        fileDownload(response.data, download.filename);
                    } else {
                        if (response.data.type === "application/json") {
                            response = JSON.parse(await response.data.text());
                            alert(response.error);
                            return response;
                        } else {
                            return makeResponse(false, null, "Unknown Response", 500);
                        }
                    }
                })
            ;
            this._commit(RESET_DOWNLOAD_PROGRESS);
            if (download.onComplete) download.onComplete(response);
            if (this.store.state.fnApi.downloads.length > 0) {
                this.processDownloads();
            }

        } else {
            this._commit(RESET_DOWNLOAD_PROGRESS);
        }
    }


    /**
     * Commit a mutation to the store
     *
     * @param mutation
     * @param params
     */
    _commit(mutation, params){
        if (this.store) {
            this.store.commit(apiStore(mutation), params);
        }
    }

    /**
     * Generate a URL for the given URI and replace any URI place holders found in the params
     *
     * @param {string} uri The URI to use as the base
     * @param {object} params The list of parameters to map to placeholders or append as a query string
     * @returns {string} The modified URI
     */
    uri(uri, params){
        return route(uri, params);
    }

    /**
     * Do a handle call
     *
     * Handle call are POST calls made to the server
     *
     * @param {string} uri The URI to call
     * @param {object} params The URI parameters to replace in the URI or attach as a query string
     * @param {object} data The data we need to send to the server
     * @returns {Promise}
     */
    handle(uri, params = {}, data = {}, method = "POST"){
        let url = this.uri(uri, params);
        return this.call(url, method, data);
    }

    /**
     * Do a view call
     *
     * Handle call are POST calls made to the server
     *
     * @param {string} uri The URI to call
     * @param {object} params The URI parameters to replace in the URI or attach as a query string
     * @param {object} data The data we need to send to the server
     * @returns {Promise}
     */
    view(uri, params = {}, data = {}, method = "GET"){
        let url = this.uri(uri, params);
        return this.call(url, method, data);
    }

    /**
     * Get a form from the server to display
     *
     * @param uri
     * @param params
     * @param data
     * @param method
     * @returns {*|Promise}
     */
    form(uri, params = {}, data = {}, method = "GET"){
        return this.view(uri, {...params, _action: "view"}, data, method);
    }

    /**
     * @deprecated This method is no longer used. Use uri() instead.
     */
    getViewUrl(uri, params){
        return this.uri(uri, params);
    }

    /**
     * @deprecated This method is no longer used. Use uri() instead.
     */
    getHandleUrl(uri, params){
        return this.uri(uri, params);
    }

}



export default ApiService;
