import {OKINUS_CUSTOMER_PORTAL, BREEZE_CUSTOMER_PORTAL} from "../../constants";

export default {
    methods: {
        redirectToOkinusCreditOptions() {
            const url = 'https://okinus.com/credit-options/';
            window.open(url, '_blank');
        },
        pageTitle() {
            const domain = window?.location?.hostname;
            if (domain.endsWith(OKINUS_CUSTOMER_PORTAL)) {
                return this.$config("app.titles.okinus");
            } else if (domain.endsWith(BREEZE_CUSTOMER_PORTAL)) {
                return this.$config("app.titles.breeze");
            }
            return this.$config("app.name");
        },
        phoneNumber() {
            const domain = window?.location?.hostname;
            if (domain.endsWith(OKINUS_CUSTOMER_PORTAL)) {
                return this.$config("app.phone.okinus");
            } else if (domain.endsWith(BREEZE_CUSTOMER_PORTAL)) {
                return this.$config("app.phone.breeze");
            }
            return this.$config("app.phone.okinus");
        },
        emailAdrress() {
            const domain = window?.location?.hostname;
            if (domain.endsWith(OKINUS_CUSTOMER_PORTAL)) {
                return this.$config("app.email.okinus");
            } else if (domain.endsWith(BREEZE_CUSTOMER_PORTAL)) {
                return this.$config("app.email.breeze");
            }
            return this.$config("app.email.okinus");
        },
        footerCompanyText() {
            const domain = window?.location?.hostname;
            if (domain.endsWith(OKINUS_CUSTOMER_PORTAL)) {
                return this.$config("app.footers.okinus");
            } else if (domain.endsWith(BREEZE_CUSTOMER_PORTAL)) {
                return this.$config("app.footers.breeze");
            }
            return this.$config("app.footer");
        },
        setFavicon() {            
            var favicon = "/img/favicon-okinus.png";

            try {

                const domain = window?.location?.hostname;
                
                if (domain.endsWith(BREEZE_CUSTOMER_PORTAL)) {
                    favicon = "/img/favicon-breeze.png";
                }

                let link = document.querySelector("link[rel~='icon']");

                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.head.appendChild(link);
                }
                
                link.href = favicon;

            } catch (error) {

            }

        }
    }
}
