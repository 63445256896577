import {uniqueId, findIndex, merge, isString, isEmpty} from "lodash";

/**
 * Notifications object
 */
function Notify(Vue){

    this.store = Vue.observable({
        latest: null,
        notifications: []
    });

    /**
     * Add a notification to the notifications
     *
     * @param {{title: *, component: *, props: *, on: {*}}} notification
     */
    this.push = function(notification){
        let key = uniqueId("notification");

        if (isString(notification)) {
            notification = {
                message: notification,
                type: "success"
            };
        }

        if (isEmpty(notification?.message)) {
            return;
        }

        let _notification = merge({}, notification, {
            id: key
        });
        this.store.notifications.push(_notification);
        this.store.latest = key;
    };

    /**
     * Remove a notification from the notifications
     *
     * @param id
     */
    this.pull = function(id){
        let index = findIndex(this.store.notifications, (notification) => notification.id === id);
        if (index !== -1) {
            let notification = this.store.notifications[index];
            this.store.notifications.splice(index, 1);
            return notification;
        }
        return null;
    };

}

const fnNotifyMixin = {
    computed: {
        latest_notification_key: function(){
            return this.$fnNotifications.store.latest;
        }
    },
    watch: {
        latest_notification_key: function(newVal, oldVal){
            if (newVal !== oldVal) {
                let notification = this.$fnNotifications.pull(newVal);
                if (notification) {
                    this.notify(notification);
                }
            }
        }
    },
};

export const fnResponseToNotification = (response) => {
    let message = "An unknown error occurred";
    let type = (response.status) ? "success" : "warning";
    if (response) {
        if (response.error) {
            type = "error";
            message = response.error;
        } else if(this.response.message) {
            message = response.message;
        }
    }
    return {
        message,
        type
    };
};


/**
 * FnNotifications
 *
 * (c) 2020
 */
const FnNotifyPlugin = {};

FnNotifyPlugin.install = function(Vue, options) {

    const fnNotifications = new Notify(Vue);

    Vue.prototype.$fnNotifications = fnNotifications;

    window.alert = function(message){
        fnNotifications.push({
            type: "error",
            message
        });
    };

    /**
     * Add a notification
     *
     * Adds a notification options object to the store.
     *
     * The structure of the options is from https://www.npmjs.com/package/vue-toast-notification#available-options
     *
     * @param {Object} state
     * @param {{message: {String}, type: {String}, duration: {Number}}} notification See link in description for more options
     */
    Vue.prototype.$fnNotify = function(notification) {
        fnNotifications.push(notification);
    };

    /**
     * Notify an error
     *
     * @param message
     */
    Vue.prototype.$fnNotifyError = (message) => {
        fnNotifications.push({
            message,
            type: "error"
        });
    };

    /**
     * Notify a success
     * @param message
     */
    Vue.prototype.$fnNotifySuccess = (message) => {
        fnNotifications.push({
            message,
            type: "success"
        });
    };

};

export default FnNotifyPlugin;

export {
    fnNotifyMixin
};
