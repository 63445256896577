import {REGISTER_COLUMN, UPDATE_COLUMN} from "./mutations";

export const registerColumn = "registerColumn";
export const updateColumn = "updateColumn";

export default {
    /**
     * Register layout column
     *
     * @param commit
     * @param payload
     */
    [registerColumn]({commit}, payload) {
        commit(REGISTER_COLUMN, payload);
    },

    /**
     * Update layout column
     *
     * @param commit
     * @param payload
     */
    [updateColumn]({commit}, payload) {
        commit(UPDATE_COLUMN, payload);
    },
};
