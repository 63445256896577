<template>
    <base-error>
        <div class="error-content">
            <h3 class="text-white mt-3">{{ $t("Page Not Found") }}</h3>
            <h4 class="text-secondary mt-2">{{ $t("Error code 404") }}</h4>
            <p class="text-muted mt-3">{{ $t("It's looking like you may have taken a wrong turn. Don't worry... it happens to the best of us.") }}
            </p>
        </div>
    </base-error>
</template>

<script>
import BaseError from "@/features/errors/screens/BaseError";
export default {
    name: "Error404",
    components: {
        BaseError
    }
};
</script>
