<template>
    <loader :loading="loadingBanks || loadingCards || loadingPayment" class="w-100" transparent>
        <div v-if="details">
            <div>
                <div class="row mb-2">
                    <div class="col font-18 text-primary font-weight-bold">{{ $t('Make Payment') }}</div>
                </div>

                <div class="row row-cols-2 mb-2">
                    <div class="col text-left">{{ $t('Minimum Due') }}</div>
                    <div class="col text-right">
                        <fn-format-currency :value="displayedMinimumDue"></fn-format-currency>
                    </div>
                </div>

                <div class="row row-cols-2 mb-2">
                    <div class="col text-left">{{ $t('Payoff Amount') }}</div>
                    <div class="col text-right">
                        <fn-format-currency :value="payoffAmount"></fn-format-currency>
                    </div>
                </div>

                <div class="row row-cols-2 mb-4">
                    <div class="col text-left">{{ $t('On or Before') }}</div>
                    <div class="col text-right">
                        <fn-format-date format="MM/DD/YYYY" :value="pending.pay_at" :ignore-time-zone="true"></fn-format-date>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col">
                        <label class="mb-0" for="pay_amount">{{ $t('Payment Amount') }}</label>
                        <fn-type-money-input
                            id="pay_amount"
                            :format="'$0,0.00'"
                            v-model="payAmount"
                            :minLength="initialPayAmount"
                            :maxLength="payoffAmount <= 0 ? MAX_PAYABLE_AMOUNT : payoffAmount"
                            required
                        ></fn-type-money-input>
                    </div>
                </div>

                <div class="row mb-2">
                        <div class="col" v-if="!loadingBanks && !loadingCards">
                            <label class="mb-0" for="pay_date">{{ $t('Payment Method') }}</label>
                            <payment-select
                                :cards="customerCards"
                                :banks="customerBanks"
                                :default="defaultPayment"
                                @payment-change="handlePaymentChange"
                                @add-card="handleAddCard"
                            ></payment-select>
                        </div>
                </div>

                <div class="row mb-2">
                    <div class="col">
                        <label class="mb-0" for="pay_date">{{ $t('Payment Date') }}</label>
                        <fn-type-date-input
                            id="pay_date"
                            :data="dateInput"
                            v-model="payDate"
                        ></fn-type-date-input>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col">
                        <div class="btn btn-outline-dark btn-block btn-lg px-5 d-flex justify-content-between align-items-center"
                            :class="{'disabled' : isDisabled}"
                            @click="handlePayment">
                            <span class="font-14">{{ $t('Make Payment') }}</span>
                            <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </loader>
</template>

<script>
import {FnTypeMoneyInput, FnTypeDateInput} from "@fndry-vue/fn-types";
import withContext from "@fndry-vue/fn-context/mixins/withContext";
import {withScreen} from "@fndry-vue/fn-screen";
import customerDetails from "../../dashboard/mixins/customerDetails";
import moment from "moment";
import {DASHBOARD, MAX_PAYABLE_AMOUNT} from "../../dashboard";
import PaymentSelect from "../../dashboard/components/PaymentSelect";
// import AddNewCardPanel from "../../profile/panels/AddNewCardPanel";

export default {
    name: "PayEarlyScreen",
    props: {
        details: Object,
        lease: Object,
        pending: Object,
        backRouteName: {
            type: String,
            default : DASHBOARD
        }
    },
    mixins: [
        withContext,
        withScreen,
        customerDetails,
    ],
    components: {
        FnTypeMoneyInput,
        FnTypeDateInput,
        PaymentSelect
    },
    screen() {
        return {
            class: 'details-screen',
            data: {
                withButton: false,
                noToggle: true,
                withBack: true,
                backRouteName: this.backRouteName,
                details : this.details,
            }
        }
    },
    context(){
        return false;
    },
    data() {
        return {
            payAmount: 0,
            payDate: null,
            selectedPayment_: null,
            loadingPayment: false,
            displayedMinDue: 0,
        }
    },
    created() {
        if(this.pending === undefined || this.lease === undefined) {
            this.$router.push({
                name: this.backRouteName
            })
        }
    },
    mounted() {
        this.payAmount = this.initialPayAmount;
        this.displayedMinDue = this.payAmount;
    },
    computed: {
        displayedMinimumDue() {
            return this.parseNumber(this.displayedMinDue);
        },
        minimumDue() {
            return this.parseNumber(this.pending.minDue);
        },
        initialPayAmount() {
            return this.parseNumber(this.pending?.amount? this.pending?.amount : this.pending?.amount_ach);
        },
        payoffAmount() {
            return this.parseNumber(this.lease?.extra?.balance);
        },
        dateInput() {
            return {
                required: true,
                rules: 'required',
                datetime: {
                    minDate: moment().toDate(),
                    maxDate: this.pending.pay_at ? moment(this.pending.pay_at).toDate() : null,
                }
            }
        },
        isDisabled() {
            return this.payAmount === null || this.payDate === null || this.selectedPayment === null || this.selectedPayment?.id === null;
        },
        selectedPayment: {
            get() {
                if(this.selectedPayment_) {
                    return this.selectedPayment_
                } else {
                    return this.defaultPayment;
                }
            },
            set(value) {
                this.selectedPayment_ = value;
            }
        }
    },
    methods: {
        parseNumber(value) {
            const parsedValue = parseFloat(value);
            return isNaN(parsedValue) ? 0 : parsedValue;
        },
        handlePaymentChange(payment) {
            this.selectedPayment = payment;
        },
        isPayoffAmount(amount) {
            return this.parseNumber(amount) >= this.parseNumber(this.lease.extra.balance);
        },
        handlePayment() {

            if(this.isDisabled || this.loadingPayment) {
                return;
            }
            
            this.loadingPayment = true;

            if(this.payAmount >= this.minimumDue && this.payAmount <= this.payoffAmount && this.payAmount <= MAX_PAYABLE_AMOUNT) {
                let text = this.$t("lease.pay_early.confirm", {amount: this.payAmount, date: moment(this.payDate).format('MM/DD/YYYY') })
                let description = this.$t("lease.pay_early.description");
                this.$fnModals.confirm(text + " " + description)
                    .then(value => {
                        if(value) {
                            this.$fnApi.call('/api/v1/mobile/payments', 'POST', {
                                amount : this.payAmount,
                                method_id : this.selectedPayment.id,
                                method_type : this.selectedPayment.payment_type,
                                date: this.payDate,
                                lease: this.lease.lease_uuid,
                                isPayoffAmount: this.isPayoffAmount(this.payAmount),
                            }).then(response => {
                                if(response.status) {
                                    if(response?.data?.['scheduled']) {
                                        this.$fnNotify({
                                            message: this.$t("Your payment has been scheduled"),
                                            type: "success"
                                        });
                                    }
                                    else {
                                        this.$fnNotify({
                                            message: this.$t("Your payment has been successfully processed, please allow up to 3 business days for the payment to post to your account."),
                                            type: "success"
                                        });
                                    }
                                    this.loadingPayment = false;
                                    this.$router.push({
                                        name: this.backRouteName
                                    })
                                } else {
                                    this.$fnNotify({
                                        message: this.$t("An unknown error occurred, please try again later!"),
                                        type: "error"
                                    });
                                    this.loadingPayment = false;
                                }
                            })
                        } else {
                            this.loadingPayment = false;
                        }
                    })
            } else {
                this.$fnNotify({
                    message: this.$t("lease.pay_early.warning", {'minimumDue': this.minimumDue, 'maxAmount': this.payoffAmount > 0 ? this.payoffAmount : MAX_PAYABLE_AMOUNT}),
                    type: "error"
                });
                this.loadingPayment = false;
            }
        },
        handleAddCard() {
            this.$fnPanels.open({
                component: () => import("../../profile/panels/AddNewCardPanel"),
                props: {
                    ssn: this.lease.social_security_number
                },
                on: {
                    success: () => {
                        this.selectedPayment = null;
                        this.getCustomerCards()
                    }
                }
            })
        }
    }
}
</script>
