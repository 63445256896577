import {findIndex, forEach} from "lodash";

export const ADD_MODAL = "ADD_MODAL";
export const REMOVE_MODAL = "REMOVE_MODAL";
export const UPDATE_MODAL_PROPS = "UPDATE_MODAL_PROPS";
export const UPDATE_MODAL = "UPDATE_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export default {
    [ADD_MODAL](state, {modal, id}){
        state.modals = Object.assign({}, state.modals, {[id]: modal});
    },
    [REMOVE_MODAL](state, id){
        delete state.modals[id];
        state.modals = {
            ...state.modals
        };
    },
    [CLOSE_MODAL](state, id){
        let modals = {...state.modals};

        let keys = Object.keys(modals);
        let index = findIndex(keys, (_id) => id === _id);

        if (index !== -1) {
            let close_ids = keys.slice(index);
            forEach(close_ids, (_id) => {
                modals[_id].visible = false;
            });
        }
        state.modals = {
            ...modals
        };
    },
    [UPDATE_MODAL](state, {id, modal}){
        state.modals = {
            ...state.modals,
            [id]: {
                ...state.modals[id],
                ...modal
            }
        };
    },
    [UPDATE_MODAL_PROPS](state, {id, props}){
        state.modals = {
            ...state.modals,
            [id]: {
                ...state.modals[id],
                props: {
                    ...state.modals[id].props,
                    ...props
                }
            }
        };
    }
};
