<template>
  <loader :loading="loadingLeases">
    <div class="row mb-2" v-if="currentLeases.length > 0">
      <div class="col font-18 text-primary font-weight-bold">{{ $t('Current Leases') }}</div>
    </div>
    <current-lease-item v-for="(lease, index) in currentLeases" :lease="lease"  :ssn="ssn" :key="index"></current-lease-item>
  </loader>
</template>

<script>
import CurrentLeaseItem from "@/features/payasguest/components/CurrentLeaseItem";
import {withScreen} from "@fndry-vue/fn-screen";
import LeaseDetails from "@/features/payasguest/mixins/leaseDetails";
import { AUTH_LOGIN } from "@/features/auth/screens";

export default {
  name: "CurrentLeases",
  props: {
    token: {
        type: String,
    },
    applicantId: {
        type: Number,
    },
    ssn: {
        type: String,
    },
  },
  mixins: [
    LeaseDetails,
    withScreen
  ],
  screen() {
      return {
          title: this.$t('Choose Lease'),
          data: {
              noToggle: true,
              withButton: true,
              clearWave: true
          }
      }
  },
  components: {
    CurrentLeaseItem
  },
  mounted() {
    if (this.token != null && this.applicantId != null) {
      this.getLeases(this.token, this.applicantId);
    } else {
      this.$fnNotify({message: this.$t("Invalid token or session expired!"), type: 'error'});
      this.$router.push({
          name: AUTH_LOGIN
      });
    }
  },
  methods: {
    
  }
}
</script>
