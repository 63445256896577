import { isArray, get as objGet, isEmpty } from "lodash";

/**
 * Process a full schema and its children to determine all visible properties in the schema
 *
 * @param {object} schema The full form schema
 * @param {object} model The form model data
 */
export const process = (inputs, schema, model) => {
    if (schema.input) {
        schema = inputs[schema.name];
    }
    schema.visible = isVisible(schema, model);
    if (schema.children && schema.children.length > 0) {
        for (let i = 0; i < schema.children.length; i++) {
            process(inputs, schema.children[i], model);
        }
    }
};

/**
 * Determines if a given schema has a conditional display property and processes it
 *
 * @param {{conditions: *}} schema The schema to process
 * @param {object} model The model containing the values from the form
 * @returns {boolean}
 */
export const isVisible = (schema, model) => {

    let show = true;

    if(isArray(schema.conditions) && schema.conditions.length > 0){
        let conditions = [];

        for (let i = 0; i < schema.conditions.length; i++){

            let condition = schema.conditions[i].split(":");


            if(condition && condition.length > 0){
                let ref = condition[0];
                let operator = condition[1];
                let value = condition[2];
                let values = [];

                if(value){
                    let v = value.split(",");
                    if(v && v.length > 0){
                        for (let j = 0; j < v.length; j++){
                            values.push(v[j]);
                        }
                    }
                }

                if(ref && operator && values.length > 0){

                    conditions.push({
                        ref,
                        operator,
                        values
                    });
                }
            }
            show = runConditional(conditions, model);
        }
    }

    return show;

};

/**
 * Runs the conditional checks against the model
 *
 * @param {object} conditions The list of conditions to process
 * @param {object} value The field value being checked
 * @returns {boolean}
 */
export const runConditional = (conditions, value) => {
    for(let condition of conditions){
        if (determineVisibility(condition, value)) {
            return true;
        }
    }
    return false;
};

/**
 * Determines the visibility of a given condition against the current model
 *
 * @param {string} operator The operator we are processing
 * @param {[*]} values The possible values the field can contain
 * @param {object} value The value being checked
 * @returns {boolean|any}
 */
export const determineVisibility = ({operator, values}, value) => {

    switch (operator){
        case "notEmpty":
            return (value !== null && value !== "");
        case "empty":
            return (value === null || value === "");
        case "in":
            if (values.length === 0) return false;
            let show = false;
            if(value && isArray(value)){
                for (let k = 0; k < value.length; k++){
                    if(!show){
                        show = values.includes(eval(value[k]));
                    }
                }
            } else {
                show = value ? values.includes(eval(value)): false;
            }
            return show;
        default:
            if (values.length === 0) return false;
            let _values = values[0];

            if (typeof(value) === "string") {
                value = `'${value}'`;
            }

            let _eval = `${value}${operator}${_values}`;
            if (_values === "'null'") {
                _eval = `${value}${operator}null`;
            }

            return eval(_eval);
    }
};
