<template>
    <div>
        <div class="error-screen__logo-container" :style="{backgroundImage: 'url('+wave+')'}">
            <img class="error-screen__logo" :src="logo">
        </div>
        <slot></slot>
    </div>
</template>

<script>
import Logo from "@theme/ok-customer/assets/images/New-Okinus-Logo-1-1.svg"
import wave from "@theme/ok-customer/assets/images/wave.svg"
import {withScreen} from "@fndry-vue/fn-screen";

export default {
    name: "BaseError",
    mixins: [
        withScreen,
    ],
    screen() {
        return {
            title: this.$t('Error'),
            class: "error-screen",
            data: {
                clearWave: true,
            }
        }
    },
    computed: {
        logo() {
            return Logo
        },
        wave() {
            return wave;
        }
    }
}
</script>
