<template>
  <div class="current-lease-item">
    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Merchant Name') }}</div>
      <div class="col text-right">{{ lease.store_name + ' ' }}</div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Start Date') }}</div>
      <div class="col text-right">
        <span v-if="lease.extra.RECGoodsDate">{{ formattedStartDate ?? ''}}</span>
        <span v-else>---</span>
      </div>
    </div>

    <div class="row">
      <div class="col" @click="() => handleGuestPay()">
        <div class="btn btn-outline-primary btn-block btn-lg px-5 d-flex justify-content-between align-items-center">
          <span class="font-14">{{ $t('Make Payment') }}</span>
          <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PayModal from './PayModal.vue';
import { GUEST_CARD_DETAILS } from '@/features/payasguest/screens';


export default {
  name: "CurrentLeaseItem",
  props: {
    lease: Object,
    ssn: String,
  },
  computed: {
      formattedStartDate() {
          var date = new Date(this.lease?.extra?.RECGoodsDate);
          let formattedDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();        
          return formattedDate;
      }
  },
  methods: {
    handleGuestPay() {
        this.$fnModals.open({
            component: PayModal,
            props: {
              loan_number: this.lease?.loan_number,
              lease_uuid: this.lease?.lease_uuid,
              store_name: this.lease?.store_name,
              start_date: this.lease?.extra?.RECGoodsDate,
            }
        }).then((form) => {
          this.$router.push({
                name: GUEST_CARD_DETAILS,
                params: {
                  lease_uuid: this.lease?.lease_uuid,
                  ssn: this.ssn,
                  pay_amount: form.pay_amount,
                  store_name: this.lease?.store_name,
                }
            });
        });
    },
  }
}
</script>
