<template>
    <div class="card-item" :style="cardStyle">
        <img class="card-logo" :src="cardLogo"/>
        <div class="font-22 text-white mt-2">{{ getCardNumber() }}</div>
        <div class="row text-white mt-3">
            <div class="col">
                <div class="font-10">{{ $t('Month/Year') }}</div>
                <div class="font-12">{{ getExpirationDateDetails(card) }}</div>
            </div>
            <div class="col-5">
                <div class="font-10">{{ $t('cvc2/cvv2') }}</div>
                <div class="font-12">{{ 'XXX' }}</div>
            </div>
        </div>
        <div class="row text-white mt-3">
            <div class="col-5">
                <div class="font-10">{{ getCardName() }}</div>
            </div>
            <div class="col">
                <div v-if="false && isDefaultPayment" class="font-10 text-uppercase text-secondary font-weight-bold text-right">{{ $t('Default Payment') }}</div>
                <div v-if="!isDefaultPayment && isPaylianceCard && !noActions" class="font-10 text-uppercase text-center">
                    <div v-if="false" class="pointer p-1 border rounded-sm border-primary text-primary" @click="handleMakeDefault">{{ $t('Make Default Payment') }}</div>
                    <div class="mt-1 pointer p-1 border rounded-sm border-danger text-danger" @click="handleDelete">{{ $t('Remove Payment Method') }}</div>
                </div>
                <div v-if="!isPaylianceCard && !noActions" class="font-10 text-uppercase text-center" >
                    <div class="pointer p-1 border rounded-sm border-warning text-primary" @click="handleReVerify">{{ $t('Card Requires Verification') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import cardFront from "../../../theme/assets/images/card-front.png"
import cardLogos from "../../dashboard/constants/card-images";

export default {
    name: "CardItem",
    props: {
        card: Object,
        isDefaultPayment: Boolean,
        isPaylianceCard: Boolean,
        noActions: {
            type: Boolean,
            default: false
        }

    },
    computed: {
        cardStyle() {
            return {
                backgroundImage : "url("+ cardFront +")",
            }
        },
        cardLogo() {
            let brand = String(this.card?.card?.verification?.network).toLowerCase()
            return cardLogos[brand];
        },
    },
    methods: {
        handleMakeDefault() {
            this.$emit('make-default')
        },
        handleDelete() {
            this.$fnModals.confirm(this.$t('Are you sure you want to remove this Card?')).then(value => {
                if(value) {
                    this.$emit('delete');
                }
            })
        },
        handleReVerify() {
            this.$fnModals.confirm(this.$t('You will be asked to re-enter your card details now. Proceed?')).then(value => {
                if(value) {
                    this.$emit('reverify');
                }
            })
        },
        getExpirationDateDetails(card) {
            try {
                if(card?.card?.exp_month && card?.card?.exp_year) {
                    return card.card.exp_month +'/'+ card.card.exp_year;
                } else if(card.card?.card?.expirationDate) {
                    let exp = card.card.card.expirationDate.toString()
                    return exp.substring(0,2) + '/' + exp.substring(2);
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        getCardNumber() {
            if(this.card.card?.cardNumber) {
                return this.card.card.cardNumber;
            }else if(this.card.card?.card?.cardLastFour) {
                return '**********' + this.card.card.card.cardLastFour;
            }
        },
        getCardName() {
            if(this.card.card?.name) {
                return this.card.card.name;
            } else if (this.card.card?.card?.firstName && this.card.card?.card?.lastName) {
                return this.card.card.card.firstName + ' ' + this.card.card.card.lastName;
            }
        }
    }
}
</script>
