import store_, {menuStore, namespace} from "../store";
import {ADD_ITEMS, ADD_MENU, REMOVE_ITEMS, UPDATE_ITEMS} from "../store/mutations";
import {getMenu, getMenuItems} from "../store/getters";

/**
 * Menus object
 */
export default class Menus {

    constructor(Vue, store) {

        // Install the Auth Store
        store_.install(store);
        this.store = store;
    }

    /**
     * Add a menu to the menus
     *
     * @param {{name: *, menu: *} menu
     */
    addMenu(name, menu) {
        this.store.commit(menuStore(ADD_MENU), {name, menu});
    }

    /**
     * Add menu items to an existing menu
     *
     * When adding items, the "to" key supports a vue router route definition.
     *
     * @param {String} name The reference name of the menu we are adding to
     * @param {Array: {to: String|Object, text: String, icon: String, ability: String, children: Array}} items The items to add to the menu
     */
    addItems(name, items, tag = null, clear = false){
        if (!this.store.state[namespace].menus[name]) {
            this.addMenu(name, {});
        }
        this.store.commit(menuStore(ADD_ITEMS), {name, items, tag, clear});
    }

    /**
     *
     * @param {String} name
     * @param {Array} items
     */
    updateItems(name, items) {
        if (this.store.state[namespace].menus[name]) {
            this.store.commit(menuStore(UPDATE_ITEMS), {name, items});
        }
    }

    /**
     * Remove items from a menu and optionally pass a tag to remove items with the given tag
     *
     * @param {String} name The name of the menu
     * @param {Array|String} tag The tag name or array of names. Optional.
     */
    removeItems(name, tag = null) {
        if (this.store.state[namespace].menus[name]) {
            this.store.commit(menuStore(REMOVE_ITEMS), {name, tag});
        }
    }

    /**
     * Get a menu by name
     *
     * @param name
     * @returns {undefined|*}
     */
    getMenu(name) {
        return this.store.getters[menuStore(getMenu)](name);
    }

    /**
     * Get items for a given menu name
     *
     * @param name
     * @return {*[]|*}
     */
    getMenuItems(name) {
        let items = this.store.getters[menuStore(getMenuItems)](name);
        if (items) {
            return items;
        } else {
            return [];
        }
    }

    /**
     * Add child to a menu item
     *
     * @param name
     * @param itemId
     * @param child
     */
    addItemChild(name, itemId, child) {
        const items = [...this.getMenuItems(name)];

        if (items.length > 0) {
            items.forEach((item, index) => {
                if (item.id && item.id === itemId) {
                    if (!items[index].children) {
                        items[index].children = [];
                    }

                    items[index].children.push(child);
                }
            });

            this.store.commit(menuStore(UPDATE_ITEMS), {name, items});
        }
    }
}
