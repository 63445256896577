import {findIndex, forEach} from "lodash";

export const ADD_PANEL = "ADD_PANEL";
export const REMOVE_PANEL = "REMOVE_PANEL";
export const UPDATE_PANEL_PROPS = "UPDATE_PANEL_PROPS";
export const UPDATE_PANEL = "UPDATE_PANEL";
export const CLOSE_PANEL = "CLOSE_PANEL";

export default {
    [ADD_PANEL](state, {panel, id}){
        state.panels = Object.assign({}, state.panels, {[id]: panel});
    },
    [REMOVE_PANEL](state, id){
        delete state.panels[id];
        state.panels = {
            ...state.panels
        };
    },
    [CLOSE_PANEL](state, id){
        let panels = {...state.panels};

        let keys = Object.keys(panels);
        let index = findIndex(keys, (_id) => id === _id);

        if (index !== -1) {
            let close_ids = keys.slice(index);
            forEach(close_ids, (_id) => {
                panels[_id].visible = false;
            });
        }
        state.panels = {
            ...panels
        };
    },
    [UPDATE_PANEL](state, {id, panel}){
        state.panels = {
            ...state.panels,
            [id]: {
                ...state.panels[id],
                ...panel
            }
        };
    },
    [UPDATE_PANEL_PROPS](state, {id, props}){
        state.panels = {
            ...state.panels,
            [id]: {
                ...state.panels[id],
                props: {
                    ...state.panels[id].props,
                    ...props
                }
            }
        };
    }
};
