<template>
  <b-tabs fill class="dashboard-tabs">
    <b-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :title="tab.name"
        @click="goToRoute(tab.route_name)"
        :active="isActive(tab.route_name)"
    ></b-tab>
  </b-tabs>
</template>

<script>
import {
  CURRENT_LEASES_SCREEN,
  APPROVED_LEASES_SCREEN,
  PAID_OFF_LEASES_SCREEN
} from "@/features/leases/screens";

export default {
  name: "LeasesTabs",
  data() {
    return {
      activeTab: 0,
      tabs : [
        {
          name: this.$t('Current'),
          route_name: CURRENT_LEASES_SCREEN
        },
        {
          name: this.$t('Approved'),
          route_name: APPROVED_LEASES_SCREEN
        },
        {
          name: this.$t('Paid off'),
          route_name: PAID_OFF_LEASES_SCREEN
        },
      ]
    }
  },
  methods: {
    goToRoute(route) {
      if(route) {
        this.$router.push({
          name: route
        })
      }
    },
    isActive(tab) {
      return this.$route.name === tab;
    }
  }
}
</script>
