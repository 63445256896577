import { GUEST_LAYOUT } from "../../layouts";
import screens, {
    GUEST_VERIFY_IDENTITY,
    GUEST_ENTER_OTP,
    GUEST_LEASES,
    GUEST_CARD_DETAILS,
    GUEST_PAYMENT_SUCCESS
} from "./screens";


const Plugin = {
    install: function(Vue, {router, layouts}){
        router.addRoutes([
            {
                path: "/guest",
                component: layouts[GUEST_LAYOUT],
                children: [                    
                    {
                        path: "verify-identity",
                        name: GUEST_VERIFY_IDENTITY,
                        component: screens[GUEST_VERIFY_IDENTITY],
                        props: false,
                        meta: {
                            middleware: [
                                "guest"
                            ]
                        }

                    },                   
                    {
                        path: "enter-otp",
                        name: GUEST_ENTER_OTP,
                        component: screens[GUEST_ENTER_OTP],
                        props: true,
                        meta: {
                            middleware: [
                                "guest"
                            ]
                        }

                    },                   
                    {
                        path: "leases",
                        name: GUEST_LEASES,
                        component: screens[GUEST_LEASES],
                        props: true,
                        meta: {
                            middleware: [
                                "guest"
                            ]
                        }

                    },                   
                    {
                        path: "card-details",
                        name: GUEST_CARD_DETAILS,
                        component: screens[GUEST_CARD_DETAILS],
                        props: true,
                        meta: {
                            middleware: [
                                "guest"
                            ]
                        }

                    },                 
                    {
                        path: "payment-success",
                        name: GUEST_PAYMENT_SUCCESS,
                        component: screens[GUEST_PAYMENT_SUCCESS],
                        props: true,
                        meta: {
                            middleware: [
                                "guest"
                            ]
                        }

                    },
                ]
            },
        ]);
    }
};

export default Plugin;
