export default {
    props: {
        /**
         * The ID of the element
         */
        id: {
            type: String,
            required: false
        },
        /**
         * The class name to apply to the element
         */
        className: {
            type: String,
            required: false
        },
        /**
         * Additional data object with data for the element
         */
        data: {
            type: [Array, Object],
            required: false
        },
    }
}
