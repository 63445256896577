
export const makeAuthMiddleware = (onFailure) => {
    return (params, {to, next, auth}) => {
        if (!auth.isLoggedIn() || (params && !auth.hasGuard(params))) {
            return () => {
                onFailure({to, next, auth});
            };
        }
    }
};

export const makeGuestMiddleware = (onFailure) => {
    return (params, {to, next, auth}) => {
        if (auth.isLoggedIn()) {
            return () => {
                onFailure({to, next, auth});
            };
        }
    }
};

export const makeAbilityMiddleware = (onFailure) => {
    return (params, {next, auth}) => {
        if (!auth.isLoggedIn() || !auth.hasAbility(params)) {
            return () => {
                onFailure({to, next, auth});
            };
        }
    };
}

export const makeSuperAdminMiddleware = (onFailure) => {
    return (params, {next, auth}) => {
        if (!auth.getStatus() || auth.isSuperAdmin() !== true) {
            return () => {
                onFailure({to, next, auth});
            };
        }
    };
}
