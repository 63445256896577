import {DEFAULT_LAYOUT} from "../../layouts";
import screens, {
    CHAT_SCREEN,
    FAQ_SCREEN,
    SUPPORT_SCREEN,
    TOPIC_SCREEN,
} from "./screens";

const Plugin = {
    install: function (Vue, {router, layouts}) {
        router.addRoutes([
            {
                path: "/",
                component: layouts[DEFAULT_LAYOUT],
                children: [
                    {
                        path : "support",
                        component: screens[SUPPORT_SCREEN],
                        children: [
                            {
                                path: "live-chat",
                                name: CHAT_SCREEN,
                                component: screens[CHAT_SCREEN],
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                            {
                                path: "faq",
                                name: FAQ_SCREEN,
                                component: screens[FAQ_SCREEN],
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                        ]
                    },
                    {
                        path: "faq/topic",
                        name: TOPIC_SCREEN,
                        component: screens[TOPIC_SCREEN],
                        props: true,
                        meta: {
                            middleware: [
                                "auth"
                            ]
                        },
                    }
                ]
            }
        ])
    },
    init: function(App){
        App.$fnMenus.addItems("main", [
            {
                to: {name: CHAT_SCREEN},
                text: App.$t("Support")
            }
        ]);
    }
};

export default Plugin;
