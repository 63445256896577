<template>
    <loader :loading="loading">
            <template v-slot="{loading}">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Payment Details</h5>
                        <h6 class="card-subtitle mb-2 text-muted">{{ store_name }}</h6>
                        <p class="card-text"><fn-format-currency :value="pay_amount" v-if="pay_amount"></fn-format-currency></p>
                    </div>
                </div>
                <div :id="iframeId" class="payliance-iframe"></div>
                <div class="form-group">
                    <fn-ui-button variant="outline-primary" class="btn-block" @click="createCardAndPay"
                                  :disabled="loading || submitting" :submitting="submitting"
                                  >{{ $t("Make Payment Now") }}
                    </fn-ui-button>
                    <fn-ui-button variant="outline-primary" class="btn-block" @click="handleCancel">{{ $t("Cancel") }}</fn-ui-button>
                </div>
            </template>
    </loader>
</template>

<style scoped>
  .payliance-iframe {
    height: 500px;
    margin-bottom: 20px;
  }
</style>

<script>

import payliance from "@/features/profile/mixins/payliance";
import { AUTH_LOGIN } from "../../../auth/screens";
import moment from "moment";
import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";
import {withScreen} from "@fndry-vue/fn-screen";
import { GUEST_PAYMENT_SUCCESS } from "..";

export const MAX_PAYABLE_AMOUNT = 999999.99;
export default {
    name: "CardDetails",
    props: {
        pay_amount:  {
            type: Number,
            default: 0
        },
        lease_uuid : {
            type: String,
            default: ""
        },
        ssn : {
            type: String,
            default: ""
        },
        store_name : {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            submitting : false,
            payment_method_id : "",
            redirectAfterTokenization: false,
            isGuest: true,
            card_ssn: this.ssn
        }
    },
    mixins: [
        payliance,
        withAutoGuard,
        withScreen
    ],
    created () {
        this.$on('card-saved', function (card) {
            this.payment_method_id = card.data.card_id;
            this.handlePayment();
        })
    },
    computed: {
      loading() {
          return this.submitting || this.paylianceIsProcessing;
      },
    },
    mounted() {
        if (!this.pay_amount 
            || !this.lease_uuid
            || !this.ssn 
            || !this.store_name) {
            this.$fnNotify({message: this.$t("Invalid token or session expired!"), type: 'error'});
            this.$router.push({
                name: AUTH_LOGIN
            });
        }
    },
    screen() {
        return {
            title: this.$t('Enter Card Details'),
            data: {
                noToggle: true,
                withButton: true,
                clearWave: true
            }
        }
    },
    methods: {
        createCardAndPay() {
            if(this.paylianceIsProcessing)
                return;
            this.tokenizeCard();
        },
        handlePayment() {

            if(this.isDisabled){
                return;
            }
            
            this.loadingPayment = true;

            if(this.pay_amount > 0 && this.pay_amount <= MAX_PAYABLE_AMOUNT) {
                let text = this.$t("lease.pay_early.confirm", {amount: this.pay_amount, date: moment().format('MM/DD/YYYY') })
                let description = this.$t("lease.pay_early.description");
                this.$fnModals.confirm(text + " " + description)
                    .then(value => {
                        if(value) {
                            this.$fnApi.call('/api/v1/mobile/guest/payments', 'POST', {
                                amount : this.pay_amount,
                                method_id : this.payment_method_id,
                                method_type : "card",
                                date: moment().format('MM/DD/YYYY'),
                                lease: this.lease_uuid,
                                isPayoffAmount: false,
                                ssn: this.ssn
                            }).then(response => {
                                if(response.status) {
                                    if(response?.data?.['scheduled']) {
                                        this.$fnNotify({
                                            message: this.$t("Your payment has been scheduled"),
                                            type: "success"
                                        });
                                    }
                                    else {
                                        this.$fnNotify({
                                            message: this.$t("Your payment has been successfully processed, please allow up to 3 business days for the payment to post to your account."),
                                            type: "success"
                                        });
                                    }
                                    this.loadingPayment = false;
                                    this.$router.push({
                                        name: GUEST_PAYMENT_SUCCESS,
                                        params: {
                                            amount: this.pay_amount,
                                        }
                                    })
                                } else {
                                    this.loadingPayment = false;
                                    this.$fnNotify({
                                        message: this.$t("An unknown error occurred, please try again later!"),
                                        type: "error"
                                    });
                                }
                            })
                        } else {
                            this.loadingPayment = false;
                        }
                    })
            } else {
                this.$fnNotify({
                    message: this.$t("Please enter a valid amount or log into your account to see your minimum due. You may also call Customer Service"),
                    type: "error"
                });
                this.loadingPayment = false;
            }
        },
        handleCancel() {
            this.$router.push({
                name: AUTH_LOGIN
            })
        },
        
    }
}
</script>
