<template>
    <loader :loading="loadingLeases">
        <div v-if="approvedLeases.length > 0">
            <div class="row mb-2">
                <div class="col font-18 text-primary font-weight-bold">{{ $t('You are approved for:') }}</div>
            </div>
            <approved-lease-item
                v-for="(lease, index) in approvedLeases"
                :lease="lease"
                :key="index"
            ></approved-lease-item>
        </div>
    </loader>
</template>

<script>
import ApprovedLeaseItem from "../../components/ApprovedLeaseItem";
import LeaseDetails from "../../mixins/leaseDetails";

export default {
  name: "CurrentLeasesTabScreen",
  mixins: [LeaseDetails],
  components: {
    ApprovedLeaseItem
  }
}
</script>
