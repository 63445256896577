export default {
    props: {
        /**
         * The url to submit to
         */
        uri: {
            type: String,
            require: false
        },
        /**
         * The URL params for the url action
         */
        uriParams: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        },
        /**
         * The method to use to submit the action
         */
        method: {
            type: String,
            required: false,
            default: 'POST'
        },
        /**
         * The target window to launch on action
         */
        target: {
            type: String,
            required: false
        }
    }
}
