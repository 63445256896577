<template>
    <div class="fn-app">
        <loader v-if="!loaded" :loading="true"></loader>
        <div v-else class="fn-app__view">
            <router-view></router-view>
        </div>
        <fn-panels :panels="panels" @close="onPanelClose" @cancel="onPanelsCancel"></fn-panels>
        <fn-modals :modals="modals"></fn-modals>
    </div>
</template>

<script>
import {merge} from "lodash";
import {fnNotifyMixin} from "@fndry-vue/fn-notify";
import {FnPanels, fnPanelsMixin} from "@fndry-vue/fn-panels";
import {fnModalsMixin, FnModals} from "@fndry-vue/fn-modals";
import {AUTH_CHECK} from "./features/auth/screens";
import commonMethods from "./layouts/mixins/commonMethods"

export default {
    name: "app",
    mixins: [
        fnNotifyMixin,
        fnPanelsMixin,
        fnModalsMixin,
        commonMethods
    ],
    components: {
        FnPanels,
        FnModals
    },
    computed: {
        loaded: function(){
            return this.$store.state.fnApp.loaded;
        }
    },
    methods: {
        notify(options) {
            options = merge({}, {
                duration: 5000,
                type: "default",
                position: "top-right"
            }, options);
            this.$toast.open(options);
        }
    },
    beforeCreate: function () {

        //ensure 401's are handled
        this.$fnApi.$http.interceptors.response.use((response) => {
            if (response.status === 401 || response.data.code === 401) {
                if (response.config.url.indexOf("/api/v1/mobile/login") === 0) {
                    return response;
                }
                sessionStorage.removeItem("token");
                this.$fnAuth.removeUser();
                if (this.loaded) {
                    //this.$fnNotify({type: "error", message: this.$t("You need to log in")});
                    this.$router.push({
                        name: AUTH_CHECK
                    });
                }
            }
            return response;
        });

        //ensure 403's are handled
        this.$fnApi.$http.interceptors.response.use((response) => {
            if (response.status === 403 || response.data.code === 403) {
                //this.$toasted.show(`Permission denied`);
            }
            return response;
        });

        this.$fnApi.$http.interceptors.response.use((response) => {
            if (response.data && response.data.message) {
                //todo should this be here???
                //this.$fnNotify(response.data.message);
            }
            return response;
        });

        this.$fnApi.$http.defaults.headers.common['X-Customer-Portal'] = "true";

        //load tawk.to chat widget on app start instead of loading on support page
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();(function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0]; s1.async=true; s1.src='https://embed.tawk.to/56febe823234914f134f3095/default'; s1.charset='UTF-8'; s1.setAttribute('crossorigin','*'); s0.parentNode.insertBefore(s1,s0);})();
    },
    created() {
        document.title = this.pageTitle();
        this.setFavicon();

        //hide chat widget when not yet on support page
        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_API.onLoad = function(){
            window.Tawk_API.hideWidget();
        };

        //keep chat widget below aside menu
        window.Tawk_API.customStyle = {
            zIndex : '10 !important'
        };
    },
};
</script>



