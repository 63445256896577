import { IMaskDirective } from 'vue-imask';

export default {
    props: {
        mask: {
            type: String,
            required: false,
        },
        maskFormat: {
            type: String,
            required: false,
            default: ""
        },
        maskOptions: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    directives: {
        'mask': IMaskDirective
    },
    computed: {
        maskValue: function(){
            return {
                mask :  this.mask ?? new RegExp('.*'),
                definitions: {
                    '9' : /[0-9]/
                },
                overwrite: true,
                ...this.maskOptions
            };
        },
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        }
    },
    methods: {
        handleMaskInput(e) {
            this.$emit('input', e.detail.value)
        },
    }
}
