<template>
    <div class="px-4 py-3 border-bottom border-light d-flex justify-content-between align-items-center pointer" @click="handleClick">
        <div class="mr-3 bg-primary rounded-circle p-2">
            <icon class="text-white" icon="question-circle" type="regular" size="xl"></icon>
        </div>
        <span class="font-16">{{ title }}</span>
        <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
    </div>
</template>

<script>
export default {
    name: "TopicItem",
    props: {
        title: String,
    },
    methods: {
        handleClick() {
            this.$emit('open')
        }
    }
}
</script>
