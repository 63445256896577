<template>
  <div>
    <div class="row row-cols-2 mb-2">
      <div class="col-2 text-left">{{ $t('Store') }}</div>
      <div class="col-10 text-right">{{ lease.store_name }}</div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col text-left">{{ $t('Last Payment Date') }}</div>
      <div class="col text-right">
        <fn-format-date format="MM/DD/YYYY" :value="lease.last_payment_date" :ignore-time-zone="true"></fn-format-date>
      </div>
    </div>

    <div class="row row-cols-2 mb-2">
      <div class="col-8 text-left">{{ $t('Last Payment Amount') }}</div>
      <div class="col-4 text-right">
        <fn-format-currency :value="lease.monthly_payment"></fn-format-currency>
      </div>
    </div>

    <div class="row row-cols-2 mb-3">
      <div class="col text-left">{{ $t('Total Paid') }}</div>
      <div class="col text-right">
        <fn-format-currency :value="lease?.extra?.totalPaid ?? 0"></fn-format-currency>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col" @click="goToDetails">
        <div class="btn btn-outline-dark btn-block btn-lg px-5 d-flex justify-content-between align-items-center">
          <span class="font-14">{{ $t('More Details') }}</span>
          <icon class="ml-auto" icon="long-arrow-right" size="xl"></icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {LEASE_SUMMARY_SCREEN} from "@/features/leases/screens";

export default {
  name: "PaidOffLeaseItem",
  props: {
    lease: Object
  },
  computed: {
    totalPaid() {
      return this.lease.total_payments_made * this.lease.monthly_payment
    }
  },
  methods: {
      goToDetails() {
          this.$router.push({
              name: LEASE_SUMMARY_SCREEN,
              params: {
                  id: this.lease.lease_id,
                  details: {
                      store_name: this.lease.store_name,
                      contact: this.lease.store_phone,
                      address: `${this.lease.store_street1}, ${this.lease.store_city}, ${this.lease.store_state}`,
                  },
                  lease : this.lease
              }
          })
      }
  }
}
</script>
