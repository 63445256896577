<template>
  <Bar
      :chart-data="data"
      :chart-options="chartOptions"
      chart-id="bar-chart"
      dataset-id-key="label"
      :width="600"
      :height="400"
  />
</template>

<script>
import {Bar} from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "PaymentHistoryChart",
  components: { Bar },
  props: {
    data : Object,
  },
  data() {
    return {
      chartOptions : {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              label(tooltipItem) {
                return 'Amount Paid: $' + tooltipItem.parsed.y
              }
            }
          }
        },
        scales : {
          x : {
            border: {
              display: false
            },
            grid: {
              display: false
            },
          },
          y : {
            border: {
              display: false
            },
            grid: {
              display: false
            },
            display: false
          }
        },
      }
    }
  }
}
</script>
