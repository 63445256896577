<template>
  <loader :loading="loadingLeases">
    <div class="row mb-2" v-if="currentLeases.length > 0">
      <div class="col font-18 text-primary font-weight-bold">{{ $t('Current Leases') }}</div>
    </div>
    <current-lease-item v-for="(lease, index) in currentLeases" :lease="lease" :key="index"></current-lease-item>
  </loader>
</template>

<script>
import CurrentLeaseItem from "../../components/CurrentLeaseItem";
import LeaseDetails from "../../mixins/leaseDetails";

export default {
  name: "CurrentLeasesTabScreen",
  mixins: [LeaseDetails],
  components: {
    CurrentLeaseItem
  }
}
</script>
