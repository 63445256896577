import {merge} from "lodash";

/**
 * A Panel Button for loading a panel
 *
 * This will pass on the panel component and props to $fnPanels.add and the listeners of this component to the
 * panel listeners.
 *
 * In effect, `<panel-button @success="..."></panel-button>`, will result in binding a success event from the loaded
 * component bubbling up to this button.
 */
export default {
    name: "PanelButton",
    functional: true,
    props: {

        /**
         * The panel component props to pass to the component
         */
        panel: Object,

        /**
         * The size of the button (only works with Vue Bootstrap Buttons)
         *
         * @see https://bootstrap-vue.org/docs/components/button
         */
        size: {
            type: String
        },

        /**
         * The variant of the button (only works with Vue Bootstrap Buttons)
         *
         * @see https://bootstrap-vue.org/docs/components/button
         */
        variant: {
            type: String
        },

        /**
         * Override the tag typ of the button
         */
        tag: {
            type: String,
            default: "b-button"
        },

        /**
         * The icon to use in the button
         */
        buttonIcon: {
            type: String
        },

        /**
         * The text to display in the button
         */
        buttonText: {
            type: String
        },

        /**
         * Renders a 100% width button (expands to the width of its parent container)
         */
        block: Boolean,

        /**
         * The tooltip to display
         */
        tooltip: {
            type: String
        },

        /**
         * The tooltip to display
         */
        title: {
            type: String
        }
    },
    render(createElement, context) {

        let props = {
            variant: context.props.variant,
            size: context.props.size,
            block: context.props.block,
        };

        return createElement(context.props.tag, {
            class: {"fn-panel-button":true, [context.data.staticClass]:true },
            props: {
                ...props
            },
            attrs: {
                title: context.props.tooltip || context.props.title
            },
            on: merge({}, context.listeners, {
                click: (evt) => {
                    evt.preventDefault();
                    context.parent.$fnPanels.add({
                        ...context.props.panel,
                        component: context.props.panel.component,
                        props: context.props.panel.props,
                        on: merge({}, context.listeners, context.props.panel.on)
                    });
                }
            })
        }, context.children);
    }
};

