/**
 * Add in the icons
 */
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faFlag,
    faHome,
    faEye,
    faInfoCircle,
    faTimes as faTimesLight,
    faBookAlt,
    faUsers,
    faEdit,
    faTrash,
    faQuoteRight,
    faQuoteLeft,
    faChevronRight,
    faChevronLeft,
    faPlus,
    faCog,
    faCaretDown,
    faCaretLeft,
    faArchive,
    faRedo,
    faSearch,
    faFilter,
    faEyeSlash,
    faClipboard,
    faTimesSquare,
    faExclamationSquare,
    faPlay,
    faHistory,
    faCheckSquare,
    faSquare,
    faAngleDown,
    faAngleRight,
    faAngleUp,
    faBell,
    faTags,
    faCheck,
    faUndo,
    faTypewriter,
    faPencil,
    faTasks as faTasksLight,
    faFolder,
    faFolders,
    faUser,
    faCheckDouble,
    faFileUpload,
    faShare,
    faThumbsUp,
    faSignOut,
    faFileDownload,
    faDownload,
    faUpload,
    faBuilding,
    faSignInAlt,
    faKey,
    faCompressAlt,
    faExpand,
    faCalendar as faCalendarLight,
    faExpandAlt,
    faSave,
    faGlobe,
    faEnvelope,
    faPuzzlePiece,
    faUsdCircle,
    faProjectDiagram,
    faBug,
    faFileMedical,
    faArrowLeft,
    faLineColumns,
    faPaperPlane as faPaperPlaneLight,
    faFile,
    faBars,
    faChevronDown,
    faChevronUp, faHospital,
    faPlusCircle,
    faSync as faSyncLight, faLaptopCode,
    faDoorOpen,
    faDoorClosed,
    faChevronCircleRight,
    faChevronCircleLeft,
    faLongArrowRight,
} from "@fortawesome/pro-light-svg-icons";
import {
    faHome as faHomeSolid,
    faEllipsisV,
    faPenSquare,
    faSync,
    faTimes,
    faTasks,
    faSquare as faSquareSolid,
    faArrowAltUp,
    faArrowAltDown,
    faTimes as faTimesSolid,
    faExclamation,
    faSpellCheck,
    faCheckDouble as faCheckDoubleSolid,
    faCheck as faCheckSolid,
    faStar,
    faEllipsisH,
    faAngleLeft as faAngleLeftSolid,
    faAngleDoubleLeft as faAngleDoubleLeftSolid,
    faAngleRight as faAngleRightSolid,
    faAngleDoubleRight as faAngleDoubleRightSolid,
    faExclamationTriangle,
    faBan,
    faMoneyCheckEdit,
    faChevronCircleDown,
    faCalendar as faCalendarSolid,
    faCheckCircle,
    faTimesCircle,
    faCircle,
    faDollarSign,
    faLongArrowLeft,
    faMapMarkerAlt,
    faPhone,
    faEnvelope as faEnvelopeSolid,
    faBullseyePointer,
} from "@fortawesome/pro-solid-svg-icons";

import {
    faFacebookF,
    faGoogle
} from "@fortawesome/free-brands-svg-icons";

import {
    faImage,
    faCalendar,
    faClock,
    faPaperPlane as faPaperPlaneSolid,
    faQuestionCircle,
} from "@fortawesome/free-regular-svg-icons";

library.add(
    faHome,
    faHomeSolid,
    faQuoteRight,
    faQuoteLeft,
    faEye,
    faEyeSlash,
    faFacebookF,
    faGoogle,
    faCheckSquare,
    faChevronRight,
    faChevronLeft,
    faSquare,
    faFlag,
    faPenSquare,
    faCog,
    faEdit,
    faTrash,
    faPlus,
    faSync,
    faEllipsisV,
    faTimes,
    faTasks,
    faTimesLight,
    faInfoCircle,
    faBookAlt,
    faCheck,
    faCaretDown,
    faCaretLeft,
    faArchive,
    faRedo,
    faSearch,
    faFilter,
    faUsers,
    faTimesSquare,
    faExclamationSquare,
    faPlay,
    faHistory,
    faPaperPlaneSolid,
    faAngleDown,
    faAngleRight,
    faAngleUp,
    faBell,
    faUndo,
    faShare,
    faBuilding,
    faSave,
    faGlobe,
    faPuzzlePiece,
    faUsdCircle,
    faProjectDiagram,
    faBug,
    faArrowLeft,
    faLineColumns,
    faPaperPlaneLight,
    faFile,

    faImage,
    faCalendar,
    faCalendarSolid,
    faCalendarLight,
    faClock,
    faClipboard,
    faSignOut,
    faTags,
    faTypewriter,
    faPencil,
    faTasksLight,
    faFolder,
    faUser,
    faFolders,
    faCheckDouble,
    faFileUpload,
    faThumbsUp,
    faFileDownload,
    faDownload,
    faUpload,
    faSquareSolid,
    faArrowAltDown,
    faExclamation,
    faSpellCheck,
    faArrowAltUp,
    faTimesSolid,
    faCheckDoubleSolid,
    faCheckSolid,
    faStar,
    faEllipsisH,
    faSignInAlt,
    faKey,
    faCompressAlt,
    faExpandAlt,
    faSignOut,
    faEnvelope,
    faFileMedical,
    faAngleLeftSolid,
    faAngleDoubleLeftSolid,
    faAngleRightSolid,
    faAngleDoubleRightSolid,
    faBars,
    faChevronDown,
    faChevronUp,
    faHospital,
    faExclamationTriangle,
    faPlusCircle,
    faSyncLight,
    faBan,
    faMoneyCheckEdit,
    faChevronCircleDown,
    faLaptopCode,
    faExpand,
    faDoorOpen,
    faDoorClosed,
    faChevronCircleRight,
    faChevronCircleLeft,
    faCheckCircle,
    faTimesCircle,
    faDollarSign,
    faCircle,
    faLongArrowLeft,
    faLongArrowRight,
    faMapMarkerAlt,
    faPhone,
    faEnvelopeSolid,
    faQuestionCircle,
    faBullseyePointer,
);
