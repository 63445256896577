import Error403 from "./Error403";
import Error404 from "./Error404";

export const ERROR_403 = "error/403";
export const ERROR_404 = "error/404";

export default {
    [ERROR_403]: Error403,
    [ERROR_404]: Error404
};
