<template>
    <fn-form :uri="formUri" uri-method="POST" no-autoload no-buttons v-model="form" @success="onSuccess" @fail="onFail" @cancel="handleCancel">
        <template v-slot="{loading, loaded, submitting, submit, flags, cancel}">
            <fn-form-schema>
                <fn-form-group :schema="inputs.bank"></fn-form-group>
                <fn-form-group :schema="inputs.routing"></fn-form-group>
                <fn-form-group :schema="inputs.account"></fn-form-group>
            </fn-form-schema>
            <div class="form-group">
                <fn-ui-button variant="outline-primary" class="btn-block"
                              :disabled="!flags.valid || loading || submitting" :submitting="submitting"
                              type="submit">{{ $t("Add ACH") }}
                </fn-ui-button>
                <fn-ui-button variant="outline-primary" class="btn-block" @click="cancel">{{ $t("Cancel") }}</fn-ui-button>
            </div>
        </template>
    </fn-form>
</template>

<script>

import {PAYMENT_METHODS_SCREEN} from "@/features/profile/screens/index";
import {fnResponseToNotification} from "@fndry-vue/fn-notify";

export default {
    name: "AddBankScreen",
    data() {
        return {
            formUri: '/api/v1/mobile/banks',
            inputs: {
                bank: {
                    key: "bank",
                    id: "bank",
                    name: "bank",
                    placeholder: this.$t("Bank Name"),
                    type: "text",
                    required: true,
                },
                routing: {
                    key: "routing",
                    id: "routing",
                    name: "routing",
                    placeholder: this.$t("Routing Number"),
                    type: "text",
                    required: true,
                },
                account: {
                    key: "account",
                    id: "account",
                    name: "account",
                    placeholder: this.$t("Checking Account"),
                    type: "text",
                    required: true,
                },
            },
            form: {
                bank: "",
                routing: null,
                account: "",
            }
        }
    },
    methods: {
        goToPaymentsTab() {
            this.$router.push({
                name: PAYMENT_METHODS_SCREEN
            })
        },
        onSuccess(response) {
            if(response.status) {
                this.goToPaymentsTab();
            } else if(response.error && (response.error?.bank || response.error?.routing || response.error?.account)) {
                this.onFail(response);
            } else {
                this.$fnNotify(fnResponseToNotification(response))
            }
        },
        onFail(response) {
            let error = '';
            if(response.error?.bank) {
                error = response.error.bank[0]
            } else if(response.error?.routing) {
                error = response.error.routing[0];
            } else if(response.error?.bank) {
                error = response.error.bank[0]
            }

            this.$fnNotify({
                message: error,
                type: 'error'
            })
        },
        handleCancel() {
            this.goToPaymentsTab();
        }
    }
}
</script>
