export default {
    data() {
        return {
            loadingCards: false,
            loadingBanks: false,
            customerCards : [],
            customerBanks : [],
            defaultPayment: {}
        }
    },
    methods: {
        getCustomerCards() {
            this.loadingCards = true;
            this.$fnApi.call('/api/v1/mobile/cards')
                .then((response) => {
                    if(response.status) {
                        let customer = response.data.customer;
                        this.customerCards = response.data.cards;
                        this.setDefaultPayment(customer)
                    }
                }).finally(()=>{
                    this.loadingCards = false
            })
        },
        getCustomerBanks() {
            this.loadingBanks = true;
            this.$fnApi.call('/api/v1/mobile/banks')
                .then((response) => {
                    if(response.status) {
                        let customer = response.data.customer;
                        this.customerBanks = response.data.banks;
                        this.setDefaultPayment(customer)
                    }
                }).finally(()=>{
                this.loadingBanks = false
            })
        },
        setDefaultPayment(customer) {
            if(customer) {
                let type = customer?.default_payment_type?.includes('Card')? 'card' : 'bank';
                this.defaultPayment = {
                    payment_type : type,
                    id: customer.default_payment_id
                }
                if(type === 'card') {
                    let defaultCard = this.customerCards?.length == 1 ? this.customerCards : this.customerCards?.find((item) => {
                        return customer.default_payment_id === item.id
                    })
                    this.defaultPayment.payment_details = defaultCard?.card
                }
            }
        }
    },
    mounted() {
        this.getCustomerCards();
        this.getCustomerBanks();
    }
}
