import {forEach, isEmpty, isObject, isString, values} from "lodash";

export default {
    props: {
        options: [Array, Object],
        multiple: {
            type: Boolean,
            default: false
        },
        textKey: {
            type: String,
            default: "text"
        },
        valueKey: {
            type: String,
            default: "value"
        },
        helpKey: {
            type: String
        },
        groupKey: {
            type: String,
            required: false
        },
        filter: {
            type: Function
        },
        query: {
            type: String,
            default: "search"
        }
    },
    data(){
        return {
            options_: [],
            localOptions: null,
            groups_: null,
        };
    },
    created(){
        this.setOptions();
    },
    methods: {
        setOptions(){
            this.options_ = [];
            this.groups_ = null;
            forEach(this.localOptions || this.options, (option) => {
                if (this.filter && typeof(this.filter) === "function") {
                    if (!this.filter.call(this, option)) {
                        return;
                    }
                }
                let _option = this.extractItem(option);
                if (this.groupKey && option[this.groupKey]) {
                    if (!this.groups_) {
                        this.groups_ = {};
                    }

                    if (this.groups_[option[this.groupKey]] === undefined) {
                        this.groups_[option[this.groupKey]] = {
                            label: option[this.groupKey],
                            values: []
                        };
                    }
                    this.groups_[option[this.groupKey]].values.push(_option);
                } else {
                    this.options_.push(_option);
                }
            });
            this.key++;
        },
        /**
         * Extracts the text and value from the given entity/item
         *
         * The idea here is the results/options can be an array of entities, allowing us to easily extract what we need
         *
         * @param item
         * @return {{text: *, value: *}}
         */
        extractItem(item){
            return {
                text: this.extractItemText(item, this.textKey),
                value: item[this.valueKey],
                help: item[this.helpKey] || "",
            };
        },
        extractItemText(item, key){
            if (isObject(key)) {
                let {fields, join} = key;
                let arr = [];
                forEach(fields, (key) => {
                    if (!isEmpty(item[key])) {
                        arr.push(item[key]);
                    }
                });
                return arr.join(join);
            } else if(isString(key)) {
                return item[key];
            }
        },
    },
    watch: {
        options: function(newVal, oldVal){
            if (newVal !== oldVal) {
                this.setOptions();
            }
        }
    }
}
