
export default {
    computed: {
        ui: {
            get() {
                return this.$fnAuth.getAuthUi();
            },
            set(value) {
                this.$fnAuth.setAuthUi(value);
            }
        },
        guard: {
            get() {
                return this.$fnAuth.getAuthGuard();
            },
            set(value) {
                this.$fnAuth.setAuthGuard(value);
            }
        }
    },
    created(){
        if (this.$route.query.ui) this.ui = this.$route.query.ui;
        if (this.$route.query.guard) this.guard = this.$route.query.guard;
    },
    methods: {
        getGuardUriParams(params) {
            const data = (params) ? {...params} : {};

            if (this.guard) {
                data.guard = this.guard;
            }
            if (this.ui) {
                data.ui = this.ui;
            }

            return data;
        },
        getGuardRoute(name, params) {
            const route = {
                name,
                params: (params) ? {...params} : {},
                query: {}
            };

            if (this.authGuard) {
                route.query.guard = this.authGuard;
            }

            return route;
        }
    },
    watch: {
        "$route.query.guard": {
            handler(newValue, oldVal) {
                if (newValue !== oldVal) {
                    this.guard = newValue;
                }
            }
        },
        "$route.query.ui": {
            handler(newValue, oldVal) {
                if (newValue !== oldVal) {
                    this.ui = newValue;
                }
            }
        }
    }
};
