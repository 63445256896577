<template>
    <b-tabs fill class="dashboard-tabs">
        <b-tab
            v-for="(tab, index) in tabs"
            :key="index"
            :title="tab.name"
            @click="goToRoute(tab.route_name)"
            :active="isActive(tab.route_name)"
        ></b-tab>
    </b-tabs>
</template>

<script>

import {CHAT_SCREEN, FAQ_SCREEN} from "../screens";

export default {
    name: "SupportTabs",
    data() {
        return {
            activeTab: 0,
            tabs : [
                {
                    name: this.$t('Live Chat'),
                    route_name: CHAT_SCREEN
                },
                {
                    name: this.$t('FAQ'),
                    route_name: FAQ_SCREEN
                },
            ]
        }
    },
    methods: {
        goToRoute(route) {
            if(route) {
                this.$router.push({
                    name: route
                })
            }
        },
        isActive(tab) {
            return this.$route.name === tab;
        }
    }
}
</script>
