import {DEFAULT_LAYOUT} from "../../layouts";
import screens, {
    LEASES_SCREEN,
    CURRENT_LEASES_SCREEN,
    APPROVED_LEASES_SCREEN,
    PAID_OFF_LEASES_SCREEN,

    LEASE_DETAILS_SCREEN,
    LEASE_SUMMARY_SCREEN,
    LEASE_ITEMS_SCREEN,
    LEASE_CONTRACT_TERMS_SCREEN,
    LEASE_PAYMENT_HISTORY_SCREEN,
    LEASE_SCHEDULED_PAYMENTS_SCREEN,
    LEASE_RETRIEVE,
    LEASE_VERIFY
} from "./screens";


const Plugin = {
    install: function(Vue, {router, layouts}){
        router.addRoutes([
            {
                path: "/",
                component: layouts[DEFAULT_LAYOUT],
                children: [                    
                    {
                        path: "leases/retrieve",
                        name: LEASE_RETRIEVE,
                        component: screens[LEASE_RETRIEVE],
                        props: true,
                        meta: {
                            middleware: [
                                "auth"
                            ]
                        },
                    },
                    {
                        path: "leases/verify",
                        name: LEASE_VERIFY,
                        component: screens[LEASE_VERIFY],
                        props: true,
                        meta: {
                            middleware: [
                                "auth"
                            ]
                        },
                    },
                    {
                        path: "leases",
                        component: screens[LEASES_SCREEN],
                        children: [
                            {
                                path: 'current',
                                name: CURRENT_LEASES_SCREEN,
                                component: screens[CURRENT_LEASES_SCREEN],
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                            {
                                path: 'approved',
                                name: APPROVED_LEASES_SCREEN,
                                component: screens[APPROVED_LEASES_SCREEN],
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                            {
                                path: 'paid-off',
                                name: PAID_OFF_LEASES_SCREEN,
                                component: screens[PAID_OFF_LEASES_SCREEN],
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                        ]
                    },
                    {
                        path: '/lease/:id',
                        component : screens[LEASE_DETAILS_SCREEN],
                        props: true,
                        children: [
                            {
                                path: 'summary',
                                name: LEASE_SUMMARY_SCREEN,
                                component: screens[LEASE_SUMMARY_SCREEN],
                                props: true,
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                            {
                                path: 'items',
                                name: LEASE_ITEMS_SCREEN,
                                component: screens[LEASE_ITEMS_SCREEN],
                                props: true,
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                            {
                                path: 'contract-terms',
                                name: LEASE_CONTRACT_TERMS_SCREEN,
                                component: screens[LEASE_CONTRACT_TERMS_SCREEN],
                                props: true,
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                            {
                                path: 'payment-history',
                                name: LEASE_PAYMENT_HISTORY_SCREEN,
                                component: screens[LEASE_PAYMENT_HISTORY_SCREEN],
                                props: true,
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            },
                            {
                                path: 'scheduled-payments',
                                name: LEASE_SCHEDULED_PAYMENTS_SCREEN,
                                component: screens[LEASE_SCHEDULED_PAYMENTS_SCREEN],
                                props: true,
                                meta: {
                                    middleware: [
                                        "auth"
                                    ]
                                },
                            }
                        ]
                    }
                ]
            },
        ]);
    },
    init: function(App){
        App.$fnMenus.addItems("main", [
            {
                to: {name: CURRENT_LEASES_SCREEN},
                text: App.$t("All Leases")
            }
        ]);
    }
};

export default Plugin;
