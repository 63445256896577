// GETTERS
export const getScreen = "getScreen";
export const getTitle = "getTitle";
export const getClass = "getClass";
export const getDataKey = "getDataKey";

export default {
    [getScreen](state){
        return state;
    },
    [getTitle](state){
        return state.title;
    },
    [getClass](state){
        return state.class;
    },
    [getDataKey](state){
        return (key, def = null) => {
            return state.data[key] ?? def ;
        }
    },
};
