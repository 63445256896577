import {BootstrapVue} from "bootstrap-vue";
import VueToast from "vue-toast-notification";

const VendorsPlugin = {};

VendorsPlugin.install = function(Vue, options){
    Vue.use(BootstrapVue);
    Vue.use(VueToast);
};

export default VendorsPlugin;
