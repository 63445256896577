import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export const namespace = "fnPanels";

export const panelStore = (action) => {
    return namespace + "/" + action;
};

export const store_ = {
    namespaced: true,
    state: {
        index: [],
        panels: {}
    },
    getters,
    mutations,
    actions
};

export default {
    /**
     * Install the auth store into the app store
     *
     * @param store {{registerModule: function(string,Object)}}
     */
    install: function(store){
        store.registerModule(namespace, store_);
    }
};
