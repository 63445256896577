import ApiRequest from "./components/ApiRequest";
import ApiForm from "./components/ApiForm";
import ApiButton from "./components/ApiButton";
import ApiDownloads from "./components/ApiDownloads";
import ApiService from "./services/ApiService";

const FnApiPlugin = {};

/**
 * Install the ApiService into a Vue Application
 *
 * @param {Object} Vue The Vue object
 * @param {Object} Axios The instance of Axios to use for the calls
 * @param store
 * @param config
 */
FnApiPlugin.install = function(Vue, {Axios, store, config}){

    /**
     * Fndry API Service
     *
     * @type {ApiService}
     */
    Vue.prototype.$fnApi = new ApiService(Axios, store, config);

    Vue.component("fn-api-form", ApiForm);
    Vue.component("fn-api-request", ApiRequest);
    Vue.component("fn-api-button", ApiButton);
    Vue.component("fn-api-downloads", ApiDownloads);

};

export default FnApiPlugin;

export {
    ApiRequest as FnApiRequest,
    ApiForm as FnApiForm,
    ApiButton as FnApiButton,
    ApiDownloads as FnApiDownloads
};
