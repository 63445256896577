import {merge} from "lodash";

/**
 * A Modal Button for loading a modal
 *
 * This will pass on the modal component and props to $fnModals.add and the listeners of this component to the
 * modal listeners.
 *
 * In effect, `<modal-button @success="..."></modal-button>`, will result in binding a success event from the loaded
 * component bubbling up to this button.
 */
export default {
    name: "ModalButton",
    functional: true,
    props: {

        /**
         * The modal component to use
         */
        modal: Object,

        modalProps: {
            type: Object,
            default(){
                return {};
            }
        },

        /**
         *
         */
        confirm: Boolean,

        confirmMessage: String,

        confirmProps: {
            type: Object,
            default(){
                return {};
            }
        },

        /**
         * The size of the button (only works with Vue Bootstrap Buttons)
         *
         * @see https://bootstrap-vue.org/docs/components/button
         */
        size: {
            type: String
        },

        /**
         * The variant of the button (only works with Vue Bootstrap Buttons)
         *
         * @see https://bootstrap-vue.org/docs/components/button
         */
        variant: {
            type: String
        },

        /**
         * Override the tag typ of the button
         */
        tag: {
            type: String,
            default: "b-button"
        },

        /**
         * The icon to use in the button
         */
        buttonIcon: {
            type: String
        },

        /**
         * The text to display in the button
         */
        buttonText: {
            type: String
        },

        /**
         * Renders a 100% width button (expands to the width of its parent container)
         */
        block: Boolean,

        /**
         * The tooltip to display
         */
        tooltip: {
            type: String
        }
    },
    render(createElement, context) {

        let props = {
            variant: context.props.variant,
            size: context.props.size,
            block: context.props.block,
        };

        console.log(context.props);

        return createElement(context.props.tag, {
            class: {"fn-modal-button":true, [context.data.staticClass]:true },
            props: {
                ...props
            },
            attrs: {
                title: context.props.tooltip
            },
            on: merge({}, context.listeners, {
                click: (evt) => {
                    evt.preventDefault();

                    let promise;

                    if (context.props.modal) {
                        promise = context.parent.$fnModals.open({
                            component: context.props.modal,
                            props: context.props.modalProps,
                            on: merge({}, context.listeners, context.props.modal.on)
                        });
                    } else if (context.props.confirm) {
                        promise = context.parent.$fnModals.confirm(context.props.confirmMessage, context.props.confirmProps)
                    }
                    promise.then((args) => {
                        if (context.listeners.resolve) context.listeners.resolve(args);
                    }, (args) => {
                        if (context.listeners.reject) context.listeners.reject(args);
                    });
                }
            })
        }, context.children);
    }
};

