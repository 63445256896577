<template>
    <div class="bg-container" :class="{'menu-open' : primaryColumn?.show}">
        <!-- main menu -->
        <fn-ui-layout class="primary-menu-column">
            <fn-ui-primary-menu-column :compact="false" :show="primaryColumn?.show">
                <template #header>
                    <slot name="header-content"></slot>
                </template>
                <div class="fn-ui-layout-column-body">
                    <fn-ui-layout-navigation-list
                        v-if="mainMenu.length"
                        :compact="false"
                        :items="mainMenu"
                        :menu-collapsed="false"
                    />
                </div>
                <template #footer>
                    <div class="p-3">
                        <div class="btn btn-block btn-primary apply-btn" @click="redirectToOkinusCreditOptions">{{ $t('Apply Now') }}</div>
                    </div>
                    <hr>
                    <div class="fn-ui-layout-column-body px-3 mb-3 font-12 text-muted">
                        <div class="font-weight-bold">{{ footerCompanyText() }}</div>
                        <div>{{ $t('147 W Railroad St S, Pelham, GA 31779, USA') }}</div>
                        <div>{{ $t('Phone') }}: {{ phoneNumber() }}</div>
                        <div>{{ $t('Email') }}: {{ emailAdrress() }}</div>
                        <div>{{ $t('Working Hours: Monday – Friday 9 AM – 8 PM') }}</div>
                        <div>{{ $t('Saturday – 10 AM – 8 PM Sunday 1 PM – 7 PM') }}</div>
                    </div>
                </template>
            </fn-ui-primary-menu-column>
        </fn-ui-layout>
        <div class="primary-column-overlay" v-show="primaryColumn?.show" @click="toggleLayoutColumnShow(primaryMenu)"></div>
        <fn-ui-layout>
            <div class="fn-layout__screen w-100 position-relative">
                <ok-customer-screen :title="screenTitle" v-bind="screenState.data" :class="screenClass">
                    <router-view></router-view>
                </ok-customer-screen>
            </div>
        </fn-ui-layout>
    </div>
</template>

<script>
import {
    FnUiLayout,
    FnUiLayoutRow,
    FnUiPrimaryMenuColumn,
    FnUiLayoutNavigationList,
} from "@fndry-vue/fn-ui";
import logo from "../theme/assets/images/logo.svg";
import icon from "../theme/assets/images/icon.svg";
import {fnMenuAbilityMixin} from "@fndry-vue/fn-menus";
import {fnLayoutMixin} from "@fndry-vue/fn-layout";
import OkCustomerScreen from "@theme/ok-customer/components/Screen"
import {withScreen} from "@fndry-vue/fn-screen"
import commonMethods from "./mixins/commonMethods"

export default {
    name: "BasetLayout",
    mixins: [
        fnMenuAbilityMixin,
        fnLayoutMixin,
        withScreen,
        commonMethods,
    ],
    components: {
        FnUiLayout,
        FnUiLayoutRow,
        FnUiPrimaryMenuColumn,
        FnUiLayoutNavigationList,
        OkCustomerScreen,
    },
    data() {
        return {
            primaryMenu: "primaryMenu",
            contextMenu: "contextMenu",
        };
    },
    computed: {
        icon: function(){
            return icon;
        },
        logo: function(){
            return logo;
        },
        mainMenu: function() {
            let menu = this.$fnMenus.getMenuItems("main");
            return this.getAbilityMenu(menu);
        },
        primaryColumn() {
            return this.getLayoutColumn(this.primaryMenu);
        },
    },
    beforeMount() {
        this.registerLayoutColumns();
    },
    methods: {
        registerLayoutColumns() {
            this.$fnLayout.registerColumn(this.primaryMenu, {primary: true});
        },
    },
};
</script>
