// GETTERS
export const getContextName = "getContextName";
export const getSubject = "getSubject";
export const getContextActions = "getContextActions";
export const getContextNavigation = "getContextNavigation";
export const getContext = "getContext";

export default {
    [getContext](state){
        return state;
    },
    [getContextName](state){
        return state.context;
    },
    [getSubject](state){
        return state.subject;
    }
};
