import {fnResponseToNotification} from "@fndry-vue/fn-notify";

export default {
    data() {
        return {
            validated: false,
            iframe: null,
            payliance: null,
            paylianceIsProcessing: true,
            reVerificationCard: null,
            tokenizedCard: null,
            redirectAfterTokenization: true,
            isGuest: false,
            card_ssn: null,
            instanceValidated: false,
            instanceTokenized: false,
            instanceFailed: false,
            instanceVerified: false,
        }
    },
    computed: {
        iframeId() {
            return this._uid+'_payliance-iframe'
        }
    },
    methods: {
        initPayliance() {
            var url = this.isGuest ? '/api/v1/mobile/guest/cards/payliance/iframe/config' : '/api/v1/mobile/cards/payliance/iframe/config';            

            this.$fnApi.call(url, 'GET').then(async response => {
                if (response.status) {
                    this.payliance = response.data;
                    this.payliance['ContainerId'] = this.iframeId

                    const existingScript = document.querySelector(`script[src="${this.payliance.PaylianceScriptUrl}"]`);

                    if (existingScript) {
                        existingScript.remove();
                    }

                    const script = document.createElement("script");
                    script.src = this.payliance.PaylianceScriptUrl;
                    delete this.payliance.PaylianceScriptUrl;
                    script.async = true; 
                    script.onload = () => {
                        this.iframe = new Payliance.PaymentCard.TokenizeIframe(this.payliance);

                        this.iframe.on("validate", function (data) {
                            this.validated = data.successful;
                            if (this.validated) {
                                if(!this.instanceValidated) {
                                    this.instanceValidated = true;
                                    this.iframe.submit();
                                }
                            }
                        }.bind(this));
                        this.iframe.on("tokenize", function (data) {
                            if(data.successful) {                                
                                if(!this.instanceTokenized) {
                                    this.instanceTokenized = true;
                                    this.tokenizedCard = data;
                                    this.iframe.verify(data.cardId, data.cvv, "New card addition");
                                }
                            }
                            this.paylianceIsProcessing = false;
                        }.bind(this));
                        this.iframe.on("failure", function (data) {
                            if(!data.successful) {
                                if(!this.instanceFailed) {
                                    this.instanceFailed = true;
                                        this.handleTokenizationFailure(data);
                                }
                            }
                            this.paylianceIsProcessing = false;
                        }.bind(this));
                        this.iframe.on("verify", function (data) {
                            if(data.successful) {
                                if(!this.instanceVerified) {
                                    this.instanceVerified = true;
                                    this.handleTokenizationSuccess(data);
                                }
                            } else {
                                this.$fnNotify({
                                    message: "Error occured saving card, please try again or contact support: " + data.message,
                                    type: 'error'
                                })
                            }
                        }.bind(this));

                        this.iframe.load();
                        this.paylianceIsProcessing = false;

                    };
                    document.head.appendChild(script);

                }
            });
        },
        tokenizeCard() {
            this.iframe.validate();
        },
        handleTokenizationSuccess(data) {
            this.paylianceIsProcessing = true;

            var cards_url = this.isGuest ? '/api/v1/mobile/guest/cards' : '/api/v1/mobile/cards';

            if(data.successful) {
                this.$fnApi.call(cards_url, 'POST', {
                    token : this.tokenizedCard,
                    verificationData: data,
                    reVerificationCardId: this.reVerificationCard?.id ?? null,
                    ssn: this.card_ssn
                }).then(response => {
                    if(response.status) {
                        if(this.redirectAfterTokenization) {
                            this.goToPaymentsTab();
                        } else {
                            this.$emit('card-saved', response)
                        }
                    } else {
                        this.$fnNotify(fnResponseToNotification(response))
                    }
                }).catch(response => {
                    this.$fnNotify(fnResponseToNotification(response))
                }).finally(() => {
                    this.paylianceIsProcessing = false;
                })
            }
        },
        handleTokenizationFailure(data) {
            this.paylianceIsProcessing = true;

            if (!data.successful) {
                this.$fnNotify({
                    message: "Error occured saving card, please try again or contact support.",
                    type: 'error'
                })
                this.paylianceIsProcessing = false;
            }
            this.paylianceIsProcessing = false;
        },

    },
    mounted() {
        this.initPayliance();
    }

}
