/**
 * Adds a computed property to return the modals
 */
export default {
    computed: {
        /**
         * @return {Array} The array of modals objects
         */
        modals: function(){
            return this.$store.state.fnModals.modals;
        }
    },
    methods: {
        /**
         * Close the modal by its id
         *
         * @param id
         */
        onModalClose(id){
            this.$fnModals.close(id);
        },
        /**
         * Cancel the modals entirely
         */
        onModalsCancel(){
            this.$fnModals.closeAll();
        }
    }
};
