<template>
    <loader :loading="loadingLeases || loadingHistory">
        <div v-if="paymentHistory.length > 0">
            <payment-history-item
                v-for="(payment, index) in paymentHistory"
                :key="index"
                :payment="payment"
                :lease="getLeaseFromLoan(payment['Loan Number'])"
            ></payment-history-item>
        </div>
    </loader>
</template>

<script>
import leaseDetails from "../../../leases/mixins/leaseDetails";
import {map} from "lodash";

export default {
    name: "PaymentHistoryTab",
    mixins: [leaseDetails],
    components : {
        PaymentHistoryItem : () => import ("../../components/PaymentHistoryItem")
    },
    data() {
        return {
            loadingHistory : false,
            paymentHistory : []
        }
    },
    watch: {
        allLeases() {
            if(this.allLeases.length > 0) {
                this.loadingHistory = true
                let loanNumbers = map(this.allLeases, (lease) => {
                    return lease.loan_number;
                })
                this.$fnApi.call('/api/v1/mobile/leases/histories','GET' , {loan_numbers : JSON.stringify(loanNumbers)})
                    .then((response) => {
                        if(response.status) {
                            this.paymentHistory = response.data
                        }
                    })
                    .finally(() => {
                        this.loadingHistory = false;
                    })
            }
        }
    }
}
</script>
