<template>
    <loader :loading="loadingCards || loadingBanks || loadingPending || loadingLeases">
        <div v-if="pendingPayments.length > 0">
            <pending-payment-item
                v-for="(pending, index) in pendingPayments"
                :key="index"
                :lease="getCurrentLeaseFromLoan(pending.loan_number)"
                :default-payment="defaultPayment"
                @pay-early="handlePayEarly(pending)"
            ></pending-payment-item>
        </div>
    </loader>
</template>

<script>
import customerDetails from "../../mixins/customerDetails";
import leaseDetails from "../../../leases/mixins/leaseDetails";
import PendingPaymentItem from "../../components/PendingPaymentItem";
import {CURRENT_LEASES_SCREEN} from "@/features/leases/screens"

export default {
  name: "DashboardMainTab",
  mixins: [customerDetails, leaseDetails],
  components: {
    PendingPaymentItem
  },
  data() {
    return {
        loadingPending: false,
        pendingPayments : []
    }
  },
  mounted() {
    this.$router.push({
            name: CURRENT_LEASES_SCREEN,
        });
  },
  methods: {
    getPendingPayments() {
        this.loadingPending = true
        this.$fnApi.call('/api/v1/mobile/payments/pending')
          .then((response) => {
              if(response.status) {
                  this.pendingPayments = response.data
              }
          }).finally(() => {
              this.loadingPending = false
        })
    },
    handlePayEarly(pending) {
        let lease = this.getCurrentLeaseFromLoan(pending.loan_number);

        this.$router.push({
            name: DASHBOARD_PAY_EARLY_SCREEN,
            params: {
                pending: pending,
                lease: lease,
                details: {
                    store_name: lease.store_name,
                    contact: lease.store_phone,
                    address: `${lease.store_street1}, ${lease.store_city}, ${lease.store_state}`,
                }
            }
        });
    }
  }
}
</script>
