import {set} from "lodash";
import store_, {filterStore} from "../store";
import {CREATE_FILTER, DESTROY_FILTER, UPDATE_VALUES} from "../store/mutations";
import {getActiveFilters, getFilterGroups, getFilterInputs, getFilterValues} from "../store/getters";

/**
 * Filters object
 */
export default class Filters {

    /**
     * Set up the Filters and install the filter's Store
     *
     * @param Vue
     * @param store
     */
    constructor(Vue, store) {
        store_.install(store);
        this.store = store;
    }

    /**
     * Add a filter to the filters
     *
     * @param {String} name
     * @param {{}} filters
     */
    createFilter(name, filters) {
        this.store.commit(filterStore(CREATE_FILTER), {name, filters});
    }

    /**
     * Destroy a filter
     *
     * @param {String} name
     */
    destroyFilter(name) {
        this.store.commit(filterStore(DESTROY_FILTER), {name});
    }

    /**
     * Get the filter values
     *
     * @param {String} name
     * @param {{}} values
     */
    setFilterValues(name, values){
        this.store.commit(filterStore(UPDATE_VALUES), {name, values});
    }

    /**
     * Get the filter groups
     *
     * @param {String} name
     */
    getFilterGroups(name){
        return this.store.getters[filterStore(getFilterGroups)](name);
    }

    /**
     * Get the filter inputs for a group
     *
     * @param {String} name
     * @param {null|String} group
     */
    getFilterInputs(name, group = null){
        return this.store.getters[filterStore(getFilterInputs)](name, group);
    }

    /**
     * Get the filter values
     *
     * @param {String} name
     */
    getFilterValues(name){
        return this.store.getters[filterStore(getFilterValues)](name);
    }

    /**
     * Set a filter input value
     *
     * @param {String} name The filter
     * @param {String} inputName The input name to clear
     * @param {*} inputValue The input value
     */
    setFilterInputValue(name, inputName, inputValue = undefined){
        let values = this.getFilterValues(name);
        set(values, inputName, inputValue);
        this.setFilterValues(name, values);
    }

    /**
     * Clear a value from the filter's values
     *
     * @param {String} name The filter
     * @param {String} inputName The input name to clear
     */
    clearFilterValue(name, inputName){
        this.setFilterInputValue(name, inputName, undefined);
    }

    /**
     *
     * @param name
     */
    getActiveFilters(name){
        return this.store.getters[filterStore(getActiveFilters)](name);
    }
}
