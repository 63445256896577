import FnFormatDate from "./components/FnFormatDate";
import FnFormatEmail from "./components/FnFormatEmail";
import FnFormatFileSize from "./components/FnFormatFileSize";
import FnFormatPhone from "./components/FnFormatPhone";
import FnFormatSwitch from "./components/FnFormatSwitch";
import FnFormatUser from "./components/FnFormatUser";
import FnFormatUrl from "./components/FnFormatUrl";
import FnFormatNumber from "./components/FnFormatNumber";
import FnFormatCurrency from "./components/FnFormatCurrency";

export {
    FnFormatDate,
    FnFormatEmail,
    FnFormatPhone,
    FnFormatFileSize,
    FnFormatSwitch,
    FnFormatUser,
    FnFormatUrl,
    FnFormatNumber,
    FnFormatCurrency,
};

const FnFormatPlugin = {};

FnFormatPlugin.install = function(Vue){
    Vue.component("fn-format-date", FnFormatDate);
    Vue.component("fn-format-email", FnFormatEmail);
    Vue.component("fn-format-file-size", FnFormatFileSize);
    Vue.component("fn-format-phone", FnFormatPhone);
    Vue.component("fn-format-switch", FnFormatSwitch);
    Vue.component("fn-format-user", FnFormatUser);
    Vue.component("fn-format-url", FnFormatUrl);
    Vue.component("fn-format-number", FnFormatNumber);
    Vue.component("fn-format-currency", FnFormatCurrency);
};

export default FnFormatPlugin;
