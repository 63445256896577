import {throttle} from "lodash";

export default {
    data() {
        return {
            elemWidth: 0,
            elemHeight:0,
        };
    },
    mounted() {
        const ro = new ResizeObserver(throttle(entries => {
            const entry = entries[0];
            const { width, height } = entry.contentRect;
            this.elemWidth = width;
            this.elemHeight = height;
        },1500));

        ro.observe(this.$el);

        this.$once("hook:beforeDestroy", () => {
            ro.disconnect();
        });
    },
};
