<template>
    <div>
        <div v-if="!loggedOut">
            <div>
                <h4 class="text-center text-gray-600">{{ $t("One moment please...") }}</h4>
            </div>
            <div>
                <loader :loading="true"></loader>
            </div>
        </div>
        <div v-else>
            <div>
                <h4 class="text-center text-gray-600">{{ $t("You are now logged out") }}</h4>
            </div>
            <div class="mt-3">
                <router-link class="btn btn-primary btn-block" :to="loginSystem">{{ $t("Login") }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>


import {AUTH_CHECK, AUTH_LOGIN} from "./index";
import {withScreen} from "@fndry-vue/fn-screen";

export default {
    name: "AuthLogout",
    mixins: [
        withScreen
    ],
    screen() {
        return {
            title: this.$t(''),
            data: {
                noToggle: true,
                withButton: false,
                clearWave: true
            }
        }
    },
    data(){
        return {
            loggedOut: false
        };
    },
    computed: {
        user: function () {
            return this.$fnAuth.getUser();
        },
        loginSystem() {
            return {
                name: AUTH_CHECK
            };
        },
    },
    mounted() {
        this.logout();
    },
    methods: {
        logout() {

            sessionStorage.removeItem("token");
            this.$fnAuth.removeUser();
            this.$fnApi.$http.defaults.headers.common['Authorization'] = null;

            this.$fnNotify(this.$t("You have been logged out!"));

            this.$router.push({
                name: AUTH_LOGIN,
            });

        }
    }
};
</script>
