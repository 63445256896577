<template>
    <b-tabs fill class="dashboard-tabs">
        <b-tab
            v-for="(tab, index) in tabs"
            :key="index"
            :title="tab.name"
            @click="goToRoute(tab.route_name)"
            :active="isActive(tab.route_name)"
        ></b-tab>
    </b-tabs>
</template>

<script>
import {
    PROFILE_MAIN_SCREEN,
    PAYMENT_METHODS_SCREEN,
    ADD_BANK_SCREEN,
    ADD_CARD_SCREEN,
    PROFILE_SCREEN,
    CHANGE_EMAIL_SCREEN,
    CHANGE_PASSWORD_SCREEN,
} from "@/features/profile/screens";

export default {
    name: "ProfileTabs",
    data() {
        return {
            activeTab: 0,
            tabs : [
                {
                    name: this.$t('Profile'),
                    route_name: PROFILE_MAIN_SCREEN
                },
                {
                    name: this.$t('Payment Methods'),
                    route_name: PAYMENT_METHODS_SCREEN
                },
            ]
        }
    },
    methods: {
        goToRoute(route) {
            if(route) {
                this.$router.push({
                    name: route
                })
            }
        },
        isActive(tab) {
            let activeRoute = this.$route.name;
            if(tab === PAYMENT_METHODS_SCREEN
                && (activeRoute === ADD_CARD_SCREEN || activeRoute === ADD_BANK_SCREEN)
            ) {
                return true;
            } else if(tab === PROFILE_MAIN_SCREEN
                && (activeRoute === CHANGE_EMAIL_SCREEN || activeRoute === CHANGE_PASSWORD_SCREEN)
            ) {
                return true;
            }else {
                return this.$route.name === tab;
            }

        }
    }
}
</script>
