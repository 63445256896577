<template>
  <loader :loading="loadingScheduledPayments">
    <div class="row mb-3">
      <div class="col font-18 text-primary font-weight-bold">{{ $t('Scheduled Payments') }}</div>
    </div>
    <div class="mt-3" v-if="payments.length > 0">
      <scheduled-payment-item v-for="(payment, index) in payments" :key="index" :payment="payment" :lease="lease"></scheduled-payment-item>
    </div>
    <div v-if="!loadingScheduledPayments && payments.length <= 0">
      <p>No payments are currently scheduled against your lease.</p>
    </div>
  </loader>
</template>

<script>
import ScheduledPaymentItem from "../../../dashboard/components/ScheduledPaymentItem";
import moment from "moment/moment";

export default {
    name: "LeaseScheduledPaymentsTabScreen",
    props: {
        lease : Object,
    },
    components: {
        ScheduledPaymentItem,
    },
    data() {
        return {
            loadingScheduledPayments : false,
            payments: []
        }
    },
    mounted() {
        this.getScheduledPayments();
    },
    methods: {
        getScheduledPayments() {
            this.loadingScheduledPayments = true;
            if(this.lease?.lease_uuid != null) {
              this.$fnApi.call(`/api/v1/mobile/leases/${this.lease?.lease_uuid}/scheduled-payments`)
                .then((response) => {
                  if(response.status) {
                    this.payments = response?.data;
                  }
                }).finally(()=>{
                    this.loadingScheduledPayments = false
              })
            }
        }
    }
}
</script>
