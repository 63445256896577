import Menus from "./services/menus";
import menuAbilityMixin from "./mixins/menuAbilityMixin";
import menuHoverMixin from "./mixins/menuHoverMixin";

/**
 * FnMenus
 *
 * (c) 2020
 */
const FnMenusPlugin = {};

FnMenusPlugin.install = function(Vue, {store}) {
    Vue.prototype.$fnMenus = new Menus(Vue, store);
};

export default FnMenusPlugin;

export {
    menuAbilityMixin as fnMenuAbilityMixin,
    menuHoverMixin as fnMenuHoverMixin
};
