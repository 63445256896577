<template>
  <div class="d-flex pb-1 mb-2 pointer" @click="goToPaymentDetails">
    <fn-ui-icon-layers style="width: 50px" class="align-self-center">
      <icon icon="circle" type="solid" style="color:#DADEE3;opacity: 0.15" size="3x"></icon>
      <icon icon="dollar-sign" type="solid" class="text-primary"></icon>
    </fn-ui-icon-layers>
    <div class="flex-grow-1 ml-1">
      <div class="font-14">{{ lease.store_name + ' ' +$t('Payment') }}</div>
      <div class="font-12">{{ lease.store_name }}</div>
      <div class="font-10">{{ payment['Loan Number'] }}</div>
    </div>

    <div class="text-right">
      <div class="text-primary">
        <fn-format-currency :value="payment.TotalPymtLease"></fn-format-currency>
      </div>
      <div class="font-10 text-muted">
        <fn-format-date format="MM/DD/YYYY" :value="payment.dateofpaymt" :ignore-time-zone="true"></fn-format-date>
      </div>
    </div>
  </div>
</template>

<script>
import {FnUiIconLayers} from "@fndry-vue/fn-ui";
import {DASHBOARD_PAYMENT_DETAILS_SCREEN} from "@/features/dashboard/screens";
import {LEASE_PAYMENT_HISTORY_SCREEN} from "@/features/leases/screens";

export default {
  name: "PaymentHistoryItem",
  props: {
    payment: Object,
    lease : Object,
  },
  components: {
    FnUiIconLayers
  },
  methods: {
    goToPaymentDetails() {
      this.$router.push({
        name: DASHBOARD_PAYMENT_DETAILS_SCREEN,
        params: {
            details: {
                store_name: this.lease.store_name,
                contact: this.lease.store_phone,
                address: `${this.lease.store_street1}, ${this.lease.store_city}, ${this.lease.store_state}`,
            },
            lease: this.lease,
            payment: this.payment,
            backRoute: {
                name: LEASE_PAYMENT_HISTORY_SCREEN,
                params: {
                    id : this.lease.lease_id,
                    details: {
                        store_name: this.lease.store_name,
                        contact: this.lease.store_phone,
                        address: `${this.lease.store_street1}, ${this.lease.store_city}, ${this.lease.store_state}`,
                    },
                    lease: this.lease,
                }
            }
        }
      })
    }
  }
}
</script>
