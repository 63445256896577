<template>
    <div class="bank-item font-weight-medium" :style="bankStyle">
        <div class="font-22 text-dark mt-4">{{ bank.bank }}</div>
        <div class="row text-dark mt-3">
            <div class="col">
                <div class="text-white font-10">{{ $t('Full Name') }}</div>
                <div class="font-12">{{ bank.owner }}</div>
            </div>
            <div class="col text-right">
                <div class="text-white font-10">{{ $t('Routing Number') }}</div>
                <div class="font-12">{{ bank.routing }}</div>
            </div>
        </div>
        <div class="row text-dark mt-3">
            <div class="col-5">
                <div class="text-white font-10">{{ $t('Checking Account') }}</div>
                <div class="font-10 text-uppercase">{{ bank.account }}</div>
            </div>
            <div class="col">
                <div v-if="isDefaultPayment" class="font-10 text-uppercase text-secondary font-weight-bold text-right">{{ $t('Default Payment') }}</div>
                <div v-else-if="!noActions" class="font-10 text-uppercase text-center">
                    <div class="pointer p-1 border rounded-sm border-primary text-primary" @click="handleMakeDefault">{{ $t('Make Default Payment') }}</div>
                    <div class="mt-1 pointer p-1 border rounded-sm border-danger text-danger" @click="handleDelete">{{ $t('Remove Payment Method') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bankFront from "../../../theme/assets/images/bank-front.png"

export default {
    name: "BankItem",
    props: {
        bank: Object,
        isDefaultPayment: Boolean,
        noActions: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        bankStyle() {
            return {
                backgroundImage : "url("+ bankFront +")",
            }
        },
    },
    methods: {
        handleMakeDefault() {
            this.$emit('make-default')
        },
        handleDelete() {
            this.$fnModals.confirm(this.$t('Are you sure you want to remove this ACH?')).then(value => {
                if(value) {
                    this.$emit('delete');
                }
            })
        }
    }
}
</script>
