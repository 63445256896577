import {$_fnSchemaStore} from "../components/common";

/**
 * withFormInput mixin can be added to any input type component and will automatically inject any ancestor schemaStore
 * or default to using either a local model_ property or use a value if supplied.
 *
 * This mixin will always emit and "updated" event when it changes
 */
export default {
    inject: {
        [$_fnSchemaStore]: {
            name: $_fnSchemaStore,
            default: null
        }
    },
    props: {
        /**
         * The schema of the input
         */
        schema: {
            type: Object,
            required: true
        },
        /**
         * Custom value for the input
         */
        value: {
            required: false
        },
        /**
         * The values available in the form
         */
        values: {
            type: Object,
            require: false,
            default(){
                return (this[$_fnSchemaStore]) ? this[$_fnSchemaStore].state.model : {};
            }
        },
    },
    data(){
        return {
            model_: null
        };
    },
    computed: {
        model: {
            get(){
                if (this.value !== undefined) {
                    return this.value;
                } else if (this[$_fnSchemaStore]) {
                    return this[$_fnSchemaStore].getValue(this.schema.name);
                } else {
                    return this.model_;
                }
            },
            set(value){
                if (this[$_fnSchemaStore]) {
                    this[$_fnSchemaStore].setValue(this.schema.name, value);
                } else {
                    this.model_ = value;
                }
                this.$emit("input", value);
            }
        }
    }
};
