<template>
    <loader :loading="loading">
            <template v-slot="{loading}">
                <div class="card" v-if="isReverification" >
                    <div class="card-body">
                        <h5 class="card-title">Reverifying Your Card</h5>
                        <h6 class="card-subtitle mb-2 text-muted">You need to re-enter details for card:</h6>
                        <p class="card-text">**** **** **** {{ reVerificationCard?.card?.last4 }}</p>
                    </div>
                </div>
                <div :id="iframeId" class="payliance-iframe"></div>
                <div class="form-group">
                    <fn-ui-button variant="outline-primary" class="btn-block" @click="tokenizeCard"
                                  :disabled="loading || submitting" :submitting="submitting"
                                  >{{ $t("Add New Credit Card") }}
                    </fn-ui-button>
                    <fn-ui-button variant="outline-primary" class="btn-block" @click="handleCancel">{{ $t("Cancel") }}</fn-ui-button>
                </div>
            </template>
    </loader>
</template>

<style scoped>
  .payliance-iframe {
    height: 500px;
  }
</style>

<script>
import payliance from "../mixins/payliance";
import {PAYMENT_METHODS_SCREEN} from "../screens/index";

export default {
    name: "AddCardScreen",
    props: {
        isPanel : {
            type: Boolean,
            default: false
        },
        ssn: {
            type: String,
            default: null
        }
    },
    mixins: [payliance],
    data() {
        return {
            submitting : false,
        }
    },
    computed: {
      loading() {
          return this.submitting || this.paylianceIsProcessing;
      },
      isReverification() {
        return this.reVerificationCard != null
      }
    },
    mounted(){
        this.card_ssn = this.ssn;
        this.setupReverification();
    },
    methods: {
        goToPaymentsTab() {
           if(this.isPanel) {
               this.$emit('success')
           } else {
               this.$router.push({
                   name: PAYMENT_METHODS_SCREEN
               })
           }
        },
        handleCancel() {
            if(this.isPanel) {
                this.$emit('cancel')
            } else {
                this.goToPaymentsTab();
            }
        },
        setupReverification() {
            this.reVerificationCard = this.$route.params?.old_card;
        }
    }
}
</script>
