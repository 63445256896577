export default {
    /**
     * Check the current component for any context option and call it to set the context
     */
    mounted: function() {
        if (this.$options.context && typeof this.$options.context === "function") {
            this.setContext(this.$options.context.call(this));
        }
    },
    updated: function() {
        if (this.$options.context && typeof this.$options.context === "function") {
            this.setContext(this.$options.context.call(this));
        }
    },
    destroyed: function(){
        /**
         * Check if the component set a context and reset the context
         */
        if (this.$options.context && typeof this.$options.context === "function") {
            this.$fnContext.reset();
        }
    },
    methods: {
        /**
         *
         * @param {null|{title: String, context: String, subject: mixed}}
         */
        setContext(ctx) {
            const callSetContext = (ctx) => {
                if (ctx) {
                    this.$fnContext.set(ctx.title, ctx.context, ctx.subject, ctx.actions, ctx.navigation);
                } else if (ctx === false) {
                    this.$fnContext.reset();
                }
            };
            if (ctx instanceof Promise) {
                ctx.then(callSetContext);
            } else {
                callSetContext(ctx);
            }
        }
    }
};
