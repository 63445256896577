import { render, staticRenderFns } from "./ApiDownloads.vue?vue&type=template&id=5704e79b&"
import script from "./ApiDownloads.vue?vue&type=script&lang=js&"
export * from "./ApiDownloads.vue?vue&type=script&lang=js&"
import style0 from "./ApiDownloads.vue?vue&type=style&index=0&id=5704e79b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports