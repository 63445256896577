import Panel from "./Panel";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Panels
 *
 * This is responsible for containing and rendering the panels being loaded in the panels registry.
 *
 * As a panel is added with $fnPanels.add(), $fnPanels.store.panels is updated with that added panel. This then
 * drips down into this component as a computed property and will then cause the change in the panels being displayed.
 *
 */
export default {
    name: "Panels",
    components: {
        Panel
    },
    computed: {
        /**
         * @return {Array} The array of panels objects
         */
        index: function(){
            return this.$store.state.fnPanels.index;
        },
        /**
         * @return {Array} The array of panels objects
         */
        panels: function(){
            return this.$store.state.fnPanels.panels;
        },
        /**
         * @return {number}
         */
        count: function(){
            return Object.keys(this.panels).length;
        },
        /**
         *
         * @return {boolean}
         */
        overlay: function(){
            return Object.keys(this.panels).length > 0;
        }
    },
    methods: {
        closePanel(id) {
            this.$emit("close", id);
        },
    },
    render(createElement) {
        let self = this;

        let panels = [];
        let overlays = [];

        Object.keys(this.panels).forEach((id, index) => {
            let overlay;
            let panel = this.panels[id];
            if (panel) {
                panels.push(createElement(Panel, {
                    key: id,
                    props: {
                        offset: index,
                        id: id
                    },
                    on: {
                        close() {
                            self.closePanel(id);
                        },
                    }
                }));

                if(panel.overlay) {
                    overlays.push(createElement("div", {
                        attrs: {
                            id: `${id}-overlay`
                        },
                        class: ["overlay", "overlay--offset-" + index, {"restrict": panel.overlay === "restrict"  }],
                        on: {
                            click: function(){
                                if (panel.overlay !== "restrict") {
                                    self.closePanel(id, overlay);
                                }
                            }
                        },
                    }));
                }
            }

        });

        return createElement("div", {
            class: [
                {
                    "fn-panels": true,
                    "fn-panels--with-panels": this.count > 0,
                    "fn-panels--without-panels": this.count == 0
                },
                "fn-panels--panels-" + this.count
            ],
        }, [
                createElement("div",
                    {
                        class:"panels-container"
                    },
                    panels
                ),
                overlays
            ]
        );
    }
};
