<template>
    <div class="row">
        <img class="col-8 offset-2 col-lg-4 offset-lg-4" :src="logo" alt="">
        <div class="col-12 d-flex flex-column justify-content-center bg-primary text-white p-5">
            <h4 class="pt-2">{{ $t('We\'d Love To Hear From You!') }}</h4>
            <p class="font-11 pt-2">{{ $t('Our daily goal is to make your Okinus experience the best that it can be with friendly representatives, and fast, flexible lease purchase programs. We enjoy hearing from you, whether you\'re a new or existing customer or part of our certified retailer network. So if you have any comments, questions or need assistance now, simply select a contact method below.') }}</p>
            <hr class="w-100 border-secondary py-2">
            <ul class="font-11 fa-ul">
                <li class="mt-2 pl-1">
                    <span class="fa-li"><icon class="text-secondary" icon="map-marker-alt" type="solid"></icon></span>{{ $t('147 W Railroad St S, Pelham, GA 31779, United States') }}</li>
                <li class="mt-2 pl-1">
                    <span class="fa-li"><icon class="text-secondary" icon="phone" type="solid"></icon></span>{{ $t('General') }}: <fn-format-phone class="info-links" format="none" value="800-472-1334"></fn-format-phone></li>
                <li class="mt-2 pl-1">
                    <span class="fa-li"><icon class="text-secondary" icon="phone" type="solid"></icon></span>{{ $t('After Hours') }}: <fn-format-phone class="info-links" format="none" value="229-405-6656"></fn-format-phone></li>
                <li class="mt-2 pl-1">
                    <span class="fa-li"><icon class="text-secondary" icon="envelope" type="solid"></icon></span><fn-format-email class="info-links" value="information@okinus.com"></fn-format-email></li>
                <li class="mt-2 pl-1">
                    <span class="fa-li"><icon class="text-secondary" icon="clock" type="regular"></icon></span>{{ $t('Monday - Friday 9 AM - 8 PM Saturday - 10 AM - 8 PM Sunday 1 PM - 7 PM') }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import LogoBrand from "../../../../theme/assets/images/logo-brand.png"
export default {
    name: "ChatScreen",
    computed: {
        logo() {
            return LogoBrand;
        }
    },
    mounted() {
        if(window.Tawk_API) {
            window.Tawk_API.showWidget();
        }
    },
    beforeDestroy() {
        if(window.Tawk_API) {
            window.Tawk_API.hideWidget();
        }
    }
}
</script>

<style scoped>
    .info-links {
        color: #fff;
    }
</style>
