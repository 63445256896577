import DefaultLayout from "./DefaultLayout";
import ErrorLayout from "./ErrorLayout";

import {DEFAULT_LAYOUT, ERROR_LAYOUT, AUTH_LAYOUT} from "@fndry-vue/fn-layout";

export {
    DEFAULT_LAYOUT,
    ERROR_LAYOUT,
    AUTH_LAYOUT
};

export default {
    [DEFAULT_LAYOUT]: DefaultLayout,
    [AUTH_LAYOUT]: DefaultLayout,
    [ERROR_LAYOUT]: ErrorLayout,
}
