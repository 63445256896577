<template>
    <div v-if="lease">
        <div class="row mb-3">
            <div class="col font-18 text-primary font-weight-bold">{{ $t('Summmary') }}</div>
        </div>

        <div class="row mb-2" v-if="!isLeaseClosed">
            <div class="col font-14 text-primary font-weight-bold">{{ $t('Installment Summary') }}</div>
        </div>

        <div class="row row-cols-2 mb-2" v-if="!isLeaseClosed">
            <div class="col text-left">{{ $t('Minimum Due') }}</div>
            <div class="col text-right">
                <fn-format-currency :value="lease.extra.MinDue"></fn-format-currency>
            </div>
        </div>

        <div class="row row-cols-2 mb-2" v-if="!isLeaseClosed">
            <div class="col-8 text-left">{{ $t('Next Payment Due Date') }}</div>
            <div class="col-4 text-right">
                <fn-format-date format="MM/DD/YYYY" :value="lease.extra.nextDueDate" :ignore-time-zone="true"></fn-format-date>
            </div>
        </div>

        <div class="row row-cols-2 mb-2" v-if="!isLeaseClosed">
            <div class="col-8 text-left">{{ $t('Number of Installments') }}</div>
            <div class="col-4 text-right">{{ totalNumberOfPayments }}</div>
        </div>

        <div class="row mb-2">
            <div class="col font-14 text-primary font-weight-bold">{{ $t('Payment Summary') }}</div>
        </div>

        <div class="row row-cols-2 mb-2">
            <div class="col text-left">{{ $t('Total Paid') }}</div>
            <div class="col text-right">
                <fn-format-currency :value="lease?.extra?.totalPaid ?? 0"></fn-format-currency>
            </div>
        </div>

        <div class="row row-cols-2 mb-2" v-if="!isLeaseClosed">
            <div class="col text-left">{{ $t('Payments Made') }}</div>
            <div class="col text-right">{{ lease.extra.NumberPaymentsMade }} of {{ totalNumberOfPayments }}</div>
        </div>

        <div class="row row-cols-2 mb-2" v-if="!isLeaseClosed">
            <div class="col text-left">{{ $t('Last Payment Date') }}</div>
            <div class="col text-right">
                <fn-format-date format="MM/DD/YYYY" :value="lease.extra.lastPaymentdate" :ignore-time-zone="true"></fn-format-date>
            </div>
        </div>

        <div class="row row-cols-2 mb-2" v-if="!isLeaseClosed">
            <div class="col text-left">{{ $t('Late Fees') }}</div>
            <div class="col text-right">
                <fn-format-currency :value="lease.extra.LateFee"></fn-format-currency>
            </div>
        </div>

        <div class="row mb-2" v-if="!isLeaseClosed">
            <div class="col font-14 text-primary font-weight-bold">{{ $t('Payoff Summary') }}</div>
        </div>

        <div class="row row-cols-2 mb-2" v-if="!isLeaseClosed">
            <div class="col text-left">{{ $t('Payoff Amount') }}</div>
            <div class="col text-right">
                <fn-format-currency :value="lease.extra.balance"></fn-format-currency>
            </div>
        </div>

        <div class="row row-cols-2 mb-2" v-if="!isLeaseClosed">
            <div class="col text-left">{{ $t('Payoff Due Date') }}</div>
            <div class="col text-right">
                <fn-format-date format="MM/DD/YYYY" :value="lease.extra.PayoffGoodThruDate" :ignore-time-zone="true"></fn-format-date>
            </div>
        </div>

        <div class="row" v-if="!isLeaseClosed">
            <div class="col text-center small text-light">{{ $t('*Take this offer and visit your specified retailer to choose your merchandise') }}</div>
        </div>

    </div>
</template>

<script>

export default {
  name: "LeaseSummaryTabScreen",
    props: {
        lease : Object,
    },
    computed: {
        totalNumberOfPayments() {
            return parseInt(this.lease?.extra?.NumberPaymentsMade) + parseInt(this.lease?.extra?.PaymentsRemaining);
        },
        isLeaseClosed() {
            return false;
        }
    },
}
</script>
