export const REGISTER_COLUMN = "REGISTER_COLUMN";
export const UPDATE_COLUMN = "UPDATE_COLUMN";

export default {
    [REGISTER_COLUMN](state, payload) {
        if (!state.columns.find(column => column.name === payload.name)) {
            state.columns.push({
                compact: false,
                show: false,
                primary: false,
                ...payload
            });
        }
    },
    [UPDATE_COLUMN](state, payload) {
        state.columns = state.columns.map(column => {
            if (column.name === payload.name) {
                return {
                    ...column,
                    ...payload
                };
            }

            return column;
        });
    },
};
