/**
 * Modal
 *
 * This component is a functional component for the modal that is being loaded
 */

export default {
    name: "Modal",
    props: {
        id: {
            type: String,
            required: true
        }
    },
    computed: {
        /**
         * The modal details
         *
         * @see ModalRegistry.open()
         */
        modal: function(){
            return this.$store.state.fnModals.modals[this.id];
        }
    },
    render(createElement) {

        let on = {
            hidden: this.$listeners.hidden
        };

        if (this.modal.on) {
            Object.keys(this.modal.on).forEach((key) => {
                on[key] = (...params) => {
                    this.modal.on[key](...params);
                    if (key === "hidden") {
                        this.$listeners.hidden();
                    }
                };
            });
        }

        return createElement(this.modal.component, {
            class: "fn-modal",
            props: {
                ...this.modal.props,
                id: this.id
            },
            on
        });
    }
};

