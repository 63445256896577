import {merge} from "lodash";
import {ValidationObserver} from "vee-validate";

/**
 * A component for doing calls to a URI and pass the results with other properties and functions to the
 * view.
 *
 * This component can wrap any other components where you are wanting to fetch data from the server for rendering
 * such as browsing a list of data or reading a specific record from the server.
 *
 * @displayName FnRequest
 */
export default {
    name: "ApiForm",
    props: {
        /**
         * The URI to fetch results from
         */
        uri: {
            type: String,
            required: true
        },
        /**
         * The URI Parameters to send to the server
         */
        uriParams: {
            type: Object,
            default(){
                return {};
            }
        },
        /**
         * The HTTP method to use when calling the URI
         */
        uriMethod: {
            type: String,
            default: "GET"
        },
        /**
         * The data to send to the server on submitting
         */
        values: {
            type: Object,
            required: false
        },
        /**
         * Disable the use of notifications
         */
        noNotify: {
            type: Boolean,
            default: false
        }
    },
    data: function(){
        return {
            /**
             * The loading state of the component
             *
             * @type {boolean}
             */
            loading: false,

            /**
             * If the component has loaded the response from the server
             *
             * @type {boolean}
             */
            loaded: false,

            /**
             * The response received from the URI
             *
             * @type {Object|undefined}
             */
            response: undefined,


            flags: {
                dirty: null,
                validated: null,
                valid: null
            }
        };
    },
    computed: {
        /**
         * The success of the call to the server
         *
         * @type {null|boolean} Valid values are null
         */
        success: function(){
            return this.response?.status;
        },
        responseVariant: function() {
            if (this.response) {
                return (this.response.status) ? "success" : "warning";
            }
            return undefined;
        },
        responseMessage: function(){
            if (this.response) {
                if (this.response.error) {
                    return this.response.error;
                } else if(this.response.message) {
                    return this.response.message;
                } else if(!this.response.status) {
                    return "An unknown error occurred";
                }
            }
            return undefined;
        },
        state: function(){
            let { dirty, validated, valid } = this.flags;
            return dirty || validated ? valid : null;
        }
    },
    methods:{
        /**
         * Fetch results from the given uri
         */
        async submit(){
            let valid = true;
            if (this.$refs.observer) {
                valid = await this.$refs.observer.validate();
            }

            let data = this.values;

            if (!valid) {
                return;
            }

            if (this.uri == null) {
                return;
            }
            this.loading = true;
            this.response = undefined;
            this.$fnApi.call(this.uri, this.uriMethod, merge({}, this.uriParams, data))
                .then((response) => {
                    this.setResponse(response);
                })
                .finally(() => {
                    this.loaded = true;
                    this.loading = false;
                })
            ;
        },
        reset(){
            this.loading = false;
            this.loaded = false;
            this.response = undefined;
            this.success = null;
        },
        setResponse(response) {
            this.response = response;
            if (response.status) {
                this.$emit("success", response);
            } else {
                this.$refs.observer.setErrors(response.data);
                this.$emit("fail", response);
            }
        },
    },
    mounted() {
        this.$watch(
            () => {
                return this.$refs.observer.flags;
            },
            (val) => {
                this.flags = val;
            }
        );
    },
    watch: {
        responseMessage: function(newVal){
            if (newVal && !this.noNotify && this.$fnNotify) {
                this.$fnNotify({message: newVal, type: this.responseVariant});
            }
        }
    },
    render(createElement) {

        let form = createElement("form", {
            on: {
                submit: (evt) => {
                    evt.preventDefault();
                    this.submit();
                }
            }
        }, [
            /**
             @slot The default slot
             @binding {boolean} loading The current loading state
             @binding {boolean} loaded If we have loaded a response from the server
             @binding {Object} response The response returned from the server
             @binding {Object} params The current active params
             */
            this.$scopedSlots.default({
                loading: this.loading,
                loaded: this.loaded,
                success: this.success,

                response: this.response,
                responseMessage: this.responseMessage,
                responseVariant: this.responseVariant,

                submit: this.submit,
                reset: this.reset,
                flags: this.flags,
                state: this.state
            })
        ]);

        return createElement(ValidationObserver, {
            props: {
                slim: true
            },
            ref: "observer"
        }, [form]);
    },
};
