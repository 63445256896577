export default {
    props: {
        noFooter: {
            type: Boolean
        },
        noHeader: {
            type: Boolean
        },
        noCardImage: {
            type: Boolean
        },
        noHeaderButtons: {
            type: Boolean
        },
        isRelation: {
            type: Boolean
        },
        title: String
    },
    methods: {
        onUpdate(data) {
            this.$emit("update", data);
        }
    }
}
