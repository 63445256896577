import FnUiPlugin from "@fndry-vue/fn-ui";

const UiPlugin = {};

UiPlugin.install = function(Vue, {fontType}){

    Vue.use(FnUiPlugin, {
        fontType
    });

};

export default UiPlugin;

