export default {
    props: {
        /**
         * The ID of the element
         */
        values: {
            type: Object,
            required: false
        }
    }
}
