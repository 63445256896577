<template>
    <div>
        <div>
            <h4 class="text-center text-gray-600">{{$t("Reset Password")}}</h4>
        </div>
        <div>
            <p class="lead text-center text-gray-600">{{$t("Enter your new password below.")}}</p>
            <form @submit.prevent="onSubmit">
                <fn-form-schema ref="formSchema" :value="model" @input="onUpdate" no-inject @validated="onValidated">
                    <fn-form-group :schema="schema.password"></fn-form-group>
                    <fn-form-group :schema="schema.password_confirmation"></fn-form-group>
                    <div class="form-group">
                        <fn-ui-button variant="primary" class="btn-block btn-rounded" :disabled="flags.invalid || loading || submitting" :submitting="submitting" type="submit">{{$t("Reset Password")}}</fn-ui-button>
                    </div>
                    <div class="mt-4">
                        <p class="text-center small">{{$t("Need to resend instructions?")}} <router-link :to="forgotRoute">{{$t("Restart")}}</router-link></p>
                        <p class="text-center small mb-0">{{$t("Remembered your password?")}} <router-link  :to="loginRoute">{{$t("Log In")}}</router-link></p>
                    </div>
                </fn-form-schema>
            </form>
        </div>
    </div>
</template>

<script>
    import {AUTH_FORGOT, AUTH_LOGIN} from "./index";
    import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";
    import {withScreen} from "@fndry-vue/fn-screen";

    export default {
        mixins: [
            withAutoGuard,
            withScreen
        ],
        screen() {
            return {
                title: this.$t(''),
                data: {
                    noToggle: true,
                    withButton: false,
                    clearWave: true
                }
            }
        },
        data() {
            return {
                loading: false,
                submitting: false,
                flags: {
                    invalid: false,
                },
                schema: {
                    password: {
                        nameKey: "id_password",
                        id: "id_password",
                        name: "password",
                        label: this.$t("Password"),
                        type: "password",
                        required: true,
                        rules: 'required|regex:/^(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,}$/i'
                    },
                    password_confirmation: {
                        id: "id_password_confirmation",
                        name: "password_confirmation",
                        label: this.$t("Confirm Password"),
                        type: "password",
                        required: true,
                        rules: 'required|confirmed:password'
                    }
                },
                model: {
                    token: this.$route.query?.token ?? '',
                    email: this.$route.query?.email ?? '',
                    password: "",
                    password_confirmation: ""
                }
            };
        },
        computed: {
            formUri() {
                return "/api/v2/auth/reset";
            },
            formUriParams() {
                return this.getGuardUriParams();
            },
            forgotRoute() {
                return this.getGuardRoute(AUTH_FORGOT);
            },
            loginRoute() {
                return {
                    name: AUTH_LOGIN
                };
            }
        },
        methods: {
            onUpdate(value){
                this.model = {
                    ...value
                };
            },
            onValidated(flags) {
                this.flags = {
                    ...flags
                };
            },
            async onSubmit(){
                this.submitting = true;

                if (this.flags.valid === false) {
                    return;
                }

                let response = await this.$fnApi.call("/api/v1/mobile/reset/password", "POST", {
                    ...this.model
                });

                if (response.status) {
                    this.onSuccess(response.data);
                } else {
                    if (response.code == 400 || response.code == 403) {
                        this.$refs.formSchema.setErrors(response.error);
                    }
                    this.$fnNotify(this.$t("Unable to reset password"));
                }

                this.submitting = false;

            },
            onSuccess(){
                this.$fnNotify(this.$t("Password reset!"));
                this.$router.push(this.loginRoute);
            },
            onFail(response){
                if (response.code === 422) {
                    this.$refs.observer.setErrors(response.data);
                }
            }
        }
    };
</script>
