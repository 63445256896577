<template>
    <browse-retailers/>
</template>
<script>

import withContext from "@fndry-vue/fn-context/mixins/withContext";
import {withScreen} from "@fndry-vue/fn-screen"
import BrowseRetailers from "@module-shop/finder/widgets/BrowseRetailers";

export default {
    name: "FindRetailerScreen",
    mixins: [
        withContext,
        withScreen,
    ],
    components: {
        BrowseRetailers
    },
    screen() {
        return {
            title: this.$t('Find a Retailer'),
            data: {
                withButton: true,
                waveTop: true,
                noPadding: true
            }
        }
    },
    context(){
        return false;
    }
};
</script>
