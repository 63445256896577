import Auth from "./services/auth";

const FnAuthPlugin = {};

/**
 * Install the AuthService into a Vue Application
 *
 *
 * @param {Object} Vue The Vue object
 * @param {{store: Object}} The application store
 */
FnAuthPlugin.install = function(Vue, {router, store, middleware}){

    const fnAuth = new Auth(store);

    /**
     * Fndry Auth Service
     *
     * @type {Auth}
     */
    Vue.prototype.$fnAuth = fnAuth;

    //connect up the middleware pipeline
    router.beforeEach(middleware.pipeline(store, fnAuth));

    Vue.directive("can", (el, binding, vnode) => {

        let check = fnAuth.hasAbility(binding.value);

        if (binding.modifiers.not) {
            check = !!check;
        }

        if (!check) {
            // replace HTMLElement with comment node
            const comment = document.createComment(" ");
            Object.defineProperty(comment, "setAttribute", {
                value: () => undefined,
            });
            vnode.elm = comment;
            vnode.text = " ";
            vnode.isComment = true;
            vnode.context = undefined;
            vnode.tag = undefined;
            vnode.data.directives = undefined;

            if (vnode.componentInstance) {
                vnode.componentInstance.$el = comment;
            }

            if (el.parentNode) {
                el.parentNode.replaceChild(comment, el);
            }
        }
    });

};

export default FnAuthPlugin;

export {
    Auth
};
