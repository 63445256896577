<template>
  <b-tabs fill class="dashboard-tabs">
    <b-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :title="tab.name"
        @click="goToRoute(tab)"
        :active="isActive(tab.route_name)"
    ></b-tab>
  </b-tabs>
</template>

<script>
import {
  LEASE_SUMMARY_SCREEN,
  LEASE_ITEMS_SCREEN,
  LEASE_CONTRACT_TERMS_SCREEN,
  LEASE_PAYMENT_HISTORY_SCREEN,
  LEASE_SCHEDULED_PAYMENTS_SCREEN
} from "@/features/leases/screens";

export default {
  name: "DetailsTabs",
  props: {
    details: Object,
    lease : Object,
  },
  data() {
    return {
      activeTab: 0,
      tabs : [
        {
          name: this.$t('Summary'),
          route_name: LEASE_SUMMARY_SCREEN,
          params: {
            details : this.details,
            lease: this.lease,
          }
        },
        {
          name: this.$t('Items'),
          route_name: LEASE_ITEMS_SCREEN,
          params: {
            details : this.details,
            lease: this.lease,
          }
        },
        {
          name: this.$t('Contract Terms'),
          route_name: LEASE_CONTRACT_TERMS_SCREEN,
          params: {
            details : this.details,
            lease: this.lease,
          }
        },
        {
          name: this.$t('Payment History'),
          route_name: LEASE_PAYMENT_HISTORY_SCREEN,
          params: {
            details : this.details,
            lease: this.lease,
          }
        },
        {
          name: this.$t('Scheduled Payments'),
          route_name: LEASE_SCHEDULED_PAYMENTS_SCREEN,
          params: {
            details : this.details,
            lease: this.lease,
          }
        },
      ]
    }
  },
  methods: {
    goToRoute(route) {
      if(route) {
        this.$router.push({
            name: route.route_name,
            params: route.params
        })
      }
    },
    isActive(tab) {
      return this.$route.name === tab;
    }
  }
}
</script>
