<template>
    <BaseLayout>
        <template #header-content>
            <div class="fn-ui-layout-column-header" @click="toggleLayoutColumnShow(primaryMenu)">
                <fn-ui-avatar :src="avatar" type="image"></fn-ui-avatar>
                <div class="p-3 d-flex flex-column">
                    <span class="font-12">Good Morning, </span>
                    <div class="font-24 font-weight-bold">{{ user.full_name }}</div>
                </div>
            </div>
        </template>
    </BaseLayout>
</template>

<script>
import {
    FnUiAvatar,
} from "@fndry-vue/fn-ui";
import defaultAvatar from "@theme/default/assets/images/default-avatar.svg"
import BaseLayout from "./BaseLayout.vue";
import {fnLayoutMixin} from "@fndry-vue/fn-layout";

export default {
    name: "DefaultLayout",
    mixins: [
        fnLayoutMixin,
    ],
    components: {   
        FnUiAvatar,
        BaseLayout,
    },
    computed: {        
        user: function() {
            let profile = this.$fnAuth.getUser();
            if (profile?.id) {
                return profile;
            }
            return null;
        },
        avatar() {
            if(!this.user.avatar) {
                return defaultAvatar;
            }
            return this.user.avatar
        },
        primaryColumn() {
            return this.getLayoutColumn(this.primaryMenu);
        }
    },
};
</script>
