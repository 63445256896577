/**
 * To make a field selectable, it should contain a 'selectable' key and a 'selected' key which determines the default selected state of the field
 *
 * ex.
 * fields: [
 *       {
 *           key: 'display_name',
 *           label: 'Display Name',
 *            selectable: true,
 *            selected:true,
 *        },
 *     ]
 *
 *  Also make sure to pass the 'visibleFields' computed prop to the b-table fields prop instead of directly passing the fields value
 *  and make use of the setSelectable() scoped method to set the selectable fields on to the tableCard parent component
 */
export default {
    mounted() {
        if(this.hasSelectableFields) {
            this.$emit("selectable", this.selectableFields);
        }
    },
    computed: {
        hasSelectableFields() {
            return this.selectableFields.length > 0;
        },

        selectableFields() {
            let selectableFields = this.fields.filter(field => field.selectable);
            if (selectableFields.length > 0 ) {
                return selectableFields;
            }
            return false;
        },

        visibleFields() {
            let fields = this.computedFields ?? this.fields;
            return fields.filter(field => field.selected || (field.selectable === undefined));
        }

    }
};
