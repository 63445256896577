import VueRouter from "vue-router";

const makeRouter = (Vue) => {
    Vue.use(VueRouter);
    return new VueRouter({
        mode: "hash",
        linkActiveClass: "open active",
        scrollBehavior: () => ({y: 0}),
        routes: [

        ]
    });
};

export default makeRouter;
