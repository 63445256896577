<template>
  <b-tabs fill class="dashboard-tabs">
    <b-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :title="tab.name"
        @click="goToRoute(tab.route_name)"
        :active="isActive(tab.route_name)"
    ></b-tab>
  </b-tabs>
</template>

<script>
import {DASHBOARD} from "@/features/dashboard";
import {DASHBOARD_PAYMENT_HISTORY_TAB} from "@/features/dashboard/screens";

export default {
  name: "DashboardTabs",
  data() {
    return {
      activeTab: 0,
      tabs : [
        {
          name: this.$t('Dashboard'),
          route_name: DASHBOARD
        },
        // {
        //   name: this.$t('Notifications'),
        //   route_name: null
        // },
        {
          name: this.$t('Payment History'),
          route_name: DASHBOARD_PAYMENT_HISTORY_TAB
        },
        // {
        //   name: this.$t('Blog'),
        //   route_name: null
        // },
      ]
    }
  },
  methods: {
    goToRoute(route) {
      if(route) {
        this.$router.push({
          name: route
        })
      }
    },
    isActive(tab) {
      return this.$route.name === tab;
    }
  }
}
</script>
