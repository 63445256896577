import Vue from "vue";
window.Vue = Vue;

/**
 * Config
 */
import config from "./config";


/**
 * Bootstrap the application
 */
import createApp, {AppFeatures} from "@fndry-vue/fn-app";

/**
 * Finally load the app and features
 *
 */
import App from "./App";

const FnFeatures = new AppFeatures();
window.FnFeatures = FnFeatures;

/**
 * Plugins
 */
import Plugins from "./plugins";
FnFeatures.addFeature(Plugins);

/**
 * Theme
 */
import Theme from "./theme";
FnFeatures.addFeature(Theme);

import layouts from "./layouts";

/**
 * Features
 */
import features from "./features";
FnFeatures.addFeatures(features);

/**
 * Create the application
 */
createApp(Vue, {
    el: "#app",
    template: "<App />",
    features: FnFeatures.features,
    components: {
        App
    },
    layouts,
    config
});


