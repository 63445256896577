import {isObject, forEach} from 'lodash';

/**
 * Determines the correct column class to apply to a column
 *
 * @param cols
 * @return {[]} The array of classes to apply
 */
export default (cols) => {
    let _cols = [];
    if (cols) {
        if (isObject(cols)) {
            forEach(cols, (size, type) => {
                _cols.push(`col-${type}-${size}`);
            });
        } else {
            _cols.push(`col-md-${cols}`);
        }
    }
    if (_cols.length === 0) {
        _cols.push('col');
    }
    return _cols;
}
