import {DEFAULT_LAYOUT} from "../../layouts";
import screens, {
    FINDER_SCREEN
} from "./screens";


const Plugin = {
    install: function(Vue, {router, layouts}){
        router.addRoutes([
            {
                path: "/",
                component: layouts[DEFAULT_LAYOUT],
                children: [
                    {
                        path: "find-a-retailer",
                        name: FINDER_SCREEN,
                        component: screens[FINDER_SCREEN],
                        meta: {
                            middleware: [
                                "auth"
                            ]
                        }
                    },
                ]
            }
        ]);
    },
    init: function(App){
        App.$fnMenus.addItems("main", [
            {
                to: {name: FINDER_SCREEN},
                text: App.$t("Find a Retailer")
            }
        ]);
    }
};

export default Plugin;
