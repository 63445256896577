import DashboardScreen from "./DashboardScreen";
import PaymentDetailsScreen from "./PaymentDetailsScreen";
import DashboardMainTab from "./DashboardScreen/DashboardMainTab";
import PaymentHistoryTab from "./DashboardScreen/PaymentHistoryTab";
import PayEarlyScreen from "./PayEarlyScreen";

export const DASHBOARD_SCREEN = "dashboard/home";
export const DASHBOARD_PAYMENT_DETAILS_SCREEN = "dashboard/payment-details"
export const DASHBOARD_MAIN_TAB = "dashboard/main"
export const DASHBOARD_PAYMENT_HISTORY_TAB = "dashboard/payment-history"
export const DASHBOARD_PAY_EARLY_SCREEN = "dashboard/pay-early"

export default {
    [DASHBOARD_SCREEN]: DashboardScreen,
    [DASHBOARD_PAYMENT_DETAILS_SCREEN] : PaymentDetailsScreen,
    [DASHBOARD_MAIN_TAB] : DashboardMainTab,
    [DASHBOARD_PAYMENT_HISTORY_TAB] : PaymentHistoryTab,
    [DASHBOARD_PAY_EARLY_SCREEN] : PayEarlyScreen
};
