export default {
    /**
     * Check the current component for any screen option and call it to set the context
     */
    mounted: function() {
        if (this.$options.screen && typeof this.$options.screen === "function") {
            this.setScreen(this.$options.screen.call(this));
        }
    },
    updated: function() {
        if (this.$options.screen && typeof this.$options.screen === "function") {
            this.setScreen(this.$options.screen.call(this));
        }
    },
    destroyed: function(){
        /**
         * Check if the component set a screen option and reset the screen state
         */
        if (this.$options.screen && typeof this.$options.screen === "function") {
            this.$fnScreen.resetScreen();
        }
    },
    methods: {
        /**
         *
         * @param state
         */
        setScreen(state) {
            this.$fnScreen.setScreen(state);
        },
        getScreenKey(key,def) {
            return this.$fnScreen.getDataKey(key, def);
        }
    },
    computed: {
        screenTitle() {
            return this.$fnScreen.getScreenTitle();
        },
        screenClass() {
            return this.$fnScreen.getScreenClass();
        },
        screenState() {
            return this.$fnScreen.getScreen();
        }
    }
};
