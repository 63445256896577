<template>
    <div class="d-flex flex-column h-100 justify-content-between">
        <div>
            <h4 class="text-left text-primary"></h4>
        </div>
        <div>
            <p class="lead text-center text-gray-600">{{ $t("A code was sent to your email. Please check it and enter it below.") }}</p>
            <div class="mt-3">
                <form @submit.prevent="onSubmit">
                    <fn-form-schema ref="formSchema" :value="model" @input="onUpdate" no-inject @validated="onValidated">
                        <fn-form-group :schema="schema.code"></fn-form-group>
                    </fn-form-schema>

                    <div class="form-group mt-4">
                        <fn-ui-button variant="primary" class="btn-block btn-rounded" :disabled="flags.invalid || submitting" :submitting="submitting" type="submit">{{$t("Continue")}}</fn-ui-button>
                    </div>
                    <div class="form-group mt-4">
                        <fn-ui-button variant="outline-primary" class="btn-block btn-rounded" @click="redirectToHome">{{$t("Cancel")}}</fn-ui-button>
                    </div>

                </form>

            </div>
        </div>
    </div>
</template>

<script>
    import withAutoGuard from "@fndry-vue/fn-auth/mixins/withAutoGuard";
    import {withScreen} from "@fndry-vue/fn-screen";
    import {FnUiButton} from "@fndry-vue/fn-ui";

    import {FnFormGroup, FnFormSchema} from "@fndry-vue/fn-form";
    import { AUTH_LOGIN } from "@/features/auth/screens";
    import { GUEST_LEASES } from "@/features/payasguest/screens";

    export default {
        name: "EnterOtp",
        mixins: [
            withAutoGuard,
            withScreen
        ],
        components: {
            FnFormSchema,
            FnFormGroup,
            FnUiButton
        },
        screen() {
            return {
                title: this.$t('Verification Confirmation'),
                data: {
                    noToggle: true,
                    withButton: true,
                    clearWave: true
                }
            }
        },
        data() {
            return {
                loading: false,
                submitting: false,
                flags: {
                    invalid: false,
                    dirty: false
                },
                applicant_id: null,
                schema: {
                    code: {
                        id: "id_code",
                        name: "code",
                        label: this.$t("Code"),
                        type: "text",
                        rules: "required",
                        required: true
                    }
                },
                model: {
                    code: "",
                    last_name: this.$route.params?.last_name ?? "",
                    date_of_birth: this.$route.params?.date_of_birth ?? "",
                    ssn: this.$route.params?.ssn ?? "",
                    token: this.$route.params?.token ?? "",
                }
            };
        },
        methods: {
            onUpdate(value){
                this.model.code = value.code ?? "";
                this.model.date_of_birth = this.$route.params?.date_of_birth ?? "",
                this.model.ssn = this.$route.params?.ssn ?? "",
                this.model.last_name = this.$route.params?.last_name ?? "",
                this.model.token = this.$route.params?.token ?? ""
            },
            onValidated(flags) {
                this.flags = {
                    ...flags
                };
            },
            async onSubmit(){
                this.submitting = true;

                if (this.flags.invalid === true) {
                    return;
                }

                let response = await this.$fnApi.call("/api/v1/mobile/guest/validate-otp", "POST", {
                    ...this.model
                });

                if (response.status) {
                    this.applicant_id = response.data?.applicant_id;
                    this.onSuccess();
                } else {
                    if (response.code == 404) {
                        this.$refs.formSchema.setErrors(response.error ?? this.$t("Unknown error."));
                        this.$fnNotify({message: this.$t("No lease found, please confirm the details you're using are correct!"), type: 'error'});
                    } else if (response.data["maxed"] || response.code == 406) {
                        this.$fnNotify({message: this.$t("Pin verification attempts exceeded"), type: 'error'});
                    } else {
                        this.$fnNotify({message: this.$t("Verification unsuccessful, please try again!"), type: 'error'});
                    }
                }

                this.submitting = false;

            },
            redirectToHome() {
                this.$router.push({
                    name: AUTH_LOGIN
                });
            },
            redirectToLeaseSelect() {
                this.$router.push({
                    name: GUEST_LEASES,
                    params: {
                        token: this.model.token,
                        applicantId: this.applicant_id,
                        ssn: this.model.ssn,
                    }
                });
            },
            onSuccess(){
                this.$fnNotify(this.$t("Successfully verified!"));

                this.redirectToLeaseSelect();

            }
        }
    };
</script>
