import {merge} from "lodash";

export const SET_USER = "SET_USER";
export const REMOVE_USER = "REMOVE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SET_SESSION_EXPIRED = "SET_SESSION_EXPIRED";
export const SET_GUEST = "SET_GUEST";
export const REMOVE_GUEST = "REMOVE_GUEST";
export const SET_AUTH_GUARD = "SET_AUTH_GUARD";
export const SET_AUTH_UI = "SET_AUTH_UI";


export const defaultState = {
    status: false,
    expired: null,
    user: {},
    guest: false,
    guard: null,
    ui: null
};

export default {
    /**
     * Set the authenticated User
     *
     * @param {object} state
     * @param {object} user
     */
    [SET_USER](state, {user}){
        state.status = true;
        state.user = user;
    },

    /**
     * Remove the authenticated user
     *
     * @param {object} state
     */
    [REMOVE_USER](state){
        state.status = false;
        state.user = null;
    },

    /**
     * Update the details about the currently logged in user
     *
     * @param {object} state
     * @param {object} user
     */
    [UPDATE_USER](state, {user}){
        state.user = merge({}, state.user, user);
    },

    /**
     * Mark the session expired status
     *
     * @param {object} state
     * @param {boolean} expired
     */
    [SET_SESSION_EXPIRED](state, expired){
        state.expired = expired;
    },

    /**
     * Set the user as guest
     *
     * @param {object} state
     */
    [SET_GUEST](state){
        state.status = false;
        state.guest = true;
    },

    /**
     * Remove the guest user
     *
     * @param {object} state
     */
    [REMOVE_GUEST](state){
        state.status = false;
        state.guest = false;
    },

    [SET_AUTH_GUARD](state, guard){
        state.guard = guard;
    },

    [SET_AUTH_UI](state, ui){
        state.ui = ui;
    },

};
