import store_, {panelStore} from "./store";

import Panel from "./components/Panel";
import Panels from "./components/Panels";
import PanelButton from "./components/PanelButton";
import withPanels from "./mixins/withPanels";
import withPanelForm from "./mixins/withPanelForm";
import {closePanel, openPanel} from "./store/actions";
import {UPDATE_PANEL_PROPS} from "./store/mutations";

/**
 * Panels object
 */
class PanelsRegistry {

    constructor(Vue, store) {
        // Install the Auth Store
        store_.install(store);
        this.store = store;
    }

    /**
     * Open a panel
     *
     * @param {Object} component The component to render in the panel
     * @param {Object} props The props for the component
     * @param {Object} on The listener bindings
     * @param {Boolean} stacked Whether to stack the panel (Defaults to true)
     * @param  overlay Show or hide overlay or restrict interaction to background elements (true, false , 'restrict')
     * @return {Promise} Returns of promise which has the panel id as the accept method's first param
     */
    open({component, props, on, stacked = true, overlay = true, size =  "md"}) {
        return this.store.dispatch(panelStore(openPanel), {
            component,
            props,
            stacked,
            on,
            overlay,
            size,
            visible: true
        });
    }

    /**
     * Close a panel
     *
     * @return {Promise}
     */
    close(id = undefined){
        return this.store.dispatch(panelStore(closePanel), id);
    }

    /**
     * Close all the panels
     *
     * @return {Promise}
     */
    closeAll(){
        return this.store.dispatch(panelStore(closePanel));
    }

    /**
     * Update the panel props
     *
     * @param id
     * @param props
     */
    updateProps(id, props){
        this.store.commit(panelStore(UPDATE_PANEL_PROPS), {id, props});
    }

    /**
     * Add a panel to the panels
     *
     * @param {Object} component The component to render in the panel
     * @param {Object} props The props for the component
     * @param {Object} on The listener bindings
     * @param {Boolean} stacked Whether to stack the panel (Defaults to true)
     * @param  overlay Show or hide overlay or restrict interaction to background elements (true, false , 'restrict')
     * @deprecated Use open() instead
     */
    add(params) {
        return this.open(params);
    }

    /**
     * Remove a panel from the panels
     *
     * @param id
     */
    remove(id) {
        return this.close(id);
    }

    /**
     * Cancel all the panels
     * @return {Promise}
     * @deprecated Use closeAll
     */
    cancel(){
        return this.store.dispatch(panelStore(closePanel));
    }
}


/**
 * FnPanels
 *
 * (c) 2020
 */
const FnPanelsPlugin = {};

FnPanelsPlugin.install = function(Vue, {store}) {
    Vue.prototype.$fnPanels = new PanelsRegistry(Vue, store);

    Vue.component("fn-panel-button", PanelButton);
};

export default FnPanelsPlugin;

export {
    Panels as FnPanels,
    Panel as FnPanel,
    PanelButton as FnPanelButton,
    withPanels as fnPanelsMixin,
    withPanelForm as fnPanelFormMixin,
    PanelsRegistry as FnPanelsRegistry
};

