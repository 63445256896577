<template>
  <loader :loading="loadingLeases">
    <div class="row mb-2" v-if="closedLeases.length > 0">
      <div class="col font-18 text-primary font-weight-bold">{{ $t('Paid Off Leases') }}</div>
    </div>

    <paid-off-lease-item
        v-for="(lease, index) in closedLeases"
        :key="index"
       :lease="lease"
    >
    </paid-off-lease-item>
  </loader>
</template>

<script>
import LeaseDetails from "../../mixins/leaseDetails";
import PaidOffLeaseItem from "../../components/PaidOffLeaseItem";

export default {
  name: "PaidOffLeasesTabScreen",
  mixins: [LeaseDetails],
  components: {
    PaidOffLeaseItem
  }
}
</script>
